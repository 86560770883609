import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

// register
Vue.use(Vuex);

// root
import { state, actions, mutations, getters, RootState } from "./root";

// modules
import { profile } from "./profile";
import { sessions } from "./sessions";
import { presentations } from "./presentations";
import { groups } from "./groups";
import { news } from "./news";
import { goals } from "./goals";
import { meetings } from "./meetings";
import { company } from "./company";
import { audio } from "./audio";
import { products } from "./products";
import { currentSession } from "./currentSession";
import { managed } from "./managed";
import { dashboard } from "./dashboard";
import { invitations } from "./invitations";
import { seminars } from "./seminars";
import { notifications } from "./notifications";
import { tenants } from "./tenants";
import { contact } from "./contact";
import { howTos } from "./howTos";

const store: StoreOptions<RootState> = {
  state,
  actions,
  getters,
  mutations,
  modules: {
    profile,
    sessions,
    presentations,
    groups,
    news,
    goals,
    meetings,
    company,
    audio,
    products,
    currentSession,
    managed,
    dashboard,
    invitations,
    seminars,
    notifications,
    tenants,
    contact,
    howTos,
  },
};

export default new Vuex.Store<RootState>(store);
