






























































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { hasPermissions } from "@/core/utils/auth";

import LocalStorage from "@/core/utils/LocalStorage";
import {
  UploadAudio,
  UploadVideo,
  RecordSession,
} from "./components/add-session";
import Menu from "./components/Menu.vue";

@Component({
  methods: { hasPermissions },
  components: { UploadAudio, UploadVideo, RecordSession, Menu },
})
export default class Navigation extends Vue {
  @PropSync("isUploadingProp") isUploading!: boolean;
  @Prop({ default: () => true }) showLayout!: boolean;
  @Prop({ default: () => false }) isMobile!: boolean;
  @PropSync("isOpen") open!: boolean;
  @Getter("isDarkMode") isDarkMode!: boolean;
  @Getter("seminars/currentLogo") selectedSeminarLogo!: string;

  VRMode = LocalStorage.getVRMode();

  mini = false;
  expanded = false;
  get imageWidth() {
    if (this.isMobile) return "20%";
    else return "80%";
  }
  get navHeadingHeight() {
    const width = "width:100%;height:";
    if (this.isMobile) return width + "80px";
    else return width + "100px";
  }

  get isOnSessions() {
    return this.$route.fullPath.startsWith("/sessions");
  }

  toggleMini() {
    this.mini = !this.mini;
    if (this.mini) this.expanded = false;
    else if (this.isOnSessions) this.expanded = true;
    LocalStorage.setMenuMini(this.mini);
  }

  created() {
    this.mini = LocalStorage.getMenuMini();
  }

  @Watch("$route")
  routeChanged() {
    if (this.isOnSessions && !this.mini) this.expanded = true;
  }
}
