import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldShow)?_c(VDialog,{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"prezpRed","elevation":"0"}},on),[_c('div',{staticClass:"other--text"},[_vm._v(" "+_vm._s(_vm.$t("browser_warnings.unsuppBrowser"))+" ")]),_c(VIcon,{staticClass:"other--text",attrs:{"right":""}},[_vm._v("mdi-alert")])],1)]}}],null,false,1460669195),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("browser_warnings.unsuppBrowserTitle"))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("browser_warnings.unsuppBrowserText"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("browser_warnings.ok")))])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }