import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import {
  User,
  Subscription,
  Roles,
  UserClaims,
  RoleClaims,
} from "@/core/models";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

export interface ProfileState {
  isAuthenticated: boolean;
  hasSubscription: boolean;
  ownsCompany: boolean;
  subscriptionDetails: Subscription | null;
  user?: User;
  userRoles: Roles[];
  permissions: RoleClaims[];
  userClaims: UserClaims[];
  loading: boolean;
  allUsers: User[];
  allUsersLoading: boolean;
  allUsersRetrieved: boolean;
  changeUserRoleLoading: boolean;
  changedUserRoleEmail: string;

  forgotPasswordLoading: boolean;
  forgotEmailSent: boolean;
  resetPasswordLoading: boolean;
  confirmEmailSent: boolean;
  confirmEmailLoading: boolean;

  createCompanyLoading: boolean;
  createCompanySuccess: boolean;
  editCompanyLoading: boolean;
  upgradeLoading: boolean;

  baseline: BaselineAnalysis;

  accessToken?: string;
  refreshToken?: string;
}

export const state: ProfileState = {
  user: undefined,
  userRoles: [],
  permissions: [],
  userClaims: [],
  loading: false,
  isAuthenticated: false,
  hasSubscription: false,
  ownsCompany: false,
  subscriptionDetails: null,

  allUsers: [],
  allUsersLoading: false,
  allUsersRetrieved: false,
  changeUserRoleLoading: false,
  changedUserRoleEmail: "",

  forgotPasswordLoading: false,
  forgotEmailSent: false,
  resetPasswordLoading: false,
  confirmEmailSent: false,
  confirmEmailLoading: false,

  createCompanyLoading: false,
  createCompanySuccess: false,
  editCompanyLoading: false,
  upgradeLoading: false,

  baseline: new BaselineAnalysis(null),

  accessToken: undefined,
  refreshToken: undefined,
};

const namespaced: boolean = true;

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
