import { ActionTree } from "vuex";

import { NewsState } from ".";
import api from "@/core/utils/api";
import { RootState } from "../root";
import i18n from "@/core/plugins/i18n";
import { Article } from "@/core/models";

const actions: ActionTree<NewsState, RootState> = {
  clearArticles({ state }) {
    state.articles = [];
    state.retrieved = false;
  },

  async getArticles({ state }) {
    state.retrieved = false;
    state.loading = true;
    try {
      const res = (await api.get("/api/News/")) as any[];
      state.articles = res.map(x => Article.parseArticle(x));
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async addArticle({ state }, data: FormData) {
    state.addArticleLoading = true;
    try {
      const res = await api.post("/api/News/AddArticle", data, {
        headers: { ContentType: "multipart/form-data" },
      });
      const article = Article.parseArticle(res);
      state.articles.push(article);
    } catch (error) {
      console.log(error);
    }
    state.addArticleLoading = false;
  },

  async editArticle({ state }, data: any) {
    const { articleId, newTitle, newBody } = data;
    state.editArticleLoading = true;
    state.editedArticleID = articleId;
    try {
      await api.put("/api/News/EditArticle", data);
      const idx = state.articles.findIndex(x => x.ID === articleId);
      if (idx === -1) return;
      state.articles[idx].Title = newTitle;
      state.articles[idx].Body = newBody;
    } catch (error) {
      console.log(error);
    }
    state.editArticleLoading = false;
  },

  async deleteArticle({ commit, state }, id: number) {
    state.deleteArticleLoading = true;
    state.deletedArticleID = id;
    try {
      await api.delete(`/api/News/DeleteArticle?id=${id}`);
      const idx = state.articles.findIndex(x => x.ID === id);
      if (idx === -1) return;
      state.articles.splice(idx, 1);
      const msg = i18n.t("snack.news.added").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
    }
    state.deleteArticleLoading = false;
  },
};

export default actions;
