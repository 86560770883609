class ConditionType {
  public static DueDate = "due_date";
  public static SessionScore = "session_score";
  public static Sentiment = "sentiment";
  public static SpeechSpeed = "speech_speed";
  public static Pitch = "pitch";
  public static Volume = "volume";
  public static Pauses = "pauses";
  public static OverallScore = "overall_score";
  public static Repetition = "repetition";
  public static FillerWords = "filler_words";
  public static SpeechClarity = "speech_clarity";
}

export default ConditionType;
