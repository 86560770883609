import { ActionTree } from "vuex";

import { CompanyState } from ".";
import router from "@/core/router";
import api from "@/core/utils/api";
import { RootState } from "../root";
import i18n from "@/core/plugins/i18n";
import { Company } from "@/core/models";

const actions: ActionTree<CompanyState, RootState> = {
  clear({ state }): void {
    state.company = null;
  },

  async get({ state, commit }) {
    state.getting = true;
    try {
      const endpoint = "/api/Company/GetCompanyOfUser";
      const res = await api.get(endpoint);
      if (res) {
        const company = new Company(res);
        state.company = company;
        if (!company.IsActive) router.push("/company");
      } else router.push("/company");
    } catch (error) {
      state.error = error;
      console.log(error);
      commit("displaySnackbar", "Could not get company", { root: true });
    }
    state.getting = false;
  },

  async create({ state, commit }, payload) {
    state.creating = true;
    try {
      const endpoint = "/api/Company/CreateCompany";
      const res = await api.post(endpoint, payload);
      state.company = new Company(res);
      const message = i18n.t("snack.comp.created").toString();
      commit("displaySnackbar", message, { root: true });
      router.push("/company/dashboard");
    } catch (error) {
      state.error = error;
      commit("displaySnackbar", "Could not create company", { root: true });
    }
    state.creating = false;
  },

  async editName({ state, commit }, payload) {
    state.editing = true;
    try {
      const end = "/api/Company/EditCompanyName";
      await api.put(end, payload);
    } catch (error) {
      state.error = error;
      commit("displaySnackbar", "Edit name failed", { root: true });
    }
    state.editing = false;
  },

  async inviteUsers({ state, commit }, payload) {
    state.adding = true;
    try {
      const end = "/api/Company/InviteUsers";
      const rejected = (await api.post(end, payload)) as string[];
      let msg = i18n.t("snack.comp.invited").toString();
      if (rejected && rejected.length > 0)
        msg = i18n.t("snack.comp.rejected") + rejected.join(", ");
      else if (rejected && rejected.length == 0)
        msg = i18n.t("snack.comp.success").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      state.error = error;
      //const errorData = await error;
      console.log(error);
      if (
        error.code &&
        error.code == "InvalidOperationException" &&
        error.description &&
        error.description.includes("This user is already in/owns a company")
      ) {
        const msg = i18n.t("snack.comp.conflict").toString();
        commit("displaySnackbar", msg, { root: true });
      } else commit("displaySnackbar", "Invite users failed", { root: true });
    }
    state.adding = false;
  },

  async upgrade({ state, commit }, payload) {
    state.upgrading = true;
    try {
      const end = "/api/Company/UpgradePlan";
      await api.post(end, payload);
    } catch (error) {
      state.error = error;
      commit("displaySnackbar", "Upgrade failed", { root: true });
    }
    state.upgrading = false;
  },

  async updateRole({ state, commit }, payload) {
    const { userEmail, isManager } = payload;
    if (!state.company) return;
    const idx = state.company.Employees.findIndex(e => e.Email === userEmail);
    if (idx !== -1) state.company.Employees[idx].IsManager = isManager;

    const msg = i18n.t("snack.users.rupdated").toString();
    commit("displaySnackbar", msg, { root: true });
  },

  async removeUser({ state, commit }, email: string) {
    if (!state.company) return;
    const idx = state.company.Employees.findIndex(e => e.Email === email);
    if (idx !== -1) state.company.Employees.splice(idx, 1);

    const msg = i18n.t("snack.users.rem").toString();
    commit("displaySnackbar", msg, { root: true });
  },

  set({ state, rootState }, company: Company | null) {
    if (!company) {
      console.log("No company was provided in the company setter");
      return;
    }
    state.company = company;
    rootState.active = true;
  },
};

export default actions;
