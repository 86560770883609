class SentimentSentence {
  public Text: string;
  public Sentiment: string;
  public PositiveScore: number;
  public NegativeScore: number;
  public NeutralScore: number;
  public Offset: number;
  public Duration: number;

  constructor(
    t: string,
    sent: string,
    pos: number,
    neg: number,
    neut: number,
    off: number,
    dur: number,
  ) {
    this.Text = t;
    this.Sentiment = sent;
    this.PositiveScore = Math.round(pos * 100);
    this.NegativeScore = Math.round(neg * 100);
    this.NeutralScore = Math.round(neut * 100);
    this.Offset = off;
    this.Duration = dur;
  }

  public static parseSentence(sentence: any): SentimentSentence {
    return new SentimentSentence(
      sentence.text,
      sentence.sentiment,
      sentence.positiveScore,
      sentence.negativeScore,
      sentence.neutralScore,
      sentence.offset,
      sentence.duration,
    );
  }
}

export default SentimentSentence;
