import { Module } from "vuex";

import actions from "./actions";
import getters from "./getters";
import { RootState } from "../root";
import { Interval } from "@/core/models";
import { Session } from "@/core/models/sessions";

export interface CurrentSessionState {
  limit: number;
  zoom: boolean;
  interval: Interval;
  session: Session | null;
}

export const state: CurrentSessionState = {
  session: null,
  limit: 50,
  zoom: false,
  interval: { start: 0, end: 0 },
};

export const currentSession: Module<CurrentSessionState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
};
