import { GetterTree } from "vuex";

import { NewsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<NewsState, RootState> = {
  loading: state => state.loading,
  retrieved: state => state.retrieved,
  getArticles: state =>
    state.articles.sort((a, b) => +b.PostedAt - +a.PostedAt),
  getLatestArticles: (_, getters) => getters.getArticles.slice(0, 4),

  // Helpers
  addArticleLoading: state => state.addArticleLoading,
  editArticleLoading: state => state.editArticleLoading,
  editedArticleID: state => state.editedArticleID,
  deleteArticleLoading: state => state.deleteArticleLoading,
  deletedArticleID: state => state.deletedArticleID,
};

export default getters;
