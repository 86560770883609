














import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { SelectItem, Seminar } from "@/core/models";

@Component
export default class SeminarSelect extends Vue {
  @Getter("seminars/selectedId") selectedId!: number;
  @Getter("seminars/seminars") seminars!: Seminar[];

  get items(): SelectItem<number>[] {
    return this.seminars.map(x => ({
      text: `${x.name} (Owner: ${x.owner?.username})`,
      value: x.id,
    }));
  }

  select(id: number) {
    const currentRoute = this.$route.path;
    if (currentRoute === "/seminars/admin")
      return this.$router.replace("/seminars/edit/" + id);
    const parts = currentRoute.split("/");
    const newRoute = `/seminars/${parts[2]}/${id}`;
    this.$router.replace(newRoute);
  }

  get shown() {
    const routes = ["/seminars/details", "/seminars/dashboard"];
    return routes.some(x => this.$route.path.startsWith(x));
  }
}
