import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import { Presentation } from "@/core/models";

export interface PresentationsState {
  loading: boolean;
  retrieved: boolean;
  uploadLoading: boolean;
  deleteLoading: boolean;
  presentations: Presentation[];
  deletedPresentationID: number;
}

export const state: PresentationsState = {
  loading: false,
  retrieved: false,
  presentations: [],
  uploadLoading: false,
  deleteLoading: false,
  deletedPresentationID: 0,
};

export const presentations: Module<PresentationsState, RootState> = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
