import { ActionTree } from "vuex";
import { RootState } from "../root";
import { AudioState } from "./index";

const actions: ActionTree<AudioState, RootState> = {
  updateTime({ state }, time) {
    state.currentTime = time;
  },
  setProgress({ state }, progress) {
    state.progress = progress;
  },
  setTotalTime({ state }, time) {
    state.totalTime = time;
  },
};

export default actions;
