import { GetterTree } from "vuex";

import { RootState } from "../root";
import i18n from "@/core/plugins/i18n";
import vuetify from "@/core/plugins/vuetify";

const getters: GetterTree<RootState, RootState> = {
  lang: _ => i18n.locale,
  theme: _ => vuetify.framework.theme.currentTheme,
  isDarkMode: _ => vuetify.framework.theme.dark,
  dataLoading: state => state.dataLoading,
  dataLoadSuccess: state => state.dataLoadSuccess,
  appLoading: state => state.appLoading,
  active: state => state.active,
  showSnackbar: state => state.showSnackbar,
  snackbarText: state => state.snackbarText,
};

export default getters;
