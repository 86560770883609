import { Module } from "vuex";

import actions from "./actions";
import getters from "./getters";
import { RootState } from "../root";
import {
  Goal,
  Group,
  Article,
  Presentation,
  AssignedGoal,
} from "@/core/models";

export interface DashboardData {
  groups: Group[];
  articles: Article[];
  presentations: Presentation[];
  goals: { assignedGoals: AssignedGoal[]; createdGoals: Goal[] };
}

export interface DashboardState {
  retrieved: boolean;
  loading: boolean;
}
export const state: DashboardState = {
  retrieved: false,
  loading: false,
};

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
};
