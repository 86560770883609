import { ActionTree } from "vuex";

import { GroupsState } from "../";
import api from "@/core/utils/api";
import { RootState } from "../../root";
import { GroupPost } from "@/core/models";

const actions: ActionTree<GroupsState, RootState> = {
  async addPost({ state }, data: FormData) {
    state.addPostLoading = true;
    try {
      const res = await api.post("/api/Groups/AddPost", data, {
        headers: { ContentType: "multipart/form-data" },
      });

      const groupId = Number(data.get("groupId"));
      const isPinned = data.get("isPinned");
      const post = GroupPost.parseGroupPost(res);
      const idx = state.groups.findIndex(g => g.ID == groupId);
      if (idx === -1) return;

      const copy = state.groups.slice(0);
      if (isPinned === "true") copy[idx].PinnedPostID = post.ID;
      copy[idx].Posts.push(post);
      state.groups = copy;
    } catch (error) {
      console.log(error);
    }
    state.addPostLoading = false;
  },

  async editPost({ state }, data: any) {
    state.editPostLoading = true;
    state.editedPostID = data.postId;
    try {
      await api.put("/api/Groups/EditPost", data);

      const postId = data.postId;
      const idx = state.groups.findIndex(g => g.ID == data.groupId);
      if (idx === -1) return;

      const copy = state.groups.slice(0);
      const post = copy[idx].Posts.find(p => p.ID == postId)!;
      post.Title = data.newTitle;
      post.Body = data.newBody;

      if (data.isPinned) copy[idx].PinnedPostID = postId;
      else if (!data.isPinned && copy[idx].PinnedPostID == postId)
        copy[idx].PinnedPostID = 0;
      state.groups = copy;
    } catch (error) {
      console.log(error);
    }
    state.editPostLoading = false;
  },

  // Delete a post
  async deletePost({ state }, data: any) {
    state.deletePostLoading = true;
    state.deletedPostID = data.postId;
    try {
      const endpoint = `/api/Groups/DeletePost?groupId=${data.groupId}&postId=${data.postId}`;
      await api.delete(endpoint);

      const idx = state.groups.findIndex(g => g.ID == data.groupId);
      if (idx === -1) return;

      const copy = state.groups.slice(0);
      const postToDelete = copy[idx].Posts.find(p => p.ID == data.postId)!;
      copy[idx].Posts.splice(copy[idx].Posts.indexOf(postToDelete), 1);
      state.groups = copy;
    } catch (error) {
      console.log(error);
    }
    state.deletePostLoading = false;
  },
};

export default actions;
