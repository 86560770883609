import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"340","content-class":"bottom-right-dialog"},model:{value:(_vm.supportInfoVisible),callback:function ($$v) {_vm.supportInfoVisible=$$v},expression:"supportInfoVisible"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","outlined":""}},[_c(VToolbarTitle,{staticClass:"text-h6 font-weight-regular"},[_vm._v(_vm._s(_vm.supportInfo.name))]),_c(VSpacer),_c(VBtn,{staticClass:"subtle",attrs:{"fab":"","small":"","plain":"","depressed":"","icon":""},on:{"click":function($event){_vm.supportInfoVisible = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mt-6"},[_c('div',{staticClass:"support-info"},[_c(VIcon,{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-email")]),_c('a',{staticClass:"primary--text",attrs:{"href":_vm.emailLink}},[_vm._v(_vm._s(_vm.supportInfo.email)+" ")])],1),_c('div',{staticClass:"support-info"},[_c(VIcon,{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-phone")]),_c('a',{staticClass:"primary--text",attrs:{"href":_vm.phoneLink}},[_vm._v(" "+_vm._s(_vm.supportInfo.phone))])],1),_c('div',{staticClass:"support-info"},[_c(VIcon,{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.supportInfo.times)+" ")],1)])],1)],1),_c(VBtn,{staticStyle:{"z-index":"100"},attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"primary","title":"Support"},on:{"click":_vm.showSupportInfo}},[_c(VIcon,[_vm._v("mdi-help-circle")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }