import User from "@/core/models/User";
import FileObject from "@/core/models/FileObject";

class HowToVideo {
  public ID: number;
  public Title: string;
  public Description: string;
  public CreatedAt: Date;
  public Uploader: User | null;
  public File: FileObject | null;
  public Video: FileObject | null; // Workaround to allow res with Video instead of File

  constructor(
    id: number,
    title: string,
    description: string,
    uploader: User | null,
    createdAt: Date,
    file: FileObject | null,
    video: FileObject | null,
  ) {
    this.ID = id;
    this.Title = title;
    this.Description = description;
    this.Uploader = uploader;
    this.CreatedAt = createdAt;
    this.File = file;
    this.Video = video;
  }

  static parseHowToVideo(data: any) {
    const uploader = data.uploader
      ? new User(data.uploader.email, data.uploader.username)
      : null;
    const at = new Date(Date.parse(data.createdAt));
    const file = data.file ? FileObject.parseFile(data.file) : null;
    const video = data.video ? FileObject.parseFile(data.video) : null;
    return new HowToVideo(
      data.id,
      data.title,
      data.description,
      uploader,
      at,
      file,
      video,
    );
  }
}

export default HowToVideo;
