import { Module } from "vuex";

import actions from "@/core/store/contact/actions";
import getters from "@/core/store/contact/getters";
import mutations from "@/core/store/contact/mutations";
import { RootState } from "../root";

export interface ContactState {
  messageLoading: boolean;
  messageSent: boolean;
}

export const state: ContactState = {
  messageLoading: false,
  messageSent: false,
};

const namespaced: boolean = true;

export const contact: Module<ContactState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
