import { GetterTree } from "vuex";

import { RootState } from "../root";
import { InvitationsState } from ".";

export const getters: GetterTree<InvitationsState, RootState> = {
  retrieved: state => state.retrieved,
  loading: state => state.loading,
  sending: state => state.sending,
  invitations: state => state.invitations,
};
