class ChartEntry {
  public Timestamp: number;
  public Value: number;

  constructor(timestamp: number, value: number) {
    this.Timestamp = timestamp;
    this.Value = value;
  }
}

export default ChartEntry;
