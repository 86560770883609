import { ActionTree } from "vuex";

import router from "@/core/router";
import i18n from "@/core/plugins/i18n";
import api from "@/core/utils/api";
import LocalStorage from "@/core/utils/LocalStorage";
import UserData from "@/core/models/UserData";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

import { ProfileState } from ".";
import { RootState } from "../root";

const actions: ActionTree<ProfileState, RootState> = {
  async logoutUser({ state, dispatch }) {
    state.user = undefined;
    state.isAuthenticated = false;
    LocalStorage.clearStorage();
    dispatch("clearEverything", null, { root: true });
  },
  clear: ({ state }) => (state.baseline = new BaselineAnalysis(null)),

  // login/register/load
  async loginUser({ state, rootState, commit, dispatch }, { email, password }) {
    console.log("profile/actions loginUser");
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Login", {
        email,
        password,
        from: "web",
      })) as any;
      const { userData: data, accessToken, refreshToken } = res as any;

      const userData = new UserData(data);
      LocalStorage.setAccessToken(accessToken);
      LocalStorage.setRefreshToken(refreshToken);

      state.isAuthenticated = true;
      dispatch("setEverything", userData, { root: true });

      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;
      const hasSubscription = userData.user.subscription?.isActive || false;
      commit("setHasSubscription", hasSubscription);

      window.dispatchEvent(new Event("user-logged-in"));

      // Don't redirect on DAF login
      const { deviceCode } = router.currentRoute.query;
      if (deviceCode) return;

      await router.replace(hasSubscription ? "/" : "/subscription/pricing");
    } catch (error) {
      const msg = i18n.t(`api_errors.${(error as any).description}`).toString();
      dispatch("displaySnackbar", msg, { root: true });
      commit("userLoadError");
    }

    state.loading = false;
    commit("loaded", null, { root: true });
  },
  async registerUser({ state, /*rootState,*/ commit, dispatch }, data) {
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Register", data)) as any;
      const { user, emailToken } = res;
      dispatch("sendConfirmEmail", { email: user.email, token: emailToken });
      // If user should be signed in directly after signup (missing the complete user data from backend here)
      //const userData = new UserData({ user });
      //dispatch("setEverything", userData, { root: true });
      //rootState.dataLoadSuccess = true;
      //rootState.active = userData.active;
      await router.replace("/auth/new-user");
    } catch (error) {
      if ((error as any).description === "user_exists") {
        const msg = i18n.t("api_errors.user_exists").toString();
        dispatch("displaySnackbar", msg, { root: true });
      } else commit("userLoadError");
    }
    state.loading = false;
    commit("loaded", null, { root: true });
  },

  async loadUser({ state, commit, dispatch }) {
    state.loading = true;
    try {
      const res = (await api.get("/api/Users/GetByToken")) as any;
      const {
        email,
        username,
        roles,
        emailVerified,
        permissions,
        subscription,
        customerId,
        paymentMethodId,
      } = res;
      LocalStorage.setUserRole(roles);

      const userData = new UserData({
        email,
        username,
        roles,
        emailVerified,
        permissions,
        subscription,
        customerId,
        paymentMethodId,
      });
      commit("set", userData);

      const hasSubscription = userData.user.subscription?.isActive || false;
      commit("setHasSubscription", hasSubscription);
    } catch (error) {
      console.error("Error while loading user:", error);
      dispatch(
        "displaySnackbar",
        (error as any).message || "Error while loading user",
        { root: true },
      );
      commit("userLoadError");
    } finally {
      state.loading = false;
    }
  },

  async updateTokens({ commit }, { accessToken, refreshToken }) {
    try {
      console.log("accessToken: ", accessToken);
      LocalStorage.setAccessToken(accessToken);
      LocalStorage.setRefreshToken(refreshToken);
      commit("updateTokens", { accessToken, refreshToken });
      console.log("Tokens updated successfully");
    } catch (error) {
      console.error("Error while updating tokens", error);
    }
  },

  // subscriptions
  async getSubscriptionDetails(
    { state, commit, dispatch },
    subscriptionId: string,
  ) {
    state.loading = true;
    try {
      const subscription: any = await api.get(
        `/api/Subscriptions/${subscriptionId}`,
      );
      commit("setSubscriptionDetails", subscription);

      const hasSubscription = subscription?.isActive || false;
      commit("setHasSubscription", hasSubscription);
    } catch (error) {
      console.error("Error while getting subscription:", error);
      dispatch(
        "displaySnackbar",
        (error as any).message || i18n.t("snack.stripe.subGetError"),
        { root: true },
      );
    } finally {
      state.loading = false;
    }
  },

  async cancelSubscription(
    { state, commit, dispatch },
    subscriptionId: string,
  ) {
    state.loading = true;
    try {
      await api.patch(`/api/Subscriptions/${subscriptionId}`);
      const subscription: any = await api.get(
        `/api/Subscriptions/${subscriptionId}`,
      );
      commit("setSubscriptionDetails", subscription);
      console.log("Canceled subscription", subscriptionId);
    } catch (error) {
      console.error("Error while canceling subscription:", error);
      dispatch(
        "displaySnackbar",
        (error as any).message || i18n.t("snack.stripe.subCancelError"),
        { root: true },
      );
    } finally {
      state.loading = false;
    }
  },

  async deleteSubscription(
    { state, commit, dispatch },
    subscriptionId: string,
  ) {
    state.loading = true;
    try {
      await api.delete(`/api/Subscriptions/${subscriptionId}`);
      const subscription: any = await api.get(
        `/api/Subscriptions/${subscriptionId}`,
      );
      commit("setSubscriptionDetails", subscription);
      console.log("Canceled subscription", subscriptionId);
    } catch (error) {
      console.error("Error while deleting subscription:", error);
      dispatch(
        "displaySnackbar",
        (error as any).message || i18n.t("snack.stripe.subCancelError"),
        { root: true },
      );
    } finally {
      state.loading = false;
    }
  },

  async fetchUserRoles({ commit }) {
    try {
      const response = await api.get("/api/Users/GetAllRoles");
      commit("setUserRoles", response.data.roles);
    } catch (error) {
      console.error("Failed to fetch user roles:", error);
    }
  },

  async fetchPermissions({ commit }) {
    try {
      const roleClaims: [] = await api.get("/api/Users/GetRolePermissions");
      const userClaims: [] = await api.get("/api/Users/GetUserPermissions");
      commit("setPermissions", [...roleClaims, ...userClaims]);
      commit("setUserClaims", userClaims);
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  },

  // sending emails
  async resetPassword({ state, dispatch }, data) {
    state.resetPasswordLoading = true;
    try {
      await api.post("/api/Account/MakePasswordResetResetPassword", data);
      router.push("/auth/login");
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Reset password fail",
        { root: true },
      );
      console.log(error);
    }
    state.resetPasswordLoading = false;
  },
  async sendConfirmEmail({ state, dispatch }, payload) {
    const emailTimeout = 10 * 1000;
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/SendEmailVerification", payload);
      state.confirmEmailSent = true;
      setTimeout(() => (state.confirmEmailSent = false), emailTimeout);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Reset password fail",
        { root: true },
      );
      state.confirmEmailSent = false;
    }
    state.confirmEmailLoading = false;
  },
  async confirmEmail({ state, dispatch }, data) {
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/ConfirmEmail", data);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Error when confirming email",
        { root: true },
      );
    }
    state.confirmEmailLoading = false;
  },

  // pfp
  async uploadPfp({ state }, file: File) {
    try {
      const end = "/api/Users/UploadProfilePicture";
      const data = new FormData();
      data.append("image", file);
      const uri = (await api.post(end, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as string;
      if (!state.user) return;
      state.user.Pfp = uri;
    } catch (error) {
      console.log(error);
    }
  },
  async removePfp({ state }) {
    try {
      const end = "/api/Users/RemoveProfilePicture";
      await api.delete(end);
      if (!state.user) return;
      state.user.Pfp = "";
    } catch (error) {
      console.log(error);
    }
  },

  // tutorial
  async advanceTutorial({ state }) {
    try {
      const end = "/api/Users/Advance";
      await api.patch(end);
      if (!state.user) return;
      state.user.TutorialStep = state.user.TutorialStep + 1;
    } catch (error) {
      console.log(error);
    }
  },
  async completeTutorial({ state }) {
    try {
      const end = "/api/Users/Complete";
      await api.patch(end);
      if (!state.user) return;
      state.user.TutorialStep = 0;
      state.user.ShowTutorial = false;
    } catch (error) {
      console.log(error);
    }
  },

  // add users from excel
  async addUsersFromExcel({ state }, file: File) {
    try {
      console.log("profile/actions=>addUsersFromExcel: ", file);
      const data = new FormData();
      data.append("excelFile", file);
      await api.post("/api/Users/AddFromExcel", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!state.user) return;
    } catch (error) {
      console.log(error);
    }
  },

  // login from pre-activated deviceCode & clientId
  async loginByDeviceHandshake(
    { state, rootState, commit, dispatch },
    { deviceCode, clientId },
  ) {
    state.loading = true;
    try {
      const res = (await api.post(
        "/api/Account/RequestDeviceToken",
        {
          deviceCode,
        },
        {
          headers: {
            clientId: clientId,
          },
        },
      )) as any;
      const { userData: data, accessToken, refreshToken } = res as any;

      const userData = new UserData(data);
      LocalStorage.setAccessToken(accessToken);
      LocalStorage.setRefreshToken(refreshToken);

      state.isAuthenticated = true;
      dispatch("setEverything", userData, { root: true });
      router.replace("/");
      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;

      window.dispatchEvent(new Event("user-logged-in"));
    } catch (error) {
      console.error(error);
      if (error === "Failed to generate device token.")
        // eslint-disable-next-line no-ex-assign
        error = { description: "device_token_failed" };
      const msg = i18n.t(`api_errors.${(error as any).description}`).toString();
      dispatch("displaySnackbar", msg, { root: true });
      commit("userLoadError");
    }

    state.loading = false;
    commit("loaded", null, { root: true });
  },

  // Company
  async createCompany({ state }, company) {
    console.log("Create company action", company);
    state.createCompanySuccess = true;
  },
  async upgrade({ commit, state }, payload) {
    state.upgradeLoading = true;
    try {
      await api.post("Company/Upgrade", payload);
      const msg = i18n.t("snack.comp.upsuccess").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (errors) {
      const msg = i18n.t("snack.comp.upfail").toString();
      commit("displaySnackbar", msg, { root: true });
    }
    state.upgradeLoading = false;
  },
};

export default actions;
