class AssignedUser {
  public Email: string;
  public Username: string;
  public Progress: number;
  public IsCompleted: boolean;

  public ProgressText: string;

  constructor(
    email: string,
    username: string,
    progress: number,
    isCompleted: boolean,
  ) {
    this.Email = email;
    this.Username = username;
    this.Progress = progress;
    this.IsCompleted = isCompleted;

    this.ProgressText = `Completed ${this.Progress} sessions`;
  }

  public static parse(data: any): AssignedUser {
    return new AssignedUser(
      data.email,
      data.username,
      data.progress,
      data.isCompleted,
    );
  }
}

export default AssignedUser;
