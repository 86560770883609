import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex align-center",staticStyle:{"width":"auto"},attrs:{"elevation":"0"}},[_c('div',{staticClass:"pa-2 ml-1 mr-2"},[_c(VTooltip,{attrs:{"right":"","color":_vm.hasLiveTranscriptionQuota ? 'error' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2",staticStyle:{"cursor":"default"}},on),[_c(VIcon,{class:("mr-2 " + (_vm.hasLiveTranscriptionQuota ? 'error--text' : 'primary--text'))},[_vm._v("mdi-microphone")]),_c(VCard,{staticClass:"body-2 px-3 py-1 d-inline-block font-weight-medium",staticStyle:{"background":"none"},attrs:{"flat":""}},[_c('div',{class:("body-2 d-inline " + (_vm.liveTranscriptionFast === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.liveTranscriptionFast === "∞" ? _vm.liveTranscriptionFast : _vm.convertDuration( parseInt(_vm.liveTranscriptionFast.toString()) ))+" "),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-clock-fast")])],1),_c(VDivider,{staticClass:"ml-2 mr-1 h-full d-inline",attrs:{"vertical":""}}),_c('div',{class:("body-2 d-inline " + (_vm.liveTranscriptionRelaxed === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.liveTranscriptionRelaxed === "∞" ? _vm.liveTranscriptionRelaxed : _vm.convertDuration( parseInt(_vm.liveTranscriptionRelaxed.toString()) ))+" ")]),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-speedometer-slow")])],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("quota.tip.liveTranscription"))),_c('br'),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-clock-fast")]),_vm._v(" "+_vm._s(_vm.$t("quota.fast"))+": "),_c('b',[_vm._v(_vm._s(_vm.liveTranscriptionFast === "∞" ? _vm.$t("quota.unlimited") : (_vm.liveTranscriptionFast + "s")))]),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-speedometer-slow")]),_vm._v(" "+_vm._s(_vm.$t("quota.relaxed"))+": "),_c('b',[_vm._v(_vm._s(_vm.liveTranscriptionRelaxed === "∞" ? _vm.$t("quota.unlimited") : (_vm.liveTranscriptionRelaxed + "s")))]),(_vm.hasAnalysisQuota)?_c('span',{staticClass:"caption font-italic text-pre-wrap"},[_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t("quota.tip.exceeded"))+" ")]):_vm._e()],1)])],1),_c('div',{staticClass:"pa-2 mx-2"},[_c(VTooltip,{attrs:{"right":"","color":_vm.hasAnalysisQuota ? 'error' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2",staticStyle:{"cursor":"default"}},on),[_c(VIcon,{class:("mr-2 " + (_vm.hasAnalysisQuota ? 'error--text' : 'primary--text'))},[_vm._v("mdi-chart-bar")]),_c(VCard,{staticClass:"body-2 px-3 py-1 d-inline-block font-weight-medium",staticStyle:{"background":"none"},attrs:{"flat":""}},[_c('div',{class:("body-2 d-inline " + (_vm.analysisFast === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.analysisFast)+" "),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-clock-fast")])],1),_c(VDivider,{staticClass:"ml-2 mr-1 h-full d-inline",attrs:{"vertical":""}}),_c('div',{class:("body-2 d-inline " + (_vm.analysisRelaxed === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.analysisRelaxed)+" "),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-speedometer-slow")])],1)],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("quota.tip.analysis"))),_c('br'),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-clock-fast")]),_vm._v(" "+_vm._s(_vm.$t("quota.fast"))+": "),_c('b',[_vm._v(_vm._s(_vm.analysisFast === "∞" ? _vm.$t("quota.unlimited") : (_vm.analysisFast + " Session(s)")))]),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-speedometer-slow")]),_vm._v(" "+_vm._s(_vm.$t("quota.relaxed"))+": "),_c('b',[_vm._v(_vm._s(_vm.analysisRelaxed === "∞" ? _vm.$t("quota.unlimited") : (_vm.analysisRelaxed + " Session(s)")))]),(_vm.hasAnalysisQuota)?_c('span',{staticClass:"caption font-italic text-pre-wrap"},[_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t("quota.tip.exceeded"))+" ")]):_vm._e()],1)])],1),_c('div',{staticClass:"pa-2 mx-2"},[_c(VTooltip,{attrs:{"right":"","color":_vm.hasAiFeedbackQuota ? 'error' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2",staticStyle:{"cursor":"default"}},on),[_c(VIcon,{class:("mr-2 " + (_vm.hasAiFeedbackQuota ? 'error--text' : 'primary--text'))},[_vm._v("mdi-chat-processing-outline")]),_c(VCard,{staticClass:"body-2 px-3 py-1 d-inline-block font-weight-medium",staticStyle:{"background":"none"},attrs:{"flat":""}},[_c('div',{class:("body-2 d-inline  " + (_vm.aiFeedbackFast === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.aiFeedbackFast)+" "),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-clock-fast")])],1),_c(VDivider,{staticClass:"ml-2 mr-1 h-full d-inline",attrs:{"vertical":""}}),_c('div',{class:("body-2 d-inline " + (_vm.aiFeedbackRelaxed === 0
                  ? 'grey--text text--lighten-1 font-weight-normal'
                  : 'font-weight-medium'))},[_vm._v(" "+_vm._s(_vm.aiFeedbackRelaxed)+" "),_c(VIcon,{staticClass:"body-2"},[_vm._v("mdi-speedometer-slow")])],1)],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("quota.tip.aiFeedback"))),_c('br'),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-clock-fast")]),_vm._v(" "+_vm._s(_vm.$t("quota.fast"))+": "),_c('b',[_vm._v(_vm._s(_vm.aiFeedbackFast === "∞" ? _vm.$t("quota.unlimited") : (_vm.aiFeedbackFast + " Feedback(s)")))]),_c('br'),_c(VIcon,{staticClass:"body-2 white--text mr-2"},[_vm._v("mdi-speedometer-slow")]),_vm._v(" "+_vm._s(_vm.$t("quota.relaxed"))+": "),_c('b',[_vm._v(_vm._s(_vm.aiFeedbackRelaxed === "∞" ? _vm.$t("quota.unlimited") : (_vm.aiFeedbackRelaxed + " Feedback(s)")))]),(_vm.hasAiFeedbackQuota)?_c('span',{staticClass:"caption font-italic text-pre-wrap"},[_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t("quota.tip.exceeded"))+" ")]):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }