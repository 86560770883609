class HeatMapEntry {
  public X: number;
  public Y: number;
  public Count: number;

  constructor(x: number, y: number, count: number) {
    this.X = x;
    this.Y = y;
    this.Count = count;
  }
}

export default HeatMapEntry;
