class SharedSession {
  sessionId: number;
  sessionName: string;
  sessionStatus: string;
  createdAt: string;
  duration: string;
  sharingUserName: string;
  sharingUserId: string;
  profilePicture: string;
  hasFeedback: boolean;
  hasFeedbackBeenRead: boolean;

  constructor(
    sessionId: number,
    sessionName: string,
    sessionStatus: string,
    createdAt: string,
    duration: string,
    sharingUserName: string,
    sharingUserId: string,
    profilePicture: string,
    hasFeedback: boolean,
    hasFeedbackBeenRead: boolean,
  ) {
    this.sessionId = sessionId;
    this.sessionName = sessionName;
    this.sessionStatus = sessionStatus;
    this.createdAt = createdAt;
    this.duration = duration;
    this.sharingUserId = sharingUserId;
    this.sharingUserName = sharingUserName;
    this.profilePicture = profilePicture;
    this.hasFeedback = hasFeedback;
    this.hasFeedbackBeenRead = hasFeedbackBeenRead;
  }

  public static parseSessions(responseData: any) {
    return responseData.map((entry: any) => this.parseSingleSession(entry));
  }

  public static parseSingleSession(data: any) {
    const sessionId = Number(data.sessionId);
    const sessionName = data.sessionName;
    const sessionStatus = data.sessionStatus;
    const createdAt = data.createdAt;
    const duration = data.duration;
    const sharingUserId = data.sharingUserId;
    const sharingUserName = data.sharingUserName;
    const profilePicture = data.profilePicture;
    const hasFeedback = data.hasFeedback;
    const hasFeedbackBeenRead = data.hasFeedbackBeenRead;

    return new SharedSession(
      sessionId,
      sessionName,
      sessionStatus,
      createdAt,
      duration,
      sharingUserName,
      sharingUserId,
      profilePicture,
      hasFeedback,
      hasFeedbackBeenRead,
    );
  }
}

export default SharedSession;
