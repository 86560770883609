class BaselineAnalysis {
  has?: boolean;
  pitch: number;
  volume: number;
  analyzed?: boolean;

  constructor(data: any) {
    if (!data || data === null) {
      this.pitch = 0;
      this.volume = 0;
      this.has = false;
      this.analyzed = false;
      return;
    }

    this.has = true;
    const { averagePitch, averageVolume } = data;
    if (averagePitch === -1 || averageVolume === -1) {
      this.pitch = 0;
      this.volume = 0;
      this.analyzed = false;
      return;
    }

    const pitch = Math.round(averagePitch * 100) / 100;
    const volume = Math.round(averageVolume * 100 * 100) / 100;
    this.analyzed = true;
    this.pitch = pitch;
    this.volume = volume;
  }
}
export default BaselineAnalysis;
