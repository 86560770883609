import Vue from "vue";
import VueI18N from "vue-i18n";
import en from "./en";
import de from "./de";

const numberFormats = {
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
    // Note: We always use en for compact formatting, de-DE doesn't support short formats like 1K or 1M
    compact: {
      style: "decimal",
      notation: "compact",
      maximumFractionDigits: 0,
      compactDisplay: "short",
      roundingMode: "floor",
    },
    compactFraction1: {
      style: "decimal",
      notation: "compact",
      maximumFractionDigits: 1,
      compactDisplay: "short",
      roundingMode: "floor",
    },
    compactFraction2: {
      style: "decimal",
      notation: "compact",
      maximumFractionDigits: 2,
      compactDisplay: "short",
      roundingMode: "floor",
    },
  },
};

Vue.use(VueI18N);
const i18n = new VueI18N({ locale: "de", messages: { en, de }, numberFormats });

export default i18n;
