class FileObject {
  public Name: string;
  public Size: number;
  public Type: string;
  public URI: string;

  constructor(name: string, size: number, type: string, uri: string) {
    this.Name = name;
    this.Size = size;
    this.Type = type;
    this.URI = uri;
  }

  static parseFile(data: any): FileObject | null {
    if (data == null) return null;
    return new FileObject(data.name, data.size, data.type, data.uri);
  }
}

export default FileObject;
