import { GetterTree } from "vuex";

import { MeetingsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<MeetingsState, RootState> = {
  getMeetings: state =>
    state.meetings.sort((a, b) => +b.CreatedAt - +a.CreatedAt),
  addMeetingLoading: state => state.addMeetingLoading,
  editedMeetingID: state => state.editedMeetingId,
  editMeetingLoading: state => state.editingMeeting,
  removedMeetingID: state => state.removedMeetingId,
  removeMeetingLoading: state => state.removeMeetingLoading,
};

export default getters;
