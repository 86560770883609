import {
  AiFeedback,
  AudioAnalysis,
  FileInfo,
  HeatMapEntry,
  MovementAnalysis,
  PDFInfo,
  SessionScores,
  TextAnalysis,
  TranscriptionSlide,
} from "@/core/models/sessions";
import Timestamp from "./Timestamp";
import FileObject from "../FileObject";
import { AnalysisStatus, SessionAvearges } from "../interfaces";

class Session {
  public ID: number;
  public Name: string;
  public AudioUrl: string;
  public CreatedAt: Date;
  public RecordingDate: Date;
  public SessionLength: number;
  public MovementAnalysis: MovementAnalysis;
  public Heatmap: HeatMapEntry[];
  public VideoInfo: FileInfo;
  public PDFInfo: PDFInfo;
  public TranscriptionStatus: string;
  public TextAnalysis: TextAnalysis;
  public AudioAnalysis: AudioAnalysis;
  public SessionScore: number;
  public AudioFile: FileObject | null;
  public VideoFromRecording: FileObject | null;
  public VideoFeedback: FileObject | null;
  public Completed: boolean;
  public Timestamps: Timestamp[];
  public Slides: TranscriptionSlide[];
  public SharedWith: string[];
  public PresentationId: number | null;
  public Averages: SessionAvearges;
  public AnalysisStatus = AnalysisStatus.NotStarted;
  public SessionScores: SessionScores;
  public AiFeedback: AiFeedback;

  // others
  public Loaded = false;
  public Loading = false;

  constructor(
    id: number,
    name: string,
    audioUrl: string,
    recording: string,
    createdAt: string,
    length: string,
    movement: any,
    heatmap: any,
    video: any,
    pdf: any,
    transcriptionStatus: string,
    text: any,
    audioAnalysis: any,
    score: any,
    audioFile: FileObject | null,
    videoFromRecording: FileObject | null,
    videoFeedback: FileObject | null,
    completed: boolean,
    timestamps: Timestamp[],
    slides: TranscriptionSlide[],
    sharedWith: string[],
    pres: number | null,
    averages: SessionAvearges,
    status: string,
    scores: any,
    aiFeedback: AiFeedback,
  ) {
    this.ID = id;
    this.Name = name;
    this.AudioUrl = audioUrl;
    this.RecordingDate = new Date(recording);
    this.CreatedAt = new Date(createdAt);

    const timespan = length.split(":");
    const total =
      Number(timespan[0]) * 3600 +
      Number(timespan[1]) * 60 +
      Number(timespan[2]);
    this.SessionLength = total || 0;
    this.MovementAnalysis = MovementAnalysis.parseMovementAnalysis(movement);
    if (video) this.VideoInfo = new FileInfo(video.title, video.uri);
    else this.VideoInfo = new FileInfo("", "");
    if (pdf) this.PDFInfo = new PDFInfo(pdf.name, pdf.pdfUri);
    else this.PDFInfo = new PDFInfo("", "");
    this.TranscriptionStatus = transcriptionStatus;
    this.TextAnalysis = TextAnalysis.parseTextAnalysis(text);
    this.AudioAnalysis = AudioAnalysis.parseAudioAnalysis(audioAnalysis);
    this.SessionScore = Math.round((score || 0) * 100) / 100;
    this.SessionScores = scores;
    this.AudioFile = audioFile;
    this.VideoFromRecording = videoFromRecording;
    this.VideoFeedback = videoFeedback;
    this.Completed = completed;
    this.Timestamps = timestamps;
    this.Slides = slides;
    this.SharedWith = sharedWith;
    this.PresentationId = pres;
    this.Averages = averages;
    this.AnalysisStatus = status;
    this.AiFeedback = aiFeedback;

    // Heatmap analysis
    if (heatmap && heatmap.length)
      this.Heatmap = heatmap.map(
        (entry: any) => new HeatMapEntry(entry.x, entry.y, entry.count),
      );
    else this.Heatmap = [];
  }

  public static parseSessions(responseData: any) {
    return responseData.map((entry: any) => this.parseSingleSession(entry));
  }

  public static parseSingleSession(data: any) {
    const id = Number(data.id);
    const name = data.name;
    const audio = data.audioSasUri;
    const date = data.recordingDate;
    const createdAt = data.createdAt;
    const length = data.sessionLength;
    const movement = data.movementAnalysis;
    const heatmap = data.heatmap;
    const video = data.videoInfo;
    const pdf = data.pdfInfo;
    const transcriptionStatus = data.transcriptionStatus;
    const text = data.textAnalysis;
    const audioAnalysis = data.audioAnalysis;
    const score = data.sessionScore;
    const audioFile = FileObject.parseFile(data.audioFile);
    const videoFile = FileObject.parseFile(data.videoFromRecording);
    const videoFeedback = FileObject.parseFile(data.videoFeedback);
    const timestamps = (data.timestamps as any[]).map(t => new Timestamp(t));
    const slides = data.transcriptionSlides as TranscriptionSlide[];
    const sharedWith = data.sharedWith as string[];
    const presId = data.presentationId;
    const averages = data.averages;
    const status = data.analysisStatus;
    const completed = data.completed || status === AnalysisStatus.Succeeded;
    const scores = data.sessionScores;
    const aiFeedback = data.aiFeedback;

    return new Session(
      id,
      name,
      audio,
      date,
      createdAt,
      length,
      movement,
      heatmap,
      video,
      pdf,
      transcriptionStatus,
      text,
      audioAnalysis,
      score,
      audioFile,
      videoFile,
      videoFeedback,
      completed,
      timestamps,
      slides,
      sharedWith,
      presId,
      averages,
      status,
      scores,
      aiFeedback,
    );
  }

  public static parseSlim(data: any) {
    const res = Session.Empty();

    res.Name = data.name;
    res.ID = Number(data.id);
    res.CreatedAt = new Date(data.createdAt);
    res.RecordingDate = new Date(data.recordingDate);

    const timespan = data.sessionLength.split(":");
    let total = 0;
    total += Number(timespan[0]) * 3600;
    total += Number(timespan[1]) * 60;
    total += Number(timespan[2]);
    res.SessionLength = Math.round(total || 0);

    res.VideoFeedback = FileObject.parseFile(data.videoFeedback);

    res.Completed = data.completed as boolean;
    res.AnalysisStatus = data.analysisStatus as string;
    res.Slides = data.transcriptionSlides as TranscriptionSlide[];
    res.SharedWith = data.sharedWith as string[];
    res.Averages = data.averages as SessionAvearges;
    res.PresentationId = data.presentationId
      ? Number(data.presentationId)
      : null;
    return res;
  }

  public static Empty() {
    return new Session(
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      "",
      "",
      "",
      "",
      "",
      "",
      null,
      null,
      null,
      false,
      [],
      [],
      [],
      null,
      {} as SessionAvearges,
      "",
      {} as SessionScores,
      {} as AiFeedback,
    );
  }
}

export default Session;
