import Employee from "./Employee";

class Company {
  ID: string;
  Name: string;
  Owner?: Employee;
  IsActive: boolean;
  Tier: string;
  Employees: Employee[];
  CreatedAt: Date;

  constructor(data: any) {
    const { id, name, subscription, tier, createdAt, companyUsers } = data;

    this.ID = id;
    this.Name = name;
    this.IsActive = (subscription && subscription.isActive) || false;
    this.Tier = tier;
    if (companyUsers)
      this.Employees = (companyUsers as object[]).map(e => new Employee(e));
    else this.Employees = [];
    this.Owner = this.Employees.find(e => e.IsOwner);
    this.CreatedAt = new Date(createdAt);
  }
}

export default Company;
