export interface RootState {
  version: string;
  appLoading: boolean;

  // Active
  active: boolean;
  dataLoading: boolean;
  dataLoadSuccess: boolean;

  // Snakcbar
  showSnackbar: boolean;
  snackbarText: string;
}

const state: RootState = {
  version: "1.0.0",
  appLoading: false,

  // Active
  active: false,
  dataLoading: true,
  dataLoadSuccess: true,

  // Snackbar
  showSnackbar: false,
  snackbarText: "",
};

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export { state, actions, mutations, getters };
