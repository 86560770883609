

































import Vue from "vue";
import { Getter } from "vuex-class";
import UploadSession from "./UploadSession.vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import { hasPermissions } from "@/core/utils/auth";

@Component({
  methods: { hasPermissions },
  components: { UploadSession },
})
export default class UploadVideo extends Vue {
  @PropSync("isUploadingProp") isUploading!: boolean;
  @Prop({ default: () => false }) mini!: boolean;
  @Getter("sessions/uploadingSession") loading!: boolean;

  dialog = false;
}
