import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","large":""}},on),[_c(VIcon,[_vm._v("mdi-bell")]),(_vm.newNotificationsCount > 0)?_c(VBadge,{attrs:{"content":_vm.newNotificationsCount}}):_vm._e()],1)]}}]),model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c(VCard,{attrs:{"elevation":"0","width":"500px"}},[_c(VList,{staticClass:"pa-0 overflow-y-auto",staticStyle:{"max-height":"435px"}},[_vm._l((_vm.notifications),function(notification){return _c(VListItem,{key:notification.submissionId,staticClass:"py-2 align-center",attrs:{"link":"","to":'/seminars/submission/' + notification.submissionId}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.getIcon(notification.type)))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.getNotificationTitle(notification.type))+" ")]),_c(VListItemSubtitle,[_vm._v(" by "+_vm._s(notification.user.username)+" ")]),_c(VListItemSubtitle,{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.getNotificationsTimeSpan(notification.date))+" ")])],1),(_vm.isNew(notification))?_c(VListItemIcon,[_c(VBadge,{attrs:{"inline":""}})],1):_vm._e()],1)}),_c(VDivider)],2),_c(VListItem,[_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"text":""},on:{"click":_vm.loadNotifications}},[_vm._v(" "+_vm._s(_vm.$t("toolbarMenu.refresh"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }