import { GetterTree } from "vuex";

import { RootState } from "@/core/store/root";
import { HowTosState } from "@/core/store/howtos";

const getters: GetterTree<HowTosState, RootState> = {
  getVideos: state => state.videos.sort((a, b) => +b.CreatedAt - +a.CreatedAt),
  loading: state => state.loading,
  retrieved: state => state.retrieved,

  addVideoLoading: state => state.addVideoLoading,
  editVideoLoading: state => state.editVideoLoading,
  editedVideoID: state => state.editedVideoID,
  deleteVideoLoading: state => state.deleteVideoLoading,
  deletedVideoID: state => state.deletedVideoID,
};

export default getters;
