import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import { RootState } from "../root";
import { SharedSessions } from "@/core/models";

export interface SharingUser {
  username: string;
  email: string;
}

export interface ManagedSessionsState {
  active: boolean;
  retrieved: boolean;
  usersLoading: boolean;
  selected: string | null;
  sessionsLoading: boolean;
  sharingUsers: SharingUser[];
  sharedSessions: SharedSessions[];
}

export const state: ManagedSessionsState = {
  retrieved: false,
  usersLoading: false,
  sessionsLoading: false,

  sharingUsers: [],
  active: false,
  selected: null,
  sharedSessions: [],
};

export const managed: Module<ManagedSessionsState, RootState> = {
  state,
  getters,
  actions,
  namespaced: true,
};
