import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import Vuetify, { UserVuetifyPreset } from "vuetify";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";

Vue.use(Vuetify);

const lightTheme: Partial<VuetifyThemeVariant> = {
  primary: "#2F9FF2",
  accent: "#DBDEE3",
  accentText: "#5B6974",
  lightgray: "#E3E9EE",
  lightgray2: "#F0F3F6",
  prezpRed: "#d55129",
  main: "#000",
  other: "#fff",
  darkgray: "#8f8f8f",
  green: "#009942",

  secondary: "#FF7729",
  anchor: "#808080",
  // background: "#e3e3e3",
  background: "#F0F3F6",
  lightgray3: "#b1b1b1",
  prezpGreen: "#568e1d",
  menu: "#36528b",
};

const darkTheme: Partial<VuetifyThemeVariant> = {
  primary: "#2F9FF2",
  accent: "#555",
  accentText: "#555",
  lightgray: "#444",
  lightgray2: "#333",
  prezpRed: "#d55129",
  main: "#fff",
  other: "#000",
  darkgray: "#f0f0f0",
  green: "#009942",

  secondary: "#FF7729",
  anchor: "#808080",
  background: "#222",
  lightgray3: "#b1b1b1",
  prezpGreen: "#568e1d",
  menu: "#36528b",
};

const options: UserVuetifyPreset = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: lightTheme,
      dark: darkTheme,
    },
  },
};

export default new Vuetify(options);
