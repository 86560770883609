import { MutationTree } from "vuex";

import { ProfileState } from ".";
import router from "@/core/router";
import {
  Subscription,
  User,
  Roles,
  RoleClaims,
  UserClaims,
} from "@/core/models";
import Storage from "@/core/utils/LocalStorage";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

const mutations: MutationTree<ProfileState> = {
  // Set
  set(state, user: User) {
    const { Roles } = user;
    state.user = user;
    Storage.setUserRole(Roles);
    state.loading = false;
    state.isAuthenticated = true;
    state.hasSubscription = user.subscription?.isActive || false;
    state.ownsCompany = user.ownsCompany;
  },
  setBaseline(state, baseline: BaselineAnalysis) {
    state.baseline = JSON.parse(JSON.stringify(baseline));
  },

  userLoadError(state) {
    state.isAuthenticated = false;
    state.user = undefined;
    Storage.clearStorage();
  },

  updateTokens(state, { accessToken, refreshToken }) {
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
  },

  // Forgot password
  forgotPasswordLoading(state, value: boolean) {
    state.forgotPasswordLoading = value;
  },
  forgotEmailSent(state) {
    state.forgotEmailSent = true;
    setTimeout(() => (state.forgotEmailSent = false), 10000);
  },
  forgotPasswordSuccess() {},
  forgotPasswordError() {},

  // Reset password
  resetPasswordLoading(state, value: any) {
    state.resetPasswordLoading = value;
  },
  resetPasswordSuccess() {
    router.replace("/auth/login");
  },
  resetPasswordError() {
    router.replace("/auth/forgotPassword");
  },

  // Confirm email
  confirmEmailLoading(state, value) {
    state.confirmEmailLoading = value;
  },

  // Company
  createCompanyLoading(state) {
    state.createCompanyLoading = true;
  },
  createCompany(state) {
    state.createCompanySuccess = true;
  },
  createCompanyLoaded(state) {
    state.createCompanyLoading = false;
  },

  upgradeLoading(state) {
    state.upgradeLoading = true;
  },
  upgradeSuccess(_, company) {
    console.log(company);
  },
  upgradeLoaded(state) {
    state.upgradeLoading = true;
  },
  setHasSubscription(state, hasSubscription: boolean) {
    state.hasSubscription = hasSubscription;
  },
  setUserRoles(state, roles: Roles[]) {
    state.userRoles = roles;
  },
  setPermissions(state, permissions: RoleClaims[]) {
    state.permissions = permissions;
  },
  setUserClaims(state, userClaims: UserClaims[]) {
    if (state.user) state.user.UserClaims = userClaims;
  },
  setSubscriptionDetails(state, subscriptionDetails: Subscription) {
    if (state.user) state.user.subscription = subscriptionDetails;
  },
};

export default mutations;
