import { MutationTree } from "vuex";

import { ContactState } from "@/core/store/contact/index";

const mutations: MutationTree<ContactState> = {
  set(state) {
    state.messageLoading = false;
    state.messageSent = false;
  },
};

export default mutations;
