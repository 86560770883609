import { Module } from "vuex";

import { RootState } from "../root";
import { HowToVideo } from "@/core/models";

// getters
import getters from "./getters";

// actions
import actions from "./actions";

// mutations
//import mutations from "./mutations";

export interface HowTosState {
  videos: HowToVideo[];
  loading: boolean;
  retrieved: boolean;

  // Helper loadings
  addVideoLoading: boolean;
  editVideoLoading: boolean;
  deleteVideoLoading: boolean;

  // Loading Helpers
  editedVideoID: number;
  deletedVideoID: number;
}

export const state: HowTosState = {
  videos: [],
  loading: false,
  retrieved: false,

  // Loadings
  addVideoLoading: false,
  editVideoLoading: false,
  deleteVideoLoading: false,

  // Loading Helpers
  editedVideoID: 0,
  deletedVideoID: 0,
};

export const howTos: Module<HowTosState, RootState> = {
  state,
  namespaced: true,
  //mutations: { ...mutations },
  getters: { ...getters },
  actions: { ...actions },
};
