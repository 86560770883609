import { Module } from "vuex";
import { RootState } from "../root";
import { actions } from "./actions";
import { getters } from "./getters";
import {
  Seminar,
  SeminarBlock,
  SeminarElement,
  Submission,
} from "@/core/models";

export interface SeminarsState {
  skipping: boolean;
  gettingAll: boolean;
  loading: boolean;
  adding: boolean;
  updating: boolean;
  retrieved: boolean;
  seminars: Seminar[];
  selected: Seminar | undefined;
  blockIndex: number;
  elementIndex: number;
  selectedBlock: SeminarBlock | undefined;
  selectedElement: SeminarElement | undefined;
  feedbackVideos: Map<number, Submission[]>;
  gettingFeedback: boolean;
}

export const state: SeminarsState = {
  gettingAll: false,
  skipping: false,
  loading: false,
  adding: false,
  updating: false,
  retrieved: false,
  seminars: [],
  selected: undefined,
  blockIndex: 0,
  elementIndex: 0,
  selectedBlock: undefined,
  selectedElement: undefined,
  feedbackVideos: new Map(),
  gettingFeedback: false,
};

export const seminars: Module<SeminarsState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
};
