const de = {
  dashboardPage: {
    yourGoalsCard: {
      yourGoals: "Deine Ziele",
      haveCompleted: "Du hast {number} Session{plural} abgeschlossen.",
      error: "Ein Fehler ist aufgetreten..",
      loadingGoals: "Ziele laden...",
    },
    groupsCard: {
      groups: "Deine Gruppen",
      viewAll: "Alle ansehen",
      information: "Information",
      created: "Erstellt:",
      users: "Nutzer",
      videos: "Videos",
      posts: "Beiträge",
      notGroupMember: "Du bist noch keiner Gruppe beigetreten.",
      groupsLoading: "Gruppen werden geladen ...",
    },
    videosCard: {
      all: "Alles",
      videos: "Aktuelle Videos aus deinen Gruppen",
      group: "Gruppe",
      loadingVids: "Videos werden geladen...",
      noGroups: "Du bist noch keiner Gruppe beigetreten.",
    },
    newsCard: {
      news: "Nachrichten",
      readAll: "Alles lesen",
      posted: "Gesendet",
      readMore: "Weiterlesen",
      noNews: "Es wurden noch keine Nachrichten veröffentlicht.",
      newsLoading: "Nachrichten werden geladen ...",
    },
    sessionsCard: {
      sessions: "Sessions",
      pitchHdg: "Tonhöhe",
      pitchSubHdgDeeper:
        "Du hast diese Woche im Vergleich zur letzten Woche mit tieferer Stimme gesprochen.",
      ptichSubHdgHigher:
        "Du hast diese Woche im Vergleich zur letzten Woche mit höherer Stimme gesprochen.",
      speechSpdHdg: "Sprechgeschwindigkeit",
      speechSpdSubHdgFaster: "Du hast heute schneller gesprochen als gestern.",
      speechSpdSubHdgSlower: "Du hast heute langsamer gesprochen als gestern.",
      hertz400: "400Hz",
      hertz600: "600Hz",
      thisWeek: "Diese Woche",
      wpm: "Wörter / Minute",
      today: "Heute",
      yesterday: "Gestern",
    },
    presentationsCard: {
      presentations: "Deine Präsentationen",
      mb: "MB",
      empty: "Leer",
    },
  },
  //isnt used
  topPanel: {
    settings: "Einstellungen",
    mail: "Mail",
    refresh: "Aktualisierung",
    logout: "Ausloggen",
  },
  //isnt used
  leftPanel: {
    dashboard: "Dashboard",
    sessions: "Sessions",
    groups: "Gruppen",
    news: "Nachrichten",
    goals: "Ziele",
    meetings: "Meetings",
    adminPanel: "Administrationsmenü",
  },
  sessionsPage: {
    noSessions: "Es konnten keine Sitzungen gefunden werden.",
    sessionsFor: "Sessions für ",
    yourSessionsSection: {
      yourSessions: "Sessions",
      sessionFilters: "Session Filter",
      filterByDate: "nach Datum filtern",
      beforeDate: "vor dem Datum",
      afterDate: "nach dem Datum",
      betweenTwoDates: "zwischen zwei Daten",
      filterByDuration: "nach Dauer filtern",
      timeSpan: "Zeitspanne (Minuten)",
      longerThan: "Länger als (Minuten)",
      shorterThan: "Kürzer als (Minuten)",
      minTime: "Mindestzeit (Minuten)",
      maxTime: "Maximale Zeit (Minuten)",
      filterByName: "Nach Namen filtern",
      startsWith: "Beginnt mit",
      endsWith: "Endet mit",
      includes: "Beinhaltet",
      exactMatch: "Genaue Übereinstimmung",
      searchTerm: "Suchbegriff",
      caseInsensitive: "Groß- und Kleinschreibung wird nicht berücksichtigt",
      filterByScore: "Filtern nach Punktzahl",
      greaterThanScore: "größer als",
      lessThanScore: "weniger als",
      scoreRange: "Punktebereich",
      applyButton: "anwenden",
      clearButton: "löschen",
      cancelButton: "abbrechen",
      fetching: "Session abrufen...",
    },
    sessionsListSection: {
      sessionsList: "Session Liste",
      sessions: "Sessions",
      checkSessions: "Aktualisieren",
      audioAnalHdg: "Audioanalyse",
      aiBasedAnal: "KI-basierte Analyse",
      audioAnalNotComplete:
        "Die Audioanalyse wurde noch nicht abgeschlossen. Du kannst entweder noch ein bisschen warten oder den Status überprüfen, indem du die Seite aktualisierst.",
      textAnalHdg: "Textanalyse",
      textAnalNotComplete:
        "Textanalyse nicht abgeschlossen. Du kannst die Seite aktualisieren, um zu überprüfen, ob die Sessionauswertung fertig ist, oder einfach noch ein bischen warten.",
      movementAnalHdg: "Bewegungsanalyse",
      movementAnalNoData: "Es sind keine Bewegungsdaten verfügbar.",
      show: "Zeigen",
      points: "Punkte",
      volumeWarning:
        "Eine zu leise oder laute Aufnahme kann die Qualität der Analyse Ergebnisse beeinflussen. Um präzise Analyse Ergebnisse zu erzielen versuche eine Lautstärke zwischen 20% und 80% zu erreichen.",
      movementAnalCard: {
        movementAnal: "Bewegungsanalyse",
        headMovementOverTime: "Kopfbewegung im Laufe der Zeit",
        headMoTInfo:
          "Die Kopfbewegung im Laufe der Zeit zeigt, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat.",
        headMovement: "Kopfbewegung",
        avgHeadMovement: "Durchschnittliche Kopfbewegung",
        infoHeadM:
          "Die Kopfbewegung zeigt, wie viel du dich mit deinem Kopf / Körper bewegen. Versuchst du, dich mit Grund und Zweck von einem Raum zum anderen zu bewegen.",
        handMovementOverTime: "Handbewegung im Laufe der Zeit",
        handMoTInfo:
          "Handbewegung im Laufe der Zeit zeigt, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat",
        handMovement: "Handbewegung",
        avgHandMovement: "Durchschnittliche Handbewegung",
        infoHandM:
          "Handbewegung zeigt an, wie viel du deine Hände / Gesten bewegen. Viele Bewegungen können dazu führen, dass du nervös erscheinst, und weniger Bewegung kann du als gelangweilt darstellen. Versuche deine Gesten als Werkzeug zum Aufbau von Spannung zu verwenden.",
        distance: "Entfernung",
        changeOverTime: "Veränderung im Laufe der Zeit",
        timePercent: "Zeit in %",
      },
      speechSpeedCard: {
        cardTitle: "Sprechgeschwindigkeit",
        wpmInfo:
          "Dies zeigt, wie schnell du sprichst. / Wie viele Wörter verwendest du durchschnittlich in einer Minute.",
        wpmTimeUnit: "Wörter/Minute",
        wpmNotice:
          "Für die meisten Vorträge sollte ein Zielwert zwischen 90 und 150 Wörter pro Minute angestrebt werden",
        infoTitle: "Sprechgeschwindigkeit und Hörgeschwindigkeit",
        infoText:
          "Die durchschnittliche Sprechgeschwindigkeit von 90 bis 150 Wörter pro Minute wird allgemein als angenehm und verständlich wahrgenommen. Ein Hörer kann 150 Wörter pro Minute noch gut verstehen. Lesen können wir aber durchschnittlich 260 Wörter pro Minute! Das bedeutet: Wenn wir viel Text auf einer Vortragsfolie haben, wird der Hörer intuitiv den Text lesen und nicht zuhören, bis der Redner den Text vorgelesen hat, weil es schneller geht. Das sollte man beim Konzipieren seiner Folien immer im Hinterkopf behalten.",
      },
      scoresCard: {
        infoTitle: "Session Ergebnisse",
        infoText: "Diese Ergebnisse wurden für dieses Session kalkuliert",
        cardTitle: "Session Ergebnisse",
        overall: "Gesamt",
        overallTip:
          "Der Gesamtwert ergibt sich als Durchschnitt der Einzelergebnisse. Wenn Du in einem Bereich weniger als 3 erreicht hast, wird dieser Wert dreifach gewichtet!",
        other: "Andere Ergebnisse",
        wpm: "Sprechgeschwindigkeit",
        wpmTip:
          "Für die meisten Vorträge sollte ein Zielwert zwischen 90 und 150 Wörter pro Minute angestrebt werden. Du hast mit einer Geschwindigkeit von {value} Wörtern gesprochen. ",
        wpmFasterTip: "Versuche beim nächsten Mal etwas schneller zu sprechen.",
        wpmSlowerTip: "Versuche beim nächsten Mal etwas langsamer zu sprechen.",
        pauses: "Pausen",
        pausesTip:
          "Ein guter Vortrag sollte genügend rhetorische Pausen (Pausen länger als 2 Sekunden) enthalten. Wir empfehlen dir zwischen 2-3 solcher Pausen in der Minute zu machen. Du hast {value} Pausen pro Minute verwendet. ",
        morePausesTip: "Versuche noch bewusster mehr Pausen zu machen. ",
        lessPausesTip:
          "Um den Vortrag flüssiger wirken zu lassen, versuche beim nächsten Mal deine Anzahl auf 2-3 Pausen zu reduzieren. ",
        repetition: "Wiederholungen",
        repetitionTip:
          "Eine sehr gute Rede sollte maximal zwei Wortwiederholungen pro Minute enthalten. Du hast in diesem Vortrag folgende Worte besonders häufig verwendet: ",
        filler: "Füllwörter",
        fillerTip:
          "Ein guter Vortrag sollte nicht mehr als zwei Füllwörter pro Minute enthalten. Du hast bei deinem Training insgesamt {value} Füllwörter pro Minute verwendet. ",
        fillerTipWordlist: "Diese waren: ",
        fillerTipContinued:
          "Kurze Füllwörter (wie z. B. Ähm) werden von der Spracherkennung nicht immer erkannt oder als Buchstabe (z. B. M) missverstanden. Bitte prüfe Deinen Vortrag nochmal selbst auf Füllwörter.",
        speaking: "Deutlichkeit",
        speakingTip:
          "Diese Note gibt dir an, wie deutlich die Aussprache in deinem Vortrag verstanden wird. Versuche hier möglichst den Maximalwert von 100% zu erreichen. Du hast in diesem Vortrag eine Deutlichkeit von {value}% erreicht. ",
        none: "Keine Ergebnisse für dieses Session",
      },
      feedback: {
        recordButton: "Feedback",
        recordTooltip: "Gib Feedback als Video",
        playButton: "Ansehen",
        playTooltip: "Sieh Dir Dein Feedback an",
        playDialogTitle: "Dein Feedback",
        deleteButton: "Löschen",
        deleteTooltip: "Lösche Dein Feedback",
        deleteConfirmationTitle: "Feedback löschen",
        deletionInfo:
          "Das Löschen Deines Feedbacks ist irreversibel. Bist du sicher, dass du mit dem Löschen Deines Feedbacks fortfahren möchtest?",
        confirmButton: "Bestätigen",
        cancelButton: "Abbrechen",
      },
    },
    uploadSessionSection: {
      uploadSession: "Session hochladen",
      createSessionFromAudio: "Session aus Audiodatei erstellen",
      sessionName: "Session Name *",
      audioFile: "Audiodatei *",
      videoFile: "Videodatei *",
      chooseLang: "Sprache wählen",
    },
    deleteSession: {
      deleteSessionButton: "Diese Session löschen",
      youSureDelete: "Möchtest du diese Session wirklich löschen?",
      deletionInfo:
        "Das Löschen einer Session ist irreversibel. Bist du sicher, dass du mit dem Löschen dieser Session fortfahren möchtest?",
      confirmSessDel: "Bestätigen",
      cancelSessDel: "Abbrechen",
    },
    sessionsProgressSection: {
      analysisStatus: {
        AnalyzingAudio: "Audio wird analysiert...",
        AnalyzingText: "Transkript wird erstellt...",
        AudioDone: "Audioanlyze fertig",
        ExtractingAudio: "Extrahiere Audio",
        Running: "Laufen...",
        NotStarted: "Noch nicht angefangen",
        Succeeded: "Erflog",
        null: "Laufen...",
        TextDone: "Transkribieren fertig",
      },
      sessionsProgress: "Session Fortschritte",
      analyzingSession: "Analysieren...",
      gettingSession: "Session abrufen...",
      recentData: "aktuelle Daten",
      display: "Anzeige",
      sessions: "Sessions",
      audioAnal: "Audioanalyse",
      textAnal: "Textanalyse",
      movementAnal: "Bewegungsanalyse",
      pitchOverTimeCard: {
        pitchOverTime: "Stimmhöhe im Laufe der Zeit",
        changeOverTime: "Veränderung im Laufe der Zeit",
        voicePitch: "Stimmhöhe",
        infoPoT:
          "Mit Pitch over Time kannst du den Fortschritt deiner Stimme sehen. Dies ist nützlich, wenn du trainieren möchtest, um eine tiefere oder höhere Stimme zu verwenden.",
      },
      volumeOverTimeCard: {
        volumeOverTime: "Lautstärke im Laufe der Zeit",
        voiceVolume: "Stimmen Lautstärke",
        changeOverTime: "im Laufe der Zeit ändern",
        infoVoT:
          "Lautstärke über im Laufe der Zeit zeigt den Fortschritt der Lautstärke deiner Stimme an.",
      },
      pausesOverTimeCard: {
        pausesOverTime: "Pausen-pro-Minute im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        totalPauseTime: "Gesamtpausenzeit",
        infoPoT:
          "Pausen im Laufe der Zeit zeigt an, wie viele Pausen du in den letzten Sessions gemacht hast.",
      },
      textAnalCard: {
        sentimentOverTime: "Stimmung im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        sentiment: "Stimmung",
        infoSoT:
          "Stimmung über im Laufe der zeigt deine Fortschritte im Hinblick auf eine positive Wortwahl.",
      },
      speechSpeedOverTimeCard: {
        speechSpeedOverTime: "Sprachgeschwindigkeit im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        wordsPerMinute: "Wörter / Minute",
        infoSSoT:
          "Dies zeigt die Geschwindigkeit deiner letzten Sessions im Vergleich zu früheren.",
      },
      movementAnalCard: {
        movementAnal: "Bewegungsanalyse",
        headMovementOverTime: "Kopfbewegung im Laufe der Zeit",
        headMoTInfo:
          "Die Kopfbewegung im Laufe der Zeit zeigt an, wie stark sich deine Bewegung in den letzten Sitzungen verändert hat.",
        headMovement: "Kopfbewegung",
        infoHeadM:
          "Die Kopfbewegung zeigt an, wie viel du dich mit deinem Kopf / Körper bewegst. Versuchst du, dich mit Grund und Zweck von einem Raum zum anderen zu bewegen.",
        handMovementOverTime: "Handbewegung im Laufe der Zeit",
        handMoTInfo:
          "Handbewegung im Laufe der Zeit zeigt an, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat.",
        handMovement: "Handbewegung",
        infoHandM:
          "Handbewegung zeigt an, wie viel du deine Hände / Geste bewegst. Eine Menge Bewegung kann dazu führen, dass du nervös erscheinst, und weniger Bewegung kann du als gelangweilt darstellen. Versuchst du, deine Gesten als Werkzeug zu verwenden, um Spannung aufzubauen.",
        distance: "Entfernung",
        changeOverTime: "Veränderung im Laufe der Zeit",
        timePercent: "Zeit in %",
      },
    },
    sessionFiltersPopUp: {
      sessionFilters: "Session-Filter",
      filterByDate: "Nach Datum filtern",
      filterByDuration: "Nach Dauer filtern",
      minTimeMin: "Mindestzeit (Minuten)",
      maxTimeMin: "Maximale Zeit (Minuten)",
      filterByName: "Nach Namen filtern",
      searchTerm: "Suchbegriff",
      caseInsensitive: "Groß- und Kleinschreibung nicht berücksichtigen",
      filterByScore: "Nach Punktzahl filtern",
      apply: "Anwenden",
      clear: "Löschen",
      cancel: "Abbrechen",
      ok: "OK",
      beforeDate: "Vor dem Datum",
      after: "Nach dem Datum",
      between: "Zwischen zwei Daten",
      startDate: "Anfangsdatum",
      endDate: "Enddatum",
    },
    aiFeedback: "Persönliches Feedback von deinem KI Coach",
    aiFeedbackTitle: "Dein KI Coach Feedback",
    aiSessionTitle: "KI-Unterstützte Session",
    new: "Neu",
  },
  groupsSections: {
    yourGroupsPage: {
      yourGroups: "Deine Gruppen",
      noGroups: "Du bist in keiner Gruppe.",
      addNewGroup: "Füge eine neue Gruppe hinzu",
      addGroupButton: "Gruppe hinzufügen",
      groupName: "Gruppenname",
      groupUsers: "Gruppenbenutzer",
      emailRules:
        "Trenne Emails durch Komma (,) oder durch Drücken der Leertaste, Tabulatortaste ('Tab') oder Eingabetaste ('Enter')",
      submitButton: "Einreichen",
    },
    //isnt used
    groupPage: {
      latestVideosCard: {
        latestVideos: "Neueste Videos",
        editVideo: "Video bearbeiten",
        deleteVideo: "Video löschen",
        addVidButton: "Video hinzufügen",
        addNewVideo: "Neues Video hinzufügen",
        vidTitle: "Videotitel (optional)",
        info: "Falls der Titel leer ist, wird er automatisch festgelegt",
        vidDescription: "Videobeschreibung (optional)",
        pinVideo: "Dieses Video anheften?",
        pinfo: "Das angeheftete Video wird zuerst angezeigt",
        linkButton: "Link",
        vidLink: "Video-Link",
        submit: "Bestätigen",
        fileButton: "Datei",
      },
      latestPostsCard: {
        latestPosts: "Neueste Beiträge",
        editPost: "Beitrag bearbeiten",
        deletePost: "Beitrag löschen",
        addPostButton: "Beitrag hinzufügen",
        postTitle: "Beitragstitel (erforderlich)",
        postBody: "Post Body (erforderlich)",
        pinPost: "Diesen Beitrag anheften?",
        uploadBannerImage: "Bannerbild hochladen?",
        bannerImage: "Banner Bild",
        otherFiles: "Andere Dateien",
        postButton: "Beitrag",
      },
      usersCard: {
        users: "Benutzer",
        remove: "Benutzer entfernen",
        makeManager: "Zum Manager machen",
        removeManager: "Manager Rolle entfernen",
      },
      addUserCard: {
        addANewUser: "Neuen Benutzer hinzufügen",
        addUserButton: "Benutzer hinzufügen",
        userEmail: "Benutzer Email",
        makeManager: "Zum Manager machen",
        submitButton: "einreichen",
      },
    },
  },
  latestNewsSections: {
    latestNewsPage: {
      latestNews: "Neueste Beiträge",
      noNews: "Noch keine Beiträge gepostet.",
      newsLoading: "Beiträge werden geladen ...",
      read: "Lesen",
      back: "Zurück",
      edit: "Bearbeiten",
      delete: "Löschen",
      err404: "404",
      artWithId: "Artikel mit ID",
      notFound: "konnte nicht gefunden werden.",
      goBack: "zurückgehen",
      addArticleButton: "Artikel hinzufügen",
      addArticlePopUp: {
        addNewArticle: "Fügt einen Artikel hinzu",
        articleTitle: "Artikelüberschrift",
        cannotBeEmpty: "Feld darf nicht leer sein",
        articleBody: "Artikelinhalt",
        addBannerImg: "Banner Image hinzufügen",
        bannerImg: "Banner Bild",
        otherFiles: "Andere Dateien",
        addArticle: "Artikel hinzufügen",
      },
    },
    articleActions: {
      edit: "Bearbeiten",
      editArticle: "Artikel bearbeiten",
      newArticleTitle: "Neuer Artikel-Titel",
      newArticleBody: "Neuer Artikel-Text",
      done: "Fertig",
      deleteArticle: "Artikel löschen",
      areYouSure: "Möchtest du den aktuellen Artikel wirklich löschen?",
      confirm: "Bestätigen",
      cancel: "Abbrechen",
    },
    addArticleButton: {
      addArticle: "Artikel hinzufügen",
      addNewsArticle: "Füge einen Nachrichtenartikel hinzu",
      articleTitle: "Artikeltitel",
      articleBody: "Artikelinhalt",
      addBannerImage: "Bannerbild hinzufügen",
      bannerImage: "Banner Bild",
      otherFiles: "Andere Dateien",
    },
  },
  goalsSections: {
    yourGoals: "Deine Ziele",
    assignedGoalsCard: {
      assignedGoals: "Zugewiesene Ziele",
      completeNumSessions: "Schließe #### Sitzungen ab",
      dueDate: "Fällig in #### Jahren",
      completedSessions: "Du hast #### Sessions abgeschlossen",
      noGoalAssignments: "Dir wurden keine Ziele zugewiesen.",
    },
    createdGoalsCard: {
      createdGoals: "Erstellte Ziele",
      completeNumSessions: "Schließe #### Sessions",
      goalCreatedAtMonth: "vor einem Monat",
      goalCreatedAtMinutes: "vor x Minuten",
      sessionsThatCount:
        "Nur Sitzungen mit einer Punktzahl größer als ## zählen",
      sentimentAim: "Strebe einen Stimmungswert zwischen {from}% und {to}% an.",
      pausesAim: "Versuche x- bis y-Pausen zu machen",
      pitchAim:
        "Strebe eine durchschnittliche Tonhöhe zwischen ## Hz und ## Hz an",
      volumeAim:
        "Strebe du eine durchschnittliche Lautstärke zwischen ## dB und ## dB an",
      noCreatedGoals: "Du hast keine Ziele erstellt.",
    },
    addGoalButton: "Ziel hinzufügen",
    addGoalPopUp: {
      addNewGoal: "Neues Ziel hinzufügen",
      addCondition: "Bedingung hinzufügen",
      conditionNum: "Bedingung #x",
      conditionType: "Art der Bedingung (erforderlich)",
      dueDate: "Fälligkeitsdatum",
      sessionScore: "Session Ergebnis",
      targetScore: "Zielpunktzahl",
      sentiment: "Stimmung",
      targetSentiment: "Ziel Stimmung",
      avgPitch: "Durchschnittliche Tonhöhe",
      targetPitch: "Ziel Tonhöhe",
      avgVolume: "Durchschnittliches Volumen",
      targetVolume: "Zielvolumen",
      pausesPerMin: "Pausen pro Minute",
      targetPauses: "Zielpausen",
      assignToUsers: "Benutzer zuweisen",
      selectAll: "Alle auswählen",
      deselectAll: "Alle abwählen",
      assignSelf: "Sich zuweisen?",
      submit: "Ziel senden",
    },
  },
  meetingsSection: {
    yourMeetings: "Deine Meetings",
    allMeetingsCard: {
      allMeetings: "Alle Meetings",
      subject: "Betreff",
      noSubject: "Kein Betreff festgelegt",
      noMeetings: "Keine anstehenden Meetings.",
      joinMeeting: "Einem Meeting beitreten",
      editMeeting: "Meeting bearbeiten",
      cancelMeeting: "Meeting abbrechen",
      dateFuture: "in xx Tagen",
      datePast: "vor xx Tagen",
      done: "Fertig",
    },
    addMeetingButton: "Meeting hinzufügen",
    addNewMeetingPopUp: {
      addNewMeeting: "Füge eine neue Besprechung hinzu",
      meetingSub: "Besprechungsthema",
      chooseStart: "Wähle das Startdatum / -zeit",
      chooseEnd: "Wähle das Enddatum / die Endzeit",
      assignUsers: "Benutzer zuweisen",
      selectAll: "Alle auswählen",
      deselectAll: "Auswahl löschen",
      additionalUsers: "Zusätzliche Nutzer",
      emailRule:
        "Trenne Emails durch ein Komma (,) oder durch Drücken der Leertaste, Tabulatortaste ('Tab') oder Eingabetaste ('Enter')",
      setPass: "Passwort festlegen?",
      pass: "Passwort",
      meetingTypes: "Besprechungstypen",
      blizzMeeting: "Blizz Meeting",
      customMeeting: "benutzerdefinierte Meeting",
      customMeetingLink: "Benutzerdefinierter Meeting-Link",
      addMeeting: "Meeting hinzufügen",
      dateError: "Das Enddatum des Meetings muss nach dem Startdatum liegen.",
    },
  },
  adminPanelSection: {
    allUsers: "Alle Nutzer",
    changeRole: "Rolle ändern",
    changeRolePopUp: {
      changeRoleUser: "Ändere die Rolle für den Benutzer",
      submit: "Senden",
      cancel: "Abbrechen",
      admin: "Admin",
      user: "Benutzer",
      owner: "Besitzer",
    },
  },
  passwordResetSection: {
    resetPass: "Password zurücksetzen",
    error: "Ein Fehler ist aufgetreten.",
    newPassRequired: "Neues Passwort (erforderlich)",
    confirmPass: "Neues Passwort bestätigen (erforderlich)",
    doneRedirect: "Fertig! Wir leiten dich in 5 Sekunden zum Login weiter.",
    couldNotResetPass: "Passwort konnte nicht zurückgesetzt werden",
    resetMyPass: "Passwort zurücksetzen",
    backToForgotPass: 'Zurück zu "Passwort vergessen"',
    passRequired: "Passwort ist erforderlich",
    passMustBe: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    passMustContain:
      "Das Passwort muss mindestens je einen Klein- und Großbuchstaben, eine Ziffer, sowie ein Sonderzeichen enthalten.",
    confirmPassRequired: "Passwortbestätigung erforderlich.",
    passNoMatch: "Passwörter stimmen nicht überein.",
  },
  registerSection: {
    error: "Ein Fehler ist aufgetreten.",
    fNameRequired: "Vorname (erforderlich)",
    lNameRequired: "Nachname (erforderlich)",
    firstName: "Vorname",
    lastName: "Nachname",
    companyName: "Firmenname",
    emailRequired: "Email (erforderlich)",
    passwordRequired: "Passwort (erforderlich)",
    confirmPassRequired: "Passwort bestätigen (erforderlich)",
    agree: "Ich stimme den",
    termsNCond: "Allgemeinen Geschäftsbedingungen",
    agreeEnd: "zu",
    next: "Weiter",
    back: "Zurück",
    register: "Registrieren",
    fName: "Vorname ist erforderlich",
    fNameMin: "Der Vorname muss mindestens 2 Zeichen lang sein",
    lName: "Nachname ist erforderlich",
    lNameMin: "Nachname muss mindestens 2 Zeichen lang sein",
    flName: "Vorname und Nachname sind erforderlich",
    email: "Email ist erforderlich",
    invalidEmail: "Ungültige Email",
    emailAlreadyTaken: "Es existiert bereits ein Benutzer mit dieser Email",
    passRequired: "Passwort ist erforderlich",
    passMin: "Passwort muss mindestens {min} Zeichen lang sein",
    passMax: "Passwort darf nicht länger als {max} Zeichen sein",
    passRules:
      "Das Passwort muss Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen enthalten",
    passwordExplanation:
      "Dein Passwort muss mindestens 8 Zeichen haben. Es muss Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen beinhalten.",
    confirmPass: "Passwortbestätigung erforderlich",
    passMismatch: "Passwörter stimmen nicht überein",
    tOS: "Bitte lese die Nutzungsbedingungen und stimme diesen zu",
    registerFailed: "Es ist ein Fehler aufgetreten",
    validationError: "Deine angegebenen Daten sind unvollständig",
    termsAndServices:
      "https://stprezpshareddata.blob.core.windows.net/hosted-files/Nutzungsbedingungen_Prezp_Deutsch_03.03.2021.pdf#toolbar=0",
    yourInfo: "Deine Angaben",
    yourEmail: "Email",
    yourName: "Benutzername",
    registerToCompany: "Registriere Dich zum Beitreten des Unternehmens",
    andSeminar: "und dem Seminar",
  },
  onboardingSection: {
    titleFirstPart: "YOUR COMMUNICATION TRAINING", // this is left in English by design
    titleSecondPart: "MENSCHEN GEWINNEN",
    bannerContent:
      "Du möchtest besser kommunizieren, packender reden und erfolgreicher verkaufen? Jetzt loslegen!",
    learnMoreBtn: "Mehr erfahren",
    footerLinkPrivacy: "Datenschutz",
    footerLinkLegal: "Legal",
    footerLinkContact: "Kontakt",
  },
  loginSection: {
    dontHaveAccount: "Du hast noch keinen Account?",
    register: "Registrieren",
    signIn: "Einloggen",
    title: "Bei PREZP einloggen",
    error: "Ein Fehler ist aufgetreten.",
    unconfirmedEmail:
      "Bitte bestätige Deine E-Mail Adresse über den Link in Deiner Registrierungsemail. Dein Account ist noch nicht aktiviert.",
    wrongPasswordError:
      "Dein Benutzername oder Dein Passwort sind nicht korrekt!",
    unauthorizedError:
      "Autorisierungsfehler. Bitte stelle sicher, dass Du angemeldet bist.",
    email: "Email",
    password: "Passwort",
    forgotPass: "Passwort vergessen",
    emailPlaceHolder: "demo@prezp.com",
    invalidEmail: "Ungültige Email",
    emailRequired: "Email ist erforderlich",
    passRequired: "Passwort ist erforderlich",
    passMin: "Das Passwort muss mindestens 6 Zeichen lang sein",
    passMax: "Das Passwort darf nicht länger als 16 Zeichen sein",
  },
  newUser: {
    thanks:
      "Vielen Dank, dass du dich bei PREZP registriert hast. Bitte bestätige Deine E-Mail Adresse, in dem Du in der Registrierungsmail auf den Link klickst, um Dein Konto zu aktivieren.",
    welcome: "Willkommen bei PREZP",
  },
  inactiveProfile: {
    notActivated:
      "Dieses Profil wurde nicht aktiviert. Für weitere Informationen wendest du dich bitte an die Administratoren dieser Website.",
  },
  forgotPassSection: {
    signUp: "Willst du dich anmelden?",
    register: "Registrieren",
    wantToSignIn: "Möchtest du dich anmelden?",
    login: "Einloggen",
    resetPass: "Passwort zurücksetzen",
    error: "Ein Fehler ist aufgetreten.",
    email: "Email",
    sentEmail: "Eine Email wurde gesendet an",
    followLinkResetPass:
      "Folge dem Link in der Email, um dein Passwort zurückzusetzen.",
    emailSent: "Email gesendet!",
    sendReset: "Email zurücksetzen senden",
    invalidEmail: "Ungültige Email",
    emailRequired: "Eine Email-Adresse ist erforderlich",
    passRequired: "Passwort ist erforderlich",
    passMinRule: "Das Passwort muss mindestens 6 Zeichen lang sein",
    passMaxRule: "Passwort darf nicht länger als 16 Zeichen sein",
    emailPlaceHolder: "demo@prezp.com",
    haveAnAccount: "Du hast schon einen Account?",
    signIn: "Einloggen",
    forgotPass: "Passwort vergessen?",
  },
  confirmEmailSection: {
    emailConfirmed: "Email erfolgreich bestätigt!",
    contWeb: "Weiter zur Website",
    contApp: "Weiter zur App",
    wait: "Gib uns eine Sekunde ...",
    error: "Ein Fehler ist aufgetreten.",
    couldNotConfirm: "Email konnte nicht bestätigt werden ...",
    backToLogin: "Zurück zum Login",
  },
  registerDeviceSection: {
    deviceAuthentication: "Geräteauthentifizierung",
    toActivate: "Um dieses VR Gerät zu aktivieren",
    step1: "1. Gehe auf deinem Computer oder Mobilgerät zu:",
    step2: "2. Gebe den folgenden Code ein:",
    step3:
      "3. Drücke auf „Weiter“ sobald Du dich auf deinem Computer oder Mobilgerät authentifiziert hast:",
    continue: "Weiter",
    wait: "Gib uns eine Sekunde ...",
    error: "Ein Fehler ist aufgetreten.",
    couldNotGetCode: "Code konnte nicht angefordert werden ...",
    couldNotAuthenticate:
      "Anmeldefehler, bitte überprüfe die Authentifizierung auf dem 2. Gerät.",
    backToLogin: "Zurück zum Login",
  },
  confirmDeviceSection: {
    deviceConfirmed: "Gerät erfolgreich authorisiert!",
    deviceConnected:
      "Dein Gerät ist jetzt verbunden und kann verwendet werden.",
    contApp: "Weiter zur App",
    deviceActivation: "Geräteaktivierung",
    enterCode: "Gebe den auf deinem Gerät angezeigten Code ein",
    oneTimeCode: "Einmalcode",
    confirmDeviceCode:
      "Bitte bestätige, dass dies der auf deinem VR Headset angezeigte Code ist:",
    notInitiated:
      "Wenn Du diese Aktion nicht eingeleitet hast oder dieses Gerät nicht erkennst, wähle „Abbrechen“.",
    continue: "Weiter",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    wait: "Gib uns eine Sekunde ...",
    error:
      "Ein Fehler ist aufgetreten. Gerät konnte nicht authorisiert werden.",
    couldNotConfirm: "Bitte überprüfe den Aktivierungscode.",
    backToLogin: "Zurück zum Login",
    codeRequired: "Code ist erforderlich",
    codeMin: "Code muss mindest {min} Zeichen lang sein",
    codeMax: "Code darf maximal {max} Zeichen lang sein",
  },
  audioSection: {
    startAnal: "Analyse starten",
  },
  scoreDetailsSection: {
    sessionScore: "Session Score",
  },
  sentimentSection: {
    breakdown: "Aufschlüsselung nach Satz",
    positive: "Positiv",
    negative: "Negativ",
    neutral: "Neutral",
    noAnalyzedSentence: "Es wurden keine Sätze analysiert.",
    sentimentRatio: "Stimmung",
  },
  pausesCard: {
    pauses: "Pausen",
    spentSubVerb: "Du hast ausgegeben",
    spentPrep: "Zeit in Pause.",
    number: "Pausen gemacht",
    average: "Durchschnittliche Pausenzeit",
    total: "Gesamtpausenzeit",
    timeUnit: "Sekunden",
    warning:
      "Pausen werden ab 2 Sekunden definiert. Wenn die Stimme zu leise ist kann dies als Pause interpretiert werden.",
    ppm: "Pausen/ Minute",
    ppmCaps: "PAUSEN / MINUTE",
  },
  movementRatioCard: {
    movementRatio: "Bewegungsverhältnis",
    headMovement: "Kopfbewegung",
    handMovement: "Handbewegung",
    noMoveData: "Keine Bewegungsdaten gefunden.",
  },
  managedUsersCard: {
    usersManaged: "Benutzer, die du verwaltest",
    noManagedUsers: "Du verwaltest keine Benutzer",
  },
  keywordsCard: {
    keywords: "Schlüsselwörter",
    basedOn: "Basierend auf KI",
  },
  audioCard: {
    yourAudio: "Dein Audio",
    audioFileInfo:
      "Dies ist die Audiodatei, die du für diese Sitzung aufgenommen hast. Während du sie abspielst werden die Diagramme aktualisiert",
  },
  volumeCard: {
    voiceVolume: "Stimmen Lautstärke",
    avgVoiceVol: "Durchschnittliches Volumen",
  },
  pitchCard: {
    voicePitch: "Stimmhöhe",
    avgVoicePitch: "Durchschnittliche Tonhöhe",
  },
  sentimentRatioCard: {
    sentimentRatio: "Stimmung",
  },
  appBar: {
    notConfirmedEmail:
      "Email nicht bestätigt. Bitte klicke auf den Link in der Mail.",
    sending: "Senden...",
    sendEmail: "Email senden",
    emailSent: "Email gesendet!",
  },
  toolbarMenu: {
    settings: "Einstellungen",
    mail: "Mail",
    refresh: "Aktualisieren",
    logout: "Abmelden",
  },
  profileDropdown: {
    loading: "Laden...",
    profile: "Profil",
    checkoutSettings: "Überprüfe die Einstellungen",
    logout: "Abmelden",
  },
  groupName: {
    newName: "Neuer Gruppenname",
    rename: "Umbenennen",
    nameRule: "Name muss mindestens 3 Buchstaben lang sein",
  },
  groupItem: {
    deleteGroup: "Gruppe löschen",
    areYouSure: "Möchtest du die Gruppe wirklich löschen?",
    deleteInfo: "Dadurch werden auch alle damit verbundenen Videos gelöscht.",
    confirm: "Bestätigen",
    cancel: "Abbrechen",

    leave: "Gruppe verlassen",
    join: "Gruppe beitreten",
    groupLeft: "Du hast Gruppe {name} verlassen",
    groupJoined: "Du bist Gruppe {name} beigetreten",
    confirmLeave: "Bist du sicher, dass Du {name} verlassen möchtest?",
    confirmJoin: "Bist du sicher, dass Du {name} beitreten möchtest?",
  },
  addGroupCard: {
    addNewGroup: "Neue Gruppe hinzufügen",
    groupName: "Gruppenname",
    users: "Mitglieder hinzufügen",
    submit: "Bestätigen",
    addGroup: "Gruppe hinzufügen",
    publicGroup: "Öffentliche Gruppe",
    isVisibleToCompany: "Unternehmensgruppe",
  },
  // Navigation panel
  nav: {
    dash: "Dashboard",
    sessions: "Sessions",
    list: "Session Liste",
    submitted: "Eingereichte Sessions",
    progress: "Session Fortschritt",
    groups: "Gruppen",
    news: "Neuigkeiten",
    goals: "Ziele",
    meetings: "Meetings",
    company: "Unternehmen",
    howto: "Tutorials",
    presentation: "Präsentation",
    admin: "Admin Bereich",
    sessionList: "Session Liste",
    sessionProgress: "Session Fortschritt",
    seminars: "Seminare",
    seminarsDash: "Dashboard",
    seminarsProg: "Fortschritt",
    seminarsAdmin: "Seminar Admin",
  },
  // New stuff
  global: {
    loading: "Laden",
    submit: "Absenden",
    empty: "Leer",
    close: "Schliessen",
    requiredf: "Pflichtfeld",
    cancel: "Abbrechen",
    files: "Angehängte Dokumente",
    confirm: "Bestätigen",
    none: "Keine",
    done: "Bestätigen",
    save: "Änderungen speichern",
    refresh: "Aktualisieren",
    reset: "zurücksetzen",
    clear: "Auswahl zurücksetzen",
    nomatch: "Keine Elemente, die deiner Suche entsprechen",
    upload: "Hochladen",
    create: "Erstellen",
    back: "Zurück",
    invalidURL: "ungültige URL",
    err: "Es ist ein Fehler aufgetreten",
    retry: "Noch einmal versuchen",
    backToLogin: "Zurück zum Login",
    backToSignUp: "Zurück zur Anmeldung",
    contApp: "Weiter zur App",
    toApp: "PREZP Anwedung",
    backToDashboard: "Zurück zum Dashboard",
    imprint: "Impressum",
    dataprotection: "Datenschutz",
    dataprotection_link:
      "https://prezpstore.blob.core.windows.net/site-files/PREZP_Datenschutzerkla%CC%88rung_07112022.pdf?sp=r&st=2022-11-08T10:52:05Z&se=2032-11-08T18:52:05Z&spr=https&sv=2021-06-08&sr=b&sig=SRBEScXShur3bga4yonler4KzE447kXOhqazQLowMvo%3D",
    feedback: "Für Feedback und Fragen: info@prezp.com",
    nodata: "Keine Daten verfügbar",
    remove: "Entfernen",
  },

  forms: {
    invalidform: "Diese Form ist ungültig",
    invalidfield: "Dieses Feld ist ungültig",
    reqfield: "Dieses Feld ist erforderlich",
    audiofile: "Bitte gebe eine Audiodatei an",
    videofile: "Bitte gebe eine Videodatei an",
    namelen3: "Der Name muss mindestens 3 Buchstaben lang sein",
    file: "Bitte gebe eine Datei an",
    invaliddata: "Ungültige Eingabedaten",
    errors: "Kann mit vorhandenen Fehlern nicht fortgesetzt werden",
    missing: "Einige Dateien fehlten",
    contact: {
      title: "Kontakt",
      email: "Email",
      emailPlaceHolder: "demo@prezp.com",
      message: "Nachricht",
      messagePlaceHolder: "Schreibe eine Nachricht",
      submit: "Nachricht Senden",
      invalidEmail: "Ungültige Email",
      emailRequired: "Email ist erforderlich",
      messageRequired: "Nachricht ist erforderlich",
      success: "Nachricht wurde gesendet",
      fail: "Nachricht konnte nicht gesendet werden",
    },
  },

  upsess: {
    button: "Audio hochladen",
    title: "Neue Session erstellen aus Audiodatei",
    subtitle: "Neue Session erstellen",
    text:
      'Erstelle eine Session auf Basis einer Audiodatei. Die Analyse Ergebnisse können auf der Seite "Session" angezeigt werden. Die Analyse beginnt direkt nach dem Hochladen.',
    go: "Jetzt erstellen",
    hint:
      "Wir unterstützen die gängigsten Audiodateiformate wie mp3, wav, ogg, m4a* und mpeg*",
    smallprint:
      "* In seltenen Fällen kann die Transkription für .m4a- oder .mpeg-Dateien nicht erstellt werden",

    select: "Präsentation wählen",
    rec: "Session aufzeichnen",
    vrrec: "VR Aufnahme",
    rectip: "Neue Session direkt aufzeichnen",
    rectext: "Nimm eine neue Session mit oder ohne Präsentation auf",
    rejected_file:
      "Nicht unterstütztes Dateiformat. Nur Audioformate erlaubt (mp3, wav, ogg, m4a, mpeg)",
    errorHeading: "Fehler",
    videoTooBig:
      "Dein Video ist zu groß (> 400 MB), um hochgeladen zu werden. Bitte verringere die Auflösung oder kürze Deine Präsentation.",
    videoTooBigDownloadHint:
      "Hinweis: Du kannst dein Video herunterladen, um die Auflösung zu reduzieren. Klick einfach die drei senkrechten Punkte in der rechten unteren Ecke des Videos an.",
    uploading: "Audio wird hochgeladen...",
    uploadComplete: "Audio-Upload abgeschlossen",
  },
  upsessv: {
    button: "Video hochladen",
    title: "Neue Session erstellen aus Videodatei",
    text:
      "Erstelle eine Session auf Basis einer Videodatei. Die Ergebnisse der Analyse werden in der Liste der Sessions angezeigt. Die Analyse startet direkt nach dem Hochladen.",
    hint: "Wir unterstützen als Videodateiformate ausschließlich mp4",
    smallprint:
      "* In seltenen Fällen kann eine Videodatei nicht verarbeitet werden",
    rejected_file:
      "Nicht unterstütztes Dateiformat. Es ist nur das MP4-Videoformat zulässig.",
    noText: "Es konnte kein gesprochener Text erkannt werden.",
    uploading: "Video wird hochgeladen...",
    uploadComplete: "Video-Upload abgeschlossen",
  },
  transcription: {
    start: "Start",
    stop: "Halt",
    uploadRec: "Aufnahme hochladen",
    nextSlide: "Nächste Folie",
    resume: "fortsetzen",
    pause: "pausiere",
    audioOnly: "Nur Audio",
    video: "Video",
    total: "Gesamt",
    duration: "Dauer: ",
    wpm: "Wörter pro Minute: ",
    occurrences: "Wortvorkommen: ",
    slides: "Folien",
    slide: "Folie",
    wpmLower: " Wörter pro Minute",
    transAudio: "Transkribiertes Audio",
    awaitingText: "Warte auf Texterkennung...",
  },

  quota: {
    title: "PREZP Kontingent",
    fast: "Schnelle Verarbeitung",
    relaxed: "Entspannte Verarbeitung",
    upgrade: "Upgrade",
    unlimited: "Unbegrenzt",

    tip: {
      liveTranscription:
        "Dein verbleibendes Kontingent für Live-Transkriptionen (als Timer)",
      analysis: "Dein verbleibendes Kontingent für die Sessionanalyse",
      aiFeedback: "Dein verbleibendes Kontingent für KI-Feedbacks",
      exceeded:
        "Dein Kontingent ist aufgebraucht. Du kannst deinen Plan upgraden \n oder bis zum Ende des nächsten Reset-Zeitraums warten.",
    },
  },

  recording: {
    maxTimeWarning: {
      heading: "Aufnahmelimit",
      maxAllowedTime: "{minutes} Minuten",
      maxAllowedHint:
        "Im Stil von TED Talks: Die maximale Dauer für die Sessionaufnahme liegt bei {0}. Für einen optimalen Vortrag empfehlen wir eine Zielzeit von {1}.",
      confirm: "Los Gehts",
      cancel: "Nicht bereit",
      dontAskAgain: "Nicht mehr anzeigen",
    },
    shortRecordWarning: {
      heading: "Kurze Aufnahme",
      recordingTime: "{seconds} Sekunden",
      quotaLeftBoth:
        "Deine Analysekontingente - Schnell: {fastQuota}, Entspannt: {relaxedQuota}",
      recordingTimeHint:
        "Die Aufnahme hat nur eine Dauer von {0}. Wenn du diese Aufnahme analysieren lässt, wird dies dein Analysekontingent um 1 verringern.\n{1}\nBist du dir sicher?",
      confirm: "Ja, Hochladen",
      cancel: "Abbrechen",
    },
    noCompatibleDevice: "Es sind keine kompatiblen Geräte verfügbar.",
    noDevicePermission:
      "Für die Aufnahme sind Kamera- und Mikrofonberechtigungen erforderlich.",
    noMicrophonePermission:
      "Für die Aufnahme sind Mikrofonberechtigungen erforderlich.",
  },

  alerts: {
    beforeLeaveRecording:
      "Seite wirklich verlassen? Die Aufnahme wird hierdurch abgebrochen!",
    beforeLeaveUpload:
      "Seite wirklich verlassen? Der Upload wird hierdurch abgebrochen!",
  },

  groups: {
    groups: "Gruppen",
    public: "Öffentliche Gruppen",
    company: "Unternehmensgruppen",
    all: "Alle Gruppen",
    none: "Du bist noch keiner Gruppe zugeordnet",
    hint:
      "Trenne Emails mit Komma (,) mit der Tabulatortaste ('Tab') oder mit der Eingabetaste ('Enter')",
    created: "Erstellt am",
    delete: "Gruppe löschen",
    you: "(du)",
    creator: "Gruppengründer",
    joined: "Mitglied seit",
    removeu: "Benutzer entfernen",
    confrem: "Möchtest du {u} wirklich aus der Gruppe entfernen?",
    remself: "dich selbst",
    videos: "Neueste Videos",
    addv: "Video hinzufügen",
    addnv: "Neues Video hinzufügen",
    vidtitlehint: "Wenn der Titel leer ist, wird er automatisch festgelegt",
    vidtitlelabel: "Videotitel",
    viddesc: "Videobeschreibung",
    vidpinlabel: "Dieses Video anheften?",
    vidpinhint: "Das gepinnte Video wird zuerst angezeigt",
    link: "Link",
    vidlinklabel: "Video-Link",
    file: "Datei",
    novideos: "Keine Videos gefunden",
    delv: "Video löschen",
    editv: "Video bearbeiten",
    original_title: "Original Titel",
    author: "Autor",
    uploaded_by: "Hochgeladen von",
    posts: "Neueste Beiträge",
    addp: "Beitrag hinzufügen",
    addnp: "Neuen Beitrag erstellen",
    posttitle: "Titel des Posts*",
    postbody: "Inhalt des Posts*",
    postpin: "Diesen Post anheften?",
    bannerlabel: "Lade das Bannerbild hoch?",
    bannerfile: "Bannerbild",
    sendEmail: "Post zusätzlich als Email versenden?",
    includeInvited:
      "Email an Benutzer, die Ihre Einladung noch nicht angenommen haben?",
    files: "Andere Dateien",
    noposts: "Keine Einträge gefunden",
    delpost: "Beitrag löschen",
    editpost: "Beitrag bearbeiten",
    users: "Gruppenmitglieder",
    addu: "Mitglied hinzufügen",
    addnu: "Neues Mitglied zur Gruppe hinzufügen",
    addexcel: "Neue Mitglieder aus Excelliste hinzufügen",
    upload: "Hochladen",
    missingexcel: "Kein Excel zum Hochladen",
    uemail: "Benutzer Email",
    makeman: "Zum Manager machen",
    remman: "Als Manager entfernen",
    nousers: "Keine Benutzer Emails, die {term} enthalten",
    inv: {
      title: "Deine Einladungen",
      message: "{inviter} hat Dich zur Gruppe {group} eingeladen",
      accept: "Akzeptieren",
      decline: "Ablehnen",
      accepted: "Du wurdest zur Gruppe hinzugefügt: {group}",
      declined: "Die Einladung von {inviter} wurde abgelehnt",
      sent: "Gesendete Einladungen",
      nonesent: "Keine gesendeten Einladungen",
    },
  },
  inv: {
    title: "Deine Einladungen",
    message: "{inviter} hat Dich zur Gruppe {group} eingeladen",
    accept: "Akzeptieren",
    decline: "Ablehnen",
    accepted: "Du wurdest zur Gruppe hinzugefügt: {group}",
    declined: "Die Einladung von {inviter} wurde abgelehnt",
    sent: "Gesendete Einladungen",
    nonesent: "Keine gesendeten Einladungen",
    asmanager: "Eingeladen als Manager",
    asemployee: "Eingeladen als Mitarbeiter",
  },

  videos: {
    videos: "Videos",
    none: "Keine Videos in dieser Gruppe",
    edit: "Video bearbeiten",
    delete: "Video löschen",
    desc: "Videobeschreibung",
    title: "Video Titel",
    ispinned: "Gepinnt?",
  },

  howtos: {
    title: "Tutorials",
    loading: "Tutorials werden geladen",
    none: "Keine Tutorials vorhanden",
  },

  goals: {
    goals: "Deine Ziele",
    none: "Du hast keine Ziele",

    assigned: "Zugewiesene Ziele",
    tassigned: "Alle Ziele, die dir zugewiesen wurden",
    noassigned: "Keine zugewiesene Ziele",
    assigner: "Von",

    created: "Erstellte Ziele",
    tcreated: "Alle Ziele, die du erstellt hast",
    nocreated: "Keine erstellten Ziele",

    add: "Ziel hinzufügen",
    complete: "Schließe {n} Sessions ab",
    aim: "Erreiche",
    addc: "Bedingung hinzufügen",
    assign: "Benutzer zuweisen",
    assignedu: "Zugewiesene Benutzer",
    assigny: "Selbst zuweisen?",
    submit: "Ziel festlegen",
    remove: "Ziel entfernen",
    select: "Wähle alle",
    deselect: "Wähle keine",
    cond: "Bedingung",
    conds: "Ziel Bedingungen",
    ctype: "Art der Bedingung",

    score: "Ergebnis Ziel",
    due: "Fälligkeitsdatum",
    duration: "Aufnahmedauer",
    sentiment: "Stimmung Ziel",
    pitch: "Stimmton Ziel",
    volume: "Lautstärke Ziel",
    pauses: "Pausen Ziel",
    wpm: "Sprechgeschwindigkeit Ziel",
    overdue: "Überfällig",
    filler: "Ziel für Füllwörter",
    clarity: "Ziel für Sprechdeutlichkeit",
    repetition: "Ziel für Wiederholungen",
    overall: "Ziel für Gesamtergebnis",

    condtype: {
      date: "Enddatum",
      duration: "Dauer (In Sekunden)",
      sentiment: "Stimmung",
      pitch: "Durchschnittliche Stimmhöhe",
      vol: "Durchschnittliche Lautstärke",
      ppm: "Pausen pro Minute",
      wpm: "Sprechgeschwindigkeit",
      filler: "Füllwörter",
      clarity: "Sprechdeutlichkeit",
      repetition: "Wiederholungen",
      overall: "Gesamtergebnis",
    },

    condmsg: {
      overdue: "Überfällig",
      duein: "Fällig",
      duration:
        "Zeichne eine Session mit einer Länge zwischen {n1} und {n2} Sekudnen auf",
      session_score: "Nur Sessions mit einem Ergebnis größer als {n} zählen",
      sentiment: "Strebe nach einem Stimmungswert zwischen {n1}% und {n2}%",
      pitch:
        "Strebe nach einer durchschnittliche Tonhöhe zwischen {n1}Hz und {n2}Hz",
      volume:
        "Strebe nach einem durchschnittliches Volumen zwischen {n1}% und {n2}%",
      pauses: "Versuche zwischen {n1} und {n2} Pausen pro Minute zu machen",
      speech_speed: "Strebe nach {n1} bis {n2} Wörtern pro Minute",
      filler_words:
        "Strebe nach einee Punktzahl von {n} oder höher in der Kategorie 'Füllwörter'.",
      speech_clarity:
        "Strebe nach einer Punktzahl von {n} oder höher in der Kategorie 'Sprechdeutlichkeit'.",
      overall_score:
        "Strebe nach einer Punktzahl von {n} oder höher in der Kategorie 'Gesamt'.",
      repetition:
        "Strebe nach einer Punktzahl von {n} oder höher in der Kategorie 'Wiederholungen'.",
    },

    confirm: "Bist du sicher?",
    confirmmsg:
      "Möchtest du dieses Ziel wirklich löschen? Die Aktion ist irreversibel.",
  },

  news: {
    news: "News",
    read: "Artikel lesen",
    all: "Alle lesen",
    posted: "Gepostet am",
  },

  meet: {
    created: "Erstellt von:",
    expired: "Dieses Meeting ist abgelaufen",
    add: "Meeting hinzufügen",
  },

  pres: {
    pres: "Präsentationen",
    none: "Du hast keine Präsentationen",
    edit: "Präsentationen bearbeiten",
    dl: "Herunterladen",
    ed: "Bearbeiten",
    rm: "Entfernen",
    no_slides:
      "Wenn hier keine Bilder zu sehen sind, dann konnten wir die Folien nicht konvertieren",
    tip:
      "Lade deine Präsentationen (.pdf) hoch, um sie in deinen VR-Headset zu benutzen",
  },

  rec: {
    tiptitle: "Tipps zum PREZP Recorder",
    tiptext:
      "Du kannst dein Mikrofon testen, um zu überprüfen, ob dein Signal gut ankommt.",
    hide: "Diesen Tipp nicht mehr zeigen",
    voll: "Zu leise",
    volgood: "Gut",
    volh: "Zu laut",
    latest: "Neuste Aufnahmen",
    save: "Aufnahme speichern unter",
    sess: "Neue Session",
    base: "Zur Basissession hinzufügen",
    succ: "Session erfolgreich erstellt",
    fromVideo: "Session aus Video wurde erstellt",
    fail: "Sitzung konnte nicht erstellt werden",
    remove: "Aufnahme entfernen",
    removetext:
      "Bist du sicher, dass du die folgende Aufnahme entfernen möchtest?",
    mic: "Mikrofonlautstärke einstellen",
    mute: "Mikrofon ein-/ausschalten",
    devices: "Geräte",
    microphones: "Mikrofon",
    cameras: "Kamera",
    prefix: "Aufgenommen am",
  },

  meta: {
    dev: "Abweichung",
    earlier: "Durchschnitt der früheren Sessions",
    recent: "Durchschnitt der letzten Sessions",
    names: {
      pitch: "Stimmton",
      volume: "Lautstärke",
      pauses: "Pausen",
      sentiment: "Stimmung",
      session_score: "Sessionergebnis",
      speech_speed: "Sprechgeschwindigkeit",
    },
    units: {
      pitch: "Hz",
      volume: "%",
      pauses: "Pausen/Minute",
    },
  },

  slides: {
    slides: "Folien",
    tip: "Präsentationsfolien",
    pitch: "Durchschnittliche Stimmhöhe",
    volume: "Durchschnittliche Lautstärke",
    wpm: "Wörter/Minute",
    start: "Startzeit",
    end: "Endzeit",
    duration: "Länge",

    notfound: "Keine Folie für diese Zeit",
    none: "Es gibt keine Folien für diese Session",
  },

  lookup: {
    title: "Wörter nachschlagen",
    enter: "Gib den zu suchenden Text ein",
    exact: "Genaue Übereinstimmung",
    show: "Alle anzeigen",
    hide: "Weniger anzeigen",
    start: "Mit dem Tippen beginnen...",
    filter: "Filtrieren mit",
    missing: "Nicht genügend Daten um Wörter anzuzeigen",
  },

  cards: {
    avg: "Durchschnitt",
    pvolume: "Grundlautstärke",
    ppitch: "Grundton",
    wpm: "Wörter/Minute",
    keywords: "Schlüsselwörter (KI-basiert)",
  },

  bench: {
    choose: "Benchmark wählen",
    change: "Benchmark ändern",
    title: "Benchmark Session",
    text:
      "Wähle ein Benchmark Session, die an die Graphen angezeigt wird. Damit kannst du dich mit anderen Sessions vegleichen.",
  },

  sentences: {
    filter: "Sätze filtrieren",
    none: "Keine Sätze gefunden",
    pos: "Positiv",
    neu: "Neutral",
    neg: "Negativ",
    nochart: "Keine Dateien für die Stimmung gefunden",
    filters: {
      none: "Kein",
      vpos: "Sehr positiv",
      vneg: "Sehr negativ",
    },
  },

  subscription: {
    // Ok to use formal salutations for DE Stripe conventions
    title: "Abonnements",
    menutitle: "Deine Abonnements",
    activesub: "Aktives Abonnement",
    noactivesub: "Kein Abonnement",
    plans: "Plan auswählen",
    priceInterval: "Preis / Intervall",
    nextPayment: "Nächste Zahlung fällig am",
    subscriptionEnds: "Abonnement endet am",
    lastPayment: "Letzte Zahlung",
    paymentMethod: "Zahlungsmethode",
    subscriberSince: "Abonnent seit",
    canceledOn: "Gekündigt zum",

    latestInvoice: "Letzte Rechnung",
    viewAll: "Alle ansehen",
    invoiceNr: "Rechnung#",
    status: "Status",
    downloadInvoice: "Rechnung Herunterladen",

    active: "Aktiv",
    paid: "Bezahlt",

    monthly: "monatlich",
    yearly: "jährlich",
    monthlyPayment: "Monatliche Zahlung",
    yearlyPayment: "Jährliche Zahlung",
    month: "Monat",
    year: "Jahr",
    perMonth: "pro Monat",
    perYear: "pro Jahr",
    desctitle: "Beschreibung",
    payment: "Bezahlung",

    paymentTo: "Zahlung an",
    selectedPlan: "Gewählter Plan",
    subFor: "Abonnement für",
    billing: "Rechnungsdaten",
    checkout: "Checkout",

    buyAsCompany: "Als Firma einkaufen",
    vatData: "USt.-Daten",
    enterCompanyName: "Firmenname",
    companyNameRequired: "Ihr Firmenname wird benötigt.",
    companyNameMin: "Ihr Firmenname ist zu kurz.",
    enterVatId: "USt.-ID",
    vatIdRequired: "Ihre USt.-ID wird benötigt.",
    vatIdInvalid: "Ihre USt.-ID ist ungültig.",
    confirmVatId: "USt.-ID Bestätigen",
    couldNotValidate: "USt.-ID konnte nicht validiert werden.",
    vatIdConfirmed: "USt.-ID erfolgreich bestätigt.",
    vatIdValidated: "Ihre USt.-ID wurde validiert.",
    vatServiceDown:
      "Der Service zur Validierung der USt.-ID ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal.",
    vatServiceOverload:
      "Der Service zur Validierung der USt.-ID ist derzeit überlastet. Bitte versuchen Sie es später noch einmal.",
    companyIncomplete: "Die Firmenangaben sind unvollständig.",
    reverseCharge:
      "Reverse Charge: Steuerschuld liegt beim Leistungsempfänger.",

    product: "Produkt",
    cycle: "Zyklus",
    duration: "Dauer",
    price: "Preis",
    netTotal: "Nettosumme",
    vat: "USt",
    total: "Gesamtsumme",
    coupon: "Coupon",
    redeem: "Einlösen",
    enterCode: "Coupon-Code",
    redeemed: "Eingelöst",
    remove: "Entfernen",
    discountMonths:
      "* Gutschein gilt für die ersten {d} Monate, anschließend gilt der Standardpreis des gewählten Plans.",
    discountForever: "* Gutschein gilt bis das Abonnement beendet wird.",
    forever: "für immer",
    legalNotice:
      "Indem Sie Ihr Abonnement bestätigen, gestatten Sie 111 Medien Service GmbH, zukünftige Zahlungen Ihrem Zahlungsinstrument gemäß den allgemeinen Geschäftsbedingungen zu belasten. Sie können Ihr Abonnement jederzeit kündigen.",

    cancel: "Abbrechen",
    back: "Zurück",
    next: "Fortfahren",
    purchaseSubscription: "Zahlungspflichtig Abonnieren",

    pricing: {
      title: "Wähle Deinen PREZP-Plan",
      description:
        "PREZP bietet eine breite Auswahl an verschiedenen Preisplänen, um Deinen Bedürfnissen perfekt gerecht zu werden. Für Einzelpersonen und auf Unternehmensebene.",
      fetching: "Preise abrufen...",
      orContinueFree:
        "Oder fahre mit der kostenlosen Version fort, um PREZP mit Einschränkungen zu erkunden.",
      continueFree: "Weiter mit PREZP Free",
      compare: "Preispläne vergleichen",
    },

    cancelSubscription: "Abonnement kündigen",
    deleteSubscription: "Abonnement löschen",
    areYouSure: "Bist Du sicher, dass Du Dein Abonnement beenden möchtest?",
    canceled: "Abonnement Gekündigt",
    youCanceled:
      "Du hast dein Abo gekündigt. Dir stehen weiterhin alle Vorteile deines übrigen Kontigents zur Verfügung, bis dein Abonemment endgültig gelöscht wird.",
    toSubscribeAgain:
      "Um PREZP erneut zu abonnieren musst Du bis zum Ende des aktuellen Zahlungszyklus warten.",
  },

  checkout: {
    success: {
      statement: "Danke",
      message: "Deine PREZP-Plan-Bestellung wurde erfolgreich abgeschlossen",
      contact:
        "Eine E-Mail mit den Details zu Deiner Bestellung \n" +
        "wurde an die angegebene E-Mail-Adresse gesendet.",
      action: "Starten",
      updating: "Benutzerdaten werden aktualisiert...",
    },
    error: {
      statement: "Zahlungsfehler",
      message:
        "Es tut uns leid. Deine Zahlung konnte nicht verarbeitet werden. \n" +
        "Bitte versuche es erneut.",
      contact:
        "Wenn das Problem weiterhin besteht, wende dich bitte an unser Support-Team.",
      action: "Zurück",
    },
    pending: {
      statement: "Zahlung ausstehend",
      message:
        "Deine Zahlung wird noch bearbeitet oder erfordert eine zusätzliche Bestätigung. \n" +
        "Bitte überprüfe Dein E-Mail-Postfach für weitere Anweisungen.",
      contact:
        "Wenn die Zahlung nicht abgeschlossen werden kann, wende dich bitte an unser Support-Team.",
      action: "Zurück",
    },
  },

  tier: {
    billedMonthly: "Monatlich abgerechnet",
    billedYearly: "Jährlich abgerechnet",
    billedCustom: "Je nach Bedarf",
    individual: "Individuell",
    savePercentYearly: "Spare {percent}%  mit jährlicher Abrechnung",
    saveAmountYearly: "Spare {currency} {amount} mit jährlicher Abrechnung",
    subscribe: "Abonnieren",
    contactSales: "Vertrieb kontaktieren",

    badge: {
      explore: "Um PREZP zu erkunden", // free
      casual: "Für lockeres Training", // basic
      recommended: "Empfohlen für die meisten Benutzer", // standard / basic(mvp)
      business: "Für Business-Training", // premium
    },
  },

  features: {
    // single features - keep in sync with permission scope naming
    relaxed_live_transcription:
      "Entsp. Live-Transkriptionen ({hours}h / Monat)",
    fast_live_transcription: "Schnelle Live-Transkriptionen ({hours}h / Monat)",

    relaxed_analysis: "{count} Entspannte, bewertete Analysen",
    fast_analysis: "{count} Schnelle, bewertete Analysen",

    relaxed_ai_feedback: "{count} Entspannte KI-Feedbacks",
    fast_ai_feedback: "{count} Schnelle KI-Feedbacks",

    limited_public_group: "Begrenzter Zugriff auf öffentliche Gruppen",
    full_public_group: "Zugriff auf öffentliche Gruppen",
    b2b_group: "Zugang zu B2B-Gruppen",

    basic_tutorial: "Grundlegende Tutorials",
    advanced_tutorial: "Erweiterte Tutorials",
    expert_tutorial: "Experten-Tutorials",

    coach_review_booking: "Human-Coach™-Review-Buchungen",
    coach_1on1_booking: "Human-Coach™-Einzelbuchungen",

    seminar: "Zugang zu Seminaren",
    challenge: "Zugang zu Challenges",
    course: "Zugang zu Kursen",
    vr_mode: "PREZP VR Zugang",
    sso: "Single Sign On",
    custom_idp: "Benutzerdefinierte IDP-Integration",
    external_api: "Externe API-Integration",
    onboarding: "Onboarding & Schulung",
    tech_support: "Dedizierter technischer Support",
    dev_support: "Dedizierter Entwicklersupport",
    sla: "SLA + mit 99 % Verfügbarkeit",
    redundant_service: "Redundante Dienste",

    // feature combos - just used for compact lists
    everything_basic: "Beinhaltet alles vom Basic Plan",
    everything_premium: "Beinhaltet alles vom Premium Plan",

    unlimited_relaxed_hours_and_workers:
      "Unbegrenzt entsp. Stunden & KI-Arbeiter",
    unlimited_fast_hours_and_workers:
      "Unbegrenzt schnelle Stunden & KI-Arbeiter",

    basic_tutorial_and_challenge: "Grundlegende Tutorials & Challenges",
    advanced_tutorial_and_challenge: "Erweiterte Tutorials & Challenges",
    expert_tutorial_challenge_and_course:
      "Experten-Tutorials, Challenges und Kurse",

    seminar_and_public_group: "Seminare & öffentliche Gruppen",
    seminar_public_and_b2b_group: "Seminare, öffentliche- & B2B-Gruppen",

    sso_and_custom_idp: "SSO & Eigene IDP-Integration",
    dev_tech_support: "Dedizierter Technischer Support",

    sla_and_redundant_service: "SLA + Redundante Dienste",
  },

  company: {
    title: "Unternehmen",
    partof: "Du bist schon einem Unternehmen beigetreten",
    notpartof: "Du bist keinem Unternehmen beigetreten",
    create: "Erstelle ein Unternehmen",
    createbtn: "Unternehmen erstellen",
    invitations:
      "Oder akzeptiere eine Einladung um einem bestehenden Unternehmen beizutreten",
    noinv: "Du hast keine Einladungen",

    companies: "Unternehmen",
    getting: "Unternehmen abrufen...",
    notfound: "Keine Unternehmen gefunden",

    none: "Du hast kein Unternehmen",
    inactive: "Dieses Unternehmen ist nicht aktiviert",
    pdesc: "Wähle einen Plan und kaufe ein Abonnement",

    plans: "Pläne",
    changep: "Plan ändern",
    selectp: "Neuen Plan auswählen",
    same: "Wähle einen anderen Plan",

    emp: "Mitarbeiter",
    esingle: "Mitarbeiter",
    invite: "Benutzer einladen",
    adde: "Mitarbeiter hinzufügen",
    eemail: "Benutzer Email",
    makeman: "Zum Manager machen?",
    rem: "Entfernen",
    sure: "Möchtest du diesen Mitarbeiter wirklich entfernen?",
    crole: "Rolle wechseln",
    crolefor: "Rolle wechseln für",
    noemp: "Noch keine Mitarbeiter im Unternehmens",

    edit: "Name des Unternehmens bearbeiten",
    name: "Name des Unternehmens",
  },

  charts: {
    avghand: "Durchschnittliche Handbewegung",
    avghead: "Durchschnittliche Kopfbewegung",
    ppm: "Pausen/Minute",
    sent: "Gefühl",
    pitchot: "Stimmton im Laufe der Zeit",
    scoreot: "Sessionergebnis im Laufe der Zeit",
    avgvp: "Durchschnittliche Stimmhöhe",
    avgvol: "Durchschnittliche Lautstärke",
    avgwpm: "Durchschnittliche Wörter/Minute",
    sscore: "Sessionergebnis",
    points: "{n} Punkte zeigen",
    all: "alle",
  },

  sess: {
    noneselected: "Keine Session gewählt",
    shared: "Mit {n} Personen geteilt",
    sharedWithOne: "Mit {n} Person geteilt",
    sharedWith: "Geteilt mit ",
    sharedWithNone: "Mit niemandem geteilt",
    noOneToShareWith:
      "Du kannst Sessions nur mit Usern aus einer deiner Gruppen teilen.",
    private: "Privat",
    duration: "Dauer",
    none: "Keine Sessions gefunden",
    noneseelcted: "Keine Session ausgewählt",
    edit: "Session bearbeiten",
    name: "Name der Sessions",
    vis: "Sichtbarkeit",
    updated: "Session aktualisiert",
    filter: "Filtern von Sitzungen",
    keywords: "Schlüsselwörter",

    sharing: "Siehe geteilte Sessions an",
    usersSharing: "Benutzer, die Sessions mit dir teilen",
    usersManaged: "Benutzer, die Session mit dir Sessions teilen",
    otherUsers: "Andere Benutzer aus Gruppen",
    changeuser: "Benutzer wechseln",
    nouser: "Kein Benutzer ausgewählt",
    sharedback: "Zurück zu geteilten Sessions",

    list: "Liste",
    progress: "Fortschritte",
    progresslimit: "Wähle die Anzahl der anzuzeigenden Sessions",

    filters: {
      date: {
        before: "Vor dem Datum",
        after: "Nach dem Datum",
        between: "Zwischen zwei Daten",
      },
      duration: {
        longer: "Länger als (Minuten)",
        shorter: "Kürzer als (Minuten)",
        between: "Zeitspanne (Minuten)",
      },
      name: {
        startsWith: "Beginnt mit",
        endsWith: "Endet mit",
        includes: "Enthält",
        exact: "Genaue Übereinstimmung",
      },
      score: {
        greater: "Größer als",
        less: "Weniger als",
        range: "Punktebereich",
      },
      pres: {
        title: "Nach Präsentation filtern",
        label: "Präsentation auswählen",
      },
    },

    hanging: {
      title: "Andere Benutzer",
      text:
        "Du teilst diese Session immer noch mit einigen Benutzern, mit denen du keiner Gruppe mehr angehörst.",
      removeOneDialogTitle:
        "Bist du sicher, dass du diese Session nicht mehr mit {u} teilen möchtest?",
      removeOneDialogText:
        "Diese Aktion ist irreversibel. Du musst mit {u} in mindestens einer Gruppe sein, um später wieder ein Session mit ihnen teilen zu können.",
      removeAllButton: "Entferne alle diese Benutzer",
      removeAllDialogTitle:
        "Bist du sicher, dass du alle diese Benutzer entfernen möchtest?",
      removeAllDialogText:
        "Diese Aktion ist irreversibel. Du musst mit jedem dieser Benutzer in mindestens einer Gruppe sein, um später wieder eine Sitzung mit ihnen teilen zu können.",
      snack: {
        removeAll: "Erfolg. Benutzer entfernt",
        removeSome:
          "Nicht alle Benutzer wurden entfernt. Benutzer {emails} konnten nicht entfernt werden",
        removeFail:
          "Die Sitzung konnte nicht für einen oder mehrere Benutzer freigegeben werden",
      },
    },

    clear: {
      title: "Alle Benutzer entfernen",
      text: `Du musst deine Aktion noch bestätigen, indem du auf "Bestätigen" klickst`,
    },
  },

  words: {
    word: "Wort",
    filler: "Füllwort",
    stop: "Stoppwort",
    normal: "Standardwort",
  },

  admin: {
    activeProduct: "Aktives Produkt",
    companies: "Unternehmen",
    coupons: "Stripe Gutscheine",
    createNewProduct: "Neues Produkt anlegen",
    createProduct: "Produkt anlegen",
    maximumAllowedEmployees: "Maximale Mitarbeiteranzahl",
    page: "Admin Bereich",
    productDescription: "Produktbeschreibung",
    productName: "Produktname",
    productPrice: "Produktpreis (in Euro)",
    products: "Stripe Produkte",
    recorder: "Dev-Rekorder",
    slides: "Folien",
    tuts: "Tutorials",
    transcription: "Transkription",
    uploadTooltip: "Excelliste mit Benutzern hochladen",
    users: "Benutzer",
    usersPageHeader: "Benutzer",
    verifyEmail: "Verifiziere die E-Mail Adresse dieses Benutzers.",
  },

  permissions: {
    editTitle: "Berechtigungen verwalten",
    editText: "Hinzufügen, Entfernen oder Erweitern von Berechtigungen für",
    removeTitle: "Bist du sicher?",
    removeText:
      "Du kannst den gleichen Berechtigungstyp zu einem späteren Zeitpunkt hinzufügen.",
    modifyTitle: "Zähler aktualisieren",
    add: "Hinzufügen",
    confirm: "Bestätigen",
    inherit: "Geerbt von",
    counter: "Verbleibende Anzahl",
    seconds: "Verbleibende Sekunden",

    seminar: "Seminare",
    upload_audio: "Audio hochladen",
    upload_video: "Video hochladen",
    session_training: "Session Training",
    can_share_with_all: "Sessions mit allen Gruppenmitgliedern teilen",
    teacher: "Dozentenzugang",
    student: "Studentenzugang",
    live_transcription_relaxed: "Entspannte Live-Transkription",
    live_transcription_fast: "Schnelle Live-Transkription",
    analysis_relaxed: "Entspannte Analyse",
    analysis_fast: "Schnelle Analyse",
    ai_feedback_relaxed: "Entspanntes KI-Feedback",
    ai_feedback_fast: "Schnelles KI-Feedback",
    public_group_limited: "Eingeschränkte öffentliche Gruppe",
    public_group_full: "Vollständige öffentliche Gruppe",
    b2b_group: "B2B-Gruppe",
    tutorial_basic: "Grundlagen-Tutorial",
    tutorial_advanced: "Fortgeschrittenen-Tutorial",
    tutorial_expert: "Experten-Tutorial",
    coach_booking_review: "Coach Review Buchung",
    coach_booking_1on1: "Coach 1:1 Buchung",
    challenge: "Herausforderungen",
    course: "Kurse",
    vr_mode: "VR-Modus",
    sso: "Single Sign-On (SSO)",
    custom_idp: "Benutzerdefinierter Identitätsanbieter (IDP)",
    external_api: "Externe API",
    onboarding: "Onboarding",
    support_tech: "Technischer Support",
    support_dev: "Entwickler-Support",
    sla: "Service Level Agreement (SLA)",
    redundant_service: "Redundanter Service",
  },

  roles: {
    editTitle: "Rollen verwalten",
    editText: "Hinzufügen und Entfernen von Rollen für",
    removeTitle: "Bist du sicher?",
    removeText:
      "Du kannst die gleiche Rolle zu einem späteren Zeitpunkt hinzufügen.",
    modifyTitle: "Datum ändern",
    add: "Hinzufügen",
    confirm: "Bestätigen",

    subscriberFree: "Subscriber Free",
    subscriberBasic: "Subscriber Basic",
    subscriberStandard: "Subscriber Standard",
    subscriberPremium: "Subscriber Premium",
    subscriberEnterprise: "Subscriber Enterprise",
    employee: "Mitarbeiter",
    manager: "Manager",
    owner: "Besitzer",
    admin: "Admin",
    user: "Benutzer",
  },

  feature_perms: {
    editTitle: "Berechtigungen verwalten",
    editText: "Hinzufügen, Entfernen oder Erweitern von Berechtigungen für",
    removeTitle: "Bist du sicher?",
    removeText:
      "Du kannst den gleichen Berechtigungstyp zu einem späteren Zeitpunkt hinzufügen.",
    modifyTitle: "Datum ändern",
    add: "Hinzufügen",
    confirm: "Bestätigen",
    analysis_relaxed_count: "Entspannte Analyse Zähler",
    analysis_fast_count: "Schnelle Analyse Zähler",
    ai_feedback_relaxed_count: "Entspannte KI-Feedback Zähler",
    ai_feedback_fast_count: "Schnelle KI-Feedback Zähler",
    live_transcription_relaxed_seconds:
      "Entspannte Live-Transkription Sekunden",
    live_transcription_fast_seconds: "Schnelle Live-Transkription Sekunden",
  },

  activeUser: {
    search: "Benutzer suchen",
    none: "Keine Benutzer gefunden",
    indef: "Unbestimmt",
    mins: " Minuten",
    hours: " Stunde(n)",
    days: " Tag(e)",
    months: " Monat(e)",
    both: "Aktiv/Deaktiviert",
    active: "Aktiv",
    inactive: "Deaktiviert",
    activate: "Aktivieren",
    deactivate: "Deaktivieren",
    change: "Aktivstatus für folgenen Benutzer wechseln",
    until: "bis",
    confirm: "Bist du sicher, dass du diesen Benutzer deaktivieren möchtest?",
    activateMsg: "Bitte wähle aus, wie lange der Benutzer aktiv bleiben soll.",
  },

  def_pres: {
    title: "Standardfolien",
    current: "Derzeit hochgeladene Folien",
    currentmsg:
      "Dies sind die Folien, die jeder neue Benutzer erhält, wenn er sich für PREZP registriert",
    none: "Es sind keine Standardfolien hochgeladen",
    upload: "Neue Folien hochladen",
    uploadBtn: "Hochladen",
    remove: "Standardfolien entfernen",
    removeBtn: "Entfernen",
    confirm: "Bist du sicher, dass du diese Folien entfernen willst?",
    no_slides: "Diese Folien können nicht zu Bilder gewandelt werden",
  },

  snack: {
    users: {
      rupdated: "Benutzerrolle aktualisiert",
      rem: "Benutzer entfernt",
      emails: "Ein oder mehrere Emails sind ungültig",
      alreadyin: "Ein oder mehrere Benutzer sind schon in der Gruppe",
    },
    comp: {
      created: "Unternehmen erfolgreich erstellt",
      invited: "Benutzer erfolgreich eingeladen",
      rupdated: "Benutzerrolle aktualisiert",
      upsuccess: "Upgrade erfolgreich",
      upfail: "Das Upgrade war nicht erfolgreich",
      rejected: "Folgende Benutzer wurden nicht eingeladen: ",
      success: "Die Einladung war erfolgreich",
      createfail: "Unternehmen konnte nicht erstellt werden",
      empmax: "Zahl der Mitarbeiter mehr als max. erlaubte",
      conflict: "Ein oder mehrere Nutzer sind bereits in einem Unternehmen",
    },
    groups: {
      unadded: "Diese Benutzer konnten nicht hinzugefügt werden: ",
      del: "Gruppe erfolgreich gelöscht",
      added: "Gruppe erfolgreich hinzugefügt",

      ingroup: "Benutzer bereits in Gruppe",
      allin: "Alle Benutzer bereits in der Gruppe",
      somein: "Einige Benutzer gehören bereits zu dieser Gruppe:",
    },
    news: {
      added: "Artikel erfolgreich gelöscht",
    },
    pres: {
      del: "Präsentation erfolgreich gelöscht",
      cannotDelete:
        "Du kannst diese Präsentation nicht löschen, Du hast bereits eine Session zu Ihr aufgenommen.",
    },
    sess: {
      delsuccess: "Session erfolgreich gelöscht",
      delfail: "Session konnte nicht gelöscht werden",
      metafail:
        "Die Metaanalyse-Einstellungen konnten nicht aktualisiert werden",
      benchfail: "Benchmark-Session konnten nicht abgerufen werden",
      basefail: "Sessions konnten nicht hochgeladen werden",
      fetchfail: "Sessions für {e} konnten nicht abgerufen werden",
    },
    vid: {
      editsuccess: "Video erfolgreich bearbeitet",
      editfail: "Fehler beim Bearbeiten des Videos",
      delsuccess: "Video erfolgreich gelöscht",
      delfail: "Fehler beim Löschen des Videos",
    },
    auth: {
      fail: "Es ist ein Fehler aufgetreten, zurück zum Login",
      confirmfail: "Diese Email konnte nicht überprüft werden",
      sent: "Email gesendet",
      sendfail: "Email konnte nicht gesendet werden",
      reset: "Passwort erfolgreich zurückgesetzt",
      resetfail: "Passwort konnte nicht zurückgesetzt werden",
      deviceConfirmFail: "Das Gerät konnte nicht überprüft werden",
    },
    stripe: {
      // Ok to use formal salutations for DE Stripe conventions
      elempty: "Element kann nicht leer sein",
      elinvalid: "Ungültige Zahlungsinformationen",
      elincomplete: "Unvollständige Daten",
      purchase: "Erfolg",
      pfail: "Abonnement konnte nicht gekauft werden",
      entercode: "Bitte geben Sie einen Gutscheincode ein",
      codeinvalid: "Ungültiger Gutscheincode",
      subscribing: "Dein Abonnement wird eingerichtet...",
      subGetError: "Fehler beim Abrufen des Abonnements",
      subCancelError: "Fehler bei der Kündigung des Abonnements",
    },
    meta: {
      attrs: "Bitte wähle mindestens ein Attribut aus",
    },
  },
  settings: {
    title: "Benutzereinstellungen",
    pfp: "Profilbild",
    dark: "Dunklen Modus aktivieren",
    alreadyup: "Du hast bereits eine oder mehrere Basis Sessions hochgeladen.",
    analyzed: "Sessions wurden analysiert.",
    notanalyzed: "Sessions wurden noch nicht analysiert.",
    upAt: "Hochgeladen am",
    avgVol: "Durchschnittliches Volumen",
    avgPitch: "Durchschnittliche Tonhöhe",
    actions: "Aktionen",
    items_per_page_text: "Einträge pro Seite",
  },
  baseline: {
    up: "Basis Session hochladen",
    base: "Basissessions",
    base1: "Basissession (1)",
    base2: "Basissession (2)",
    base3: "Basissession (3)",
    text:
      "Die Basis Sessions werden genutzt um sie mit künftigen Aufnahmen zu vergleichen. Sie sollen dir eine Orientierung geben wie sich deine Stimme unter normalen Bedingungen verhält.",
    lang: "Sprache wählen",
  },

  move: {
    title: "Bewegungsanalyse",
    none:
      "Du hast kein VR-Training absolviert, deshalb wurden von PREZP keine Bewegungsdaten von dir analysiert",
    head: {
      title: "Kopfbewegung",
      avg: "Durchschnittliche Kopfbewegung",
      tip: "Zeigt die Kopfbewegung in der VR während der Präsentation an",
    },
    hand: {
      title: "Handbewegung",
      avg: "Durchschnittliche Handbewegung",
      tip: "Zeigt die Kopfbewegung in der VR während der Präsentation an",
    },
    ratio: {
      title: "Bewegungsverhältnis",
      tip: "Dies zeigt das Verhältnis zwischen Kopf- und Handbewegung",
    },
  },

  number: {
    toosmall: "Die Zahl ist zu klein (min.: {n})",
    toobig: "Die Zahl ist zu groß (max.: {n})",
  },

  seminars: {
    dash: {
      title: "Seminar Dashboard",
      noSeminars: "Du hast noch kein Seminar",
      adminLink: "Verwaltung",
      progressLink: "Fortschritt",
      yourSubmissions: "Deine Aufzeichnungen",
      feedback: "Feedback für mich",
      submissions: "Aufzeichnungen",
      noSubmissions: "Bisher gibt es keine Aufzeichnungen",
      seeAllButton: "Alle anzeigen",
      participants: "Teilnehmer",

      edit: "Bearbeiten",
      view: "Ansehen",
      publish: "Veröffentlichen",
      form: {
        publish: "Dieses Seminar veröffentlichen",
        title: "Titel",
        description: "Beschreibung",
        lecturer: "Dozent(-in)", // dunno how to translate
        price: "Preis",
        success: "Erfolgreich veröffentlichtes Seminar",
        fail: "Beim Veröffentlichen dieses Seminars ist ein Fehler aufgetreten",
      },
    },
    admin: {
      title: "Seminar Verwaltung",
      addBtn: "Seminar hinzufügen",
      addTitle: "Neues Seminar hinzufügen",
      addText:
        "Hiermit kann ein Seminar erstellt werden. Kurs-Elemente wie z.B. ein Lückentext, Video-Feedback-Übungen oder andere Kurs-Bestandteile können einfach und flexibel erstellt und hinzufügt werden. Veröffentliche danach das Seminar und lade deine Teilnehmer ganz bequem dazu ein.",

      feedback: {
        title: "Gib dem Benutzer Feedback",
        requested: "Angefordertes Feedback",
        desc:
          "Die folgenden Videoeinsendungen haben Feedback vom Seminarinhaber angefordert",
        yourFeedback: "Dein Feedback",
        alreadyGiven: "Du hast bereits Feedback zu diesem Video gegeben.",
      },
      participants: {
        title: "Seminar Teilnehmer",
        removeAllUser: "Alle Benutzer und Aufzeichnungen entfernen",
        removeAll: "Alle Aufzeichnungen entfernen",
        filterUsers: "Benutzer filtern",
        refreshInv: "Einladungen und Benutzer aktualisieren",
        search: "Suche",
        areYouSureTitle: "Bist du dir sicher",
        areYouSureAllUserText:
          "Bist du sicher, dass du alle Aufzeichnungen aller Benutzer löschen möchtest? Du musst das gesamte Seminar neu starten.",
        areYouSureAllSubs:
          "Möchtest du wirklich alle Aufzeichnungen dieses Benutzers entfernen",
        forThisSeminar: "für dieses Seminar?",
        removeThisUser: "Diesen Benutzer entfernen",
        clearUserSubs: "Eingaben dieses Benutzers löschen",
        removeAllUsers: "Alle Benutzer entfernen",
        areYouSure: "Bist du sicher?",
        removeUsersAndSubs:
          "Möchtest du wirklich alle Benutzer und ihre Aufzeichnungen entfernen? Dadurch wird das Seminar effektiv zurückgesetzt.",
        detail: "Aufzeichnungen von",
        numSubmissions: "Anzahl Aufzeichnungen",
        noParticipants: "Keine Teilnehmer",
        pos: "Position",
        progress: "Fortschritt",
        name: "Name",
        fetchFail: "Es konnten die Benutzer nicht abgerufen werden",
        userNotFound: "Benutzer nicht gefunden",
        emptySub: "Nichts zu sehen. Leere Einreichung.",
        filter: {
          title: "Benutzer in diesem Seminar filtern",
          userCount: "{n} Nutzer insgesamt",
          progressLabel: "Aktueller Fortschritt",
          progressHint:
            "Alle Benutzer, deren Fortschrittsprozentsatz höher oder gleich diesem Wert ist, werden eingeschlossen.",
          blockLabel: "Aktuellen Block",
          blockHint:
            "Alle Benutzer, die diesen Block überschritten haben, werden eingeschlossen",
          elLabel: "Aktuelles Element",
          elHint:
            "Alle Benutzer, die dieses Element überschritten haben, werden eingeschlossen",
        },
      },
      invitations: {
        fetchFail: "Gesendete Einladungen konnten nicht abgerufen werden",
        revokeFail: "Einladung konnte nicht widerrufen werden",
        invalidEmail: "Ungültige E-Mail erkannt",
      },
      seminarList: {
        yourSemis: "Deine Seminare",
        participantOrOwnerOf:
          "Du bist insgesamt Teilnehmer oder Inhaber von {n}",
      },
    },

    edit: {
      actions: "Abgaben",
      invite: "Teilnehmer einladen",
      inviteEmails: "E-Mails, die eingeladen werden",
      editBtn: "Seminar bearbeiten",
      blocks: {
        title: "Seminarblöcke",
        addBtn: "Block hinzufügen",
        editBtn: "Block bearbeiten",
        blockTitle: "Blocktitel",
        blockDesc: "Blockbeschreibung",
      },
      elements: {
        title: "Elemente in diesem Block",
        addBtn: "Element hinzufügen",
        editBtn: "Element bearbeiten",
        elTitle: "Elemententitel",
        elDesc: "Elementenbeschreibung",
        uploadVid: "Neues Video hochladen",
        embedVid: "Video einbetten",
        mkvFilesNo: "Achtung: MKV-Dateien funktionieren NICHT!",
        file: "Videodatei",
        currentVideo: "Aktuell hochgeladenes Video",
        uploadingWillReplace:
          "Das Hochladen eines neuen Videos ersetzt das vorhandene und das vorhandene wird gelöscht",
        link: "Link zum Video",
        desc_text: "Stelle Sachen hier rein...",

        video_presentation:
          "Benutzer nehmen ein Video auf, nichts zu spezifizieren",
        give_feedback:
          "Benutzer geben Feedback zu einem hochgeladenen Video, das hier nicht angegeben werden muss",
        watch_feedback:
          "Benutzer sehen sich ein für dich erstelltes Feedback-Video an, das hier nicht angegeben werden muss",
        gap_text: {
          title: 'Gib Lücken an, indem Du "__" (doppelter Unterstrich) setzts',
          answers: "Lücken füllen (Probenlösung)",
          gapText: "Lückentext",
        },
        addRow: "Zeile hinzufügen",
        addCategory: "Kategorie hinzufügen",
        newCategory: "Neue Kategorie",
        categoryTitle: "Kategorietitel",
        deleteElement: {
          title: "Element löschen",
          detail: "Bist du dir sicher, dass du dieses Element löschen willst?",
        },
        rateable:
          "Die hochgeladenen Videos werden geteilt und können bewertet werden",
        embed_youtube:
          "Wenn du Youtube Videos verlinkst stelle sicher, dass diese integriert werden können!",
        required_numer_of_submissions:
          "Benötigte Anzahl an Abgaben für dieses Element",
        maximum_number_of_submissions:
          "Maximale Anzahl an Abgaben für dieses Element",
        number_range_1_100: "Erlaubte Nummern zwischen 1-100",
        number_range_0_10: "Erlaubte Nummern zwischen 0-10",
        max_condition:
          "Max muss größer oder gleich der benötigten Anzahl an Abgaben sein",
        required_number_of_shared_submissions:
          "Benötigte Anzahl an GETEILTEN Abgaben für dieses Element",
        shared_number_condition:
          "Die Benötigte Anzahl an geteilten Abgaben muss kleiner oder gleich der Anzahl an benötigten Abgaben sein",
        max_number_of_shared_submissions:
          "Maximale Anzahl an GETEILTEN Abgaben für dieses Element",
        timed_video_recording: "Aufnahme mit Zeitbegrenzung",
        timers: "Timer",
        exp_len: "Erwartete Länge der Aufnahme (in Sekunden)",
        max_len: "Maximale Aufzeichnungsdauer (in Sekunden)",
        range100: "Zahlenbereich ist 0-100",
        range10: "Zahlenbereich ist 1-10",
        max_shared_rule_1:
          "Max. Anzahl der gemeinsam genutzten Aufzeichnungen muss größer oder gleich der erforderlichen Anzahl der gemeinsam genutzten Aufzeichnungen sein",
        max_shared_rule_2:
          "Max. Anzahl der gemeinsam genutzten Aufzeichnungen muss weniger als oder gleich der erforderlichen Anzahl der gemeinsam genutzten Aufzeichnungen sein",
        max_shared_rule_3:
          "Max. Num. Von den Aufzeichnungen müssen größer sein als die Anzahl der erforderlichen Anträge und im Bereich von 1-100",
        max_shared_rule_4:
          "Max. Num. Von gemeinsam genutzten Aufzeichnungen muss größer oder gleich einer gewünschten Anzahl der gemeinsam genutzten Aufzeichnungen und im Bereich von 1 bis 10 sein",
        req_subs_rule_1:
          "Die erforderliche Anzahl der Aufzeichnungen muss größer sein als die Anzahl der gemeinsam genutzten Aufzeichnungen und im Bereich 0-10",
        req_shared_rule_1:
          "Erforderliche Anzahl der gemeinsam genutzten Aufzeichnungen muss weniger als oder gleich der Anzahl der erforderlichen Aufzeichnungen und im Bereich 0-10 sein",
        watch_video_rule:
          "Dieser Elementtyp erfordert ein Video. Bitte verlinke ein YouTube-Video oder lade eine Datei hoch.",
      },
    },

    home: {
      startTut: "Tutorial starten",
      loading: "Wird geladen...",
      noSemisFound: "Keine Seminare gefunden",
      yourSemis: "Deine Seminare",
    },

    name: "Seminarname",
    description: "Seminarbeschreibung",
    loading: "Seminar laden...",
    notFound: "Seminar nicht gefunden",
    noBlocks: "Dieses Seminar hat keine Blöcke",
    progress: "Seminarfortschritt",
    tasks: "Aufgaben",
    skipTask: "Aufgabe überspringen",
    prev: "Zurück",
    next: "Weiter",
    percentComplete: "{percent}% abgeschlossen",
    submittedOn: "Eingereicht am",
    yourSubmission: "Deine Einreichung",
    yourAnswer: "Deine Antwort",
    sampleSolution: "Probenlösung",

    submissions: {
      videoRecorder: {
        uploadDialogTitle: "Lade deine Aufnahme hoch",
        uploadDialogText: "Lade deine Videoaufnahme hoch.",
        askForFeedback: "Nach Feedback fragen",
        askForFeedbackInfo:
          "Wenn du nach Feedback fragst kann der Seminarveranstalter dein Video sehen und dir Feedback darauf geben",
        expectedTime: "Erwartete Aufnahmezeit:",
        maxAllowedTime: "Maximale Aufnahmezeit:",
        title: "Videorecorder",
        actions: "Aktionen",
        start: "Aufnahme",
        stop: "Stop",
        pause: "Pause",
        resume: "Fortsetzen",
        uploadFeedback: "Absenden",
        upload: "Analysieren",
        reset: "Verwerfen",
        flipVideo: "Video spiegeln",
        nameYourFile: "Benennst du deine Datei",
        save: "Speichern",
        uploadYourPres: "Video hochladen",
        privateVid: "Privates Video",
        sharedVid: "Geteiltes Video",
        wouldLikeFeedback:
          "Ja, ich möchte Feedback vom Seminar Besitzer anfordern",
        shared:
          "Deine Aufnahme wird mit allen anderen Seminarteilnehmern geteilt. Bist du sicher, dass du das tun möchtest?",
        notShared:
          "Das folgende Video wird als Einreichung zum aktuellen Element dieses Seminars gespeichert",
      },
      giveFeedbackInfo:
        "Du musst einem anderen Benutzer Feedback geben. Wenn kein Video angezeigt wird, kannst du die Aufgabe überspringen",
    },

    read_text: { title: "Text lesen" },
    watch_video: { title: "Video ansehen" },
    video_presentation: {
      title: "Präsentation aufnehmen",
      submissions: "max. Aufzeichnung(en)",
      reqSubmissions: "benötigte Aufzeichnung(en)",
      sharedSubmissions: "max. geteilte Aufzeichnung(en)",
      reqSharedSubmissions: "benötigte geteilte Aufzeichnung(en)",
      share: "Aufzeichnung teilen",
      unshare: "Aufzeichnung nicht mehr teilen",
      delete: "Aufzeichnung löschen",
      delConfirm: "Bist du sicher, dass du die Aufzeichnung löschen willst?",
      error:
        "Du brauchst noch {submissions} Aufzeichnung(en) und {sharedSubmissions} geteilte Aufzeichnung(en)!",
      skipped: "Dies ist eine leere (übersprungene) Aufzeichnung.",
    },
    give_feedback: {
      title: "Feedback geben",
      text: "Nimm ein Video als Feedback zu diesem Video auf",
      noRating: "Dieses Video wird nicht bewertet",
      nextTitle: "Nimm ein Video als Feedback zu diesem Video auf",
      yourSubmissions: "Deine Aufzeichnung",
      noVideo: "Kein Video gefunden",
      noVideoDetail:
        "Wir konnten leider kein Video finden, das noch nicht von einem anderen Nutzer geprüft wurde.",
    },
    watch_feedback: {
      no_feedback: "Noch keine Feedbackvideos verfügbar!",
      title: "Feedback ansehen",
      text:
        "Sieh dir dieses Video an, das ein anderer Teilnehmer als Feedback zu deiner Präsentation gegeben hat",
    },
    gap_text: {
      title: "Lücken ausfüllen",
      error: "Bitte fülle vor dem Absenden alle Lücken aus",
    },
    rephrase: {
      title: "Umformulieren",
      sentences: "Sätze",
      error: "Bitte fülle alle Felder aus",
    },
    categorize: {
      title: "Sätze verlinken",
      phrases: "Sätze",
      noMore: "Keine Sätze mehr",
      categories: "Kategorien",
      dnd: "Hier ziehen und ablegen",
      error: "Bitte ordne alle Sätze in Kategorien ein",
      correct: "Richtige Kategorie",
    },
    vr_training: {
      title: "VR Training",
      requiredScore: "Erforderliche Punktzahl",
      yourSubmissions: "Deine Einreichungen",
      score: "Punktzahl",
      refresh: "Einreichungen neuladen",
    },
    goal: {
      title: "Ziel",
      target: "Erforderten Sessions",
      progress: "Valide Sessions",
      start: "Starte die Herausforderung!",
      description:
        "Du musst dieses Ziel dir zuweisen. Klicke den unteren Button und erfülle die Anzahl der erforderlichen Sessions mit den angegebenen Zielwerten.",
      assign: "Weise mir das Ziel zu!",
    },

    nameRequired: "Name erforderlich",
    descRequired: "Beschreibung erforderlich",

    ratings: {
      amusing: "Lustig",
      creative: "Kreativ",
      professional: "Professionell",
    },

    videos: {
      title: "Alle Aufzeichnungen",
      noSubmissions: "Bisher gibt es keine Aufzeichnungen",

      sortBy: {
        title: "Sortieren nach",
        date: "Datum",
      },
      filter: {
        users: {
          title: "Benutzer",
          detail: "Filtern nach Benutzer",
          placeholder: "Filtern nach Name...",
        },
        elements: {
          title: "Elemente",
          detail: "Filtern nach Element",
        },
      },
    },
    vrScores: {
      title: "VR Ergebnisse",
      noVr: "Es gibt keine VR Elemente in diesem Seminar",
    },
    done: "Fertig",
    doneText:
      "Du bist mit diesem Seminar fertig. Dein Fortschritt wurde gespeichert",
    back: "Zurück zum Dashboard",
    finish: "Beenden",
  },

  homePage: {
    welcomeMessage: "Willkommen bei PREZP",
    noPermissions:
      "Du hast keine Berechtigungen. Wenn Seminar-/Schulungsberechtigungen erteilt werden, kkannst du das Fenster neu laden und mit dem Durchsuchen beginnen",
    seminarBtnText: "PREZP Seminare",
    sessionTrainingBtnText: "PREZP Session Training",
    noSemiPermission:
      "Dieser Bereich ist deaktiviert, da du kein PREZP Seminar lizenziert hast. Wenn du mehr darüber erfahren möchtest, kontaktiere uns einfach über die Chat-Funktion.",
    noSessPermission:
      "Dieser Bereich ist deaktiviert, da du PREZP Session Training nicht lizenziert hast. Wenn du mehr darüber erfahren möchtest, kontaktiere uns einfach über die Chat-Funktion.",
  },

  invitations: {
    title: "Einladungen",
    type: "Einladungstyp",
    all: "Alles",
    active: "Aktiv",
    inactive: "Inaktiv",
    sent: "Gesendete Einladungen",
    revoke: "Einladung widerrufen",
    accept: "Einladung annehmen",
    decline: "Einladung ablehnen",
    noInvitations: "Du hast keine Einladungen",
  },

  zoomControl: "Zoom auf Folie",

  notifications: {
    feedback_received: "Feedback erhalten",
    rate_video: "Neues Video zum Bewerten hochgeladen",
    rating_received: "Neue Bewertung erhalten",
    months: "Monate",
    weeks: "Wochen",
    days: "Tage",
    hours: "Stunden",
    minutes: "Minuten",
    fewSeconds: "vor wenigen Sekunden",
  },

  pfp: {
    preview: "Dies ist eine Vorschau deines neuen Profilbilds",
    uploadNew: "Dieses Bild hochladen",
    chooseNew: "Neues Bild auswählen",
    cancelChange: "Änderung abbrechen",
    removeImage: "Aktuelles Bild entfernen",
    recSquare: "Wir empfehlen ein quadratisches Bild",
  },

  api_errors: {
    image_upload_failed: "Bild-Upload fehlgeschlagen",
    user_not_found: "Benutzer nicht gefunden",
    owner_not_found: "Besitzer nicht gefunden",
    invalid_type: "ungültiger Typ",
    user_exists: "Dieser Benutzer existiert schon",
    no_user_with_this_email: "Es gibt keinen Benutzer mit dieser E-Mail",
    password_incorrect: "Falsches Passwort",
    email_not_confirmed:
      "Bitte bestätige Deine E-Mail Adresse über den Link in Deiner Registrierungsemail. Dein Account ist noch nicht aktiviert.",
    user_not_active: "Benutzer ist nicht aktiv",
    access_denied: "Zugriff verweigert",
    element_not_found: "Element nicht gefunden",
    block_not_found: "Block nicht gefunden",
    seminar_not_found: "Seminar nicht gefunden",
    user_not_authorized: "Benutzer nicht autorisiert",
    seminar_order_not_found: "Seminar Ordnung nicht gefunden",
    block_data_not_found: "Blockdaten nicht gefunden",
    element_data_not_found: "Elementdaten nicht gefunden",
    invitation_not_found: "Einladung nicht gefunden",
    invitation_deactivated: "Keine aktive Einladung",
    seminar_user_not_found: "Seminarbenutzer nicht gefunden",
    goal_not_found: "Ziel nicht gefunden",
    cannot_delete: "Kann nicht löschen",
    device_token_failed:
      "Ein Fehler ist aufgetreten. Gerät konnte nicht authorisiert werden.",
  },

  browser_warnings: {
    unsuppBrowser: "Nicht unterstützter Browser",
    unsuppBrowserTitle: "Nicht unterstützter Browser erkannt",
    unsuppBrowserText:
      "Einige Funktionen, wie der Audio-/Videorecorder, funktionieren möglicherweise nicht richtig. Für eine optimale Erfahrung verwende bitte den Chrome-Browser.",
    dontShow: "Zeig mir das nicht noch einmal",
    ok: "Ok",
  },

  tour: {
    box: {
      title: "Fortschritt Einführungstour",
      home: "PREZP Seminars",
      dash: "PREZP Dashboard",
      goals: "Ziele",
      recorder: "PREZP Recorder",
      sessions: "Trainings-Einheiten",
    },
    step: {
      skip: "Überspringen",
      exit: "Ausgang",
      next: "Nächste",
      finish: "Beenden",
      close: "Abschließen",
      of: "von",
    },
    welcome: {
      title: "Willkommen bei PREZP",
      body:
        "Wenn du neu bei PREZP sind, haben wir ein Tutorial, um dir den Einstieg in die Plattform zu erleichtern. Es zeigt die wichtigsten Funktionen und deren Verwendung!",
      btn1: "Zeige mir das nicht mehr",
      btn2: "Mit dem Tutorial beginnen",
    },
    finshed: {
      title: "Fantastisch",
      body:
        'Du hast das gesamte Tutorial abgeschlossen. Wenn du ein Tutorial erneut besuchen möchtest, steht oben auf jeder Seite die Taste "Tutorial starten" zur Verfügung.',
    },
    home: {
      0: {
        title: "Willkommen bei den PREZP-Seminaren",
        body:
          "Hier findest du zwei Bereiche: 1. Eine Liste der Seminare, bei denen du entweder Teilnehmer oder Inhaber bist. 2. Eine Auflistung deiner Seminar-Einladungen. Schauen wir uns nun jeden Bereich einzeln an.",
      },
      1: {
        title: "Liste der Seminare",
        body:
          "In diesem Bereich werden deine Seminare angezeigt. Wenn du auf das Seminar-Element klickst, wirst du zu diesem Seminar weitergeleitet. Probiere es doch gleich mal aus!",
      },
      2: {
        title: "Liste der Einladungen",
        body: "Hier kannst du alle Seminar-Einladungen verwalten. ",
      },
      3: {
        title: "Next up",
        body:
          'Damit ist unser Rundgang durch die PREZP-Seminare beendet. Als Nächstes navigiere zum Bereich "Dashboard", wo wir den nächsten Schritt unserer Tour beginnen.',
      },
    },
    dash: {
      0: {
        title: "Willkommen auf deinem PREZP-Dashboard",
        body:
          "Hier findest du dein eigenes, personalisiertes Dashboard, mit allen Informationen rund um dein Training. Beginnen wir die Tour!",
      },
      1: {
        title: "Präsentationen",
        body:
          "Hier kannst du deine Präsentationen hochladen. Verbessere deine Präsentationen in dem du dich selbst, mit dem PREZP Recorder, dem PREZP Video-Recorder oder PREZP VR aufnimmst. Wir werden die Funktionen später in der Tour noch besuchen. Hinweis: Bitte verwende derzeit PDF.",
      },
      2: {
        title: "Eine Präsentation hochladen",
        body: "Klicke auf diese Schaltfläche und wähle eine Präsentation aus.",
      },
      3: {
        title: "Liste der Präsentationen",
        body:
          "Hier findest du eine Liste aller hochgeladenen Präsentationen. Der Mikrofon-Button führt dich direkt zum PREZP Recorder. Außerdem kannst du deine Präsentationen im Vollbildmodus ansehen, löschen, herunterladen oder umbenennen.",
      },
      4: {
        title: "Präsentationsfolien",
        body:
          "Verwende die Pfeile am unteren Rand, um zwischen den Präsentationsfolien zu wechseln.",
      },
      5: {
        title: "Ziele",
        body:
          "Alle Ziele, die du erstellt hast oder die dir zugewiesen wurden, werden hier aufgelistet, wobei das zuletzt erstellte Ziel ganz oben steht. Du wirst später in der Tour mehr über den Einsatz von Zielen erfahren.",
      },
      6: {
        title: "Gruppen",
        body: "Die Gruppen, denen du angehörst, werden hier angezeigt.",
      },
      7: {
        title: "Videos",
        body:
          "Dieser Bereich zeigt dir alle hochgeladenen Videos aus deinen Gruppen. Verwende die Dropdown-Liste oben rechts, um Videos einer bestimmten Gruppe angezeigt zu bekommen.",
      },
      8: {
        title: "Nachrichten",
        body:
          "Alle plattformbezogenen Nachrichten und Aktualisierungen werden hier in Kurzform aufgeführt. Wenn du oben rechts auf die Schaltfläche klickst, gelangst du auf die Nachrichtenseite, auf der du alle Nachrichten einsehen kannst.",
      },
      9: {
        title: "Bravo!",
        body:
          "Jetzt weißt du alles, was es über die Dashboard-Seite zu wissen gibt.",
      },
    },
    goals: {
      0: {
        title: "Ziele",
        body:
          "Auf dieser Seite findest du eine Auflistung deiner Ziele. Die Liste enthält selbst erstellte Ziele und zugewiesene Ziele. Beginnen wir mit einem selbst gesetzten Ziel.",
      },
      1: {
        title: "Ein neues Ziel hinzufügen",
        body: "Klicke hier, um ein neues Ziel zu erstellen.",
      },
      2: {
        title: "Sich ein Ziel setzen",
        body:
          "Ziele sind entscheidend für deinen Lernprozess. Anhand der Ziele, die du dir setzt, kannst du deinen Sprachstil verändern! Sprichst du derzeit zu schnell? Dann setze dir einfach eine niedrigere Sprechgeschwindigkeit als Ziel. Auf diese Weise bekommst du ein Gefühl dafür, wie schnell oder langsam du sprichst, was dazu beiträgt deine Zuhörer zu fesseln und sie aktiv zuhören zu lassen.",
      },
      3: {
        title: "Trainingseinheiten",
        body:
          "Hier legst du die Anzahl der Trainings und somit Wiederholungen fest, die du absolvieren möchtest. Wusstest du, dass eine professionelle Sängerin, ein neues Lied bis zu 100 Mal einsingt?",
      },
      4: {
        title: "Bedingungen",
        body:
          "Lege Bedingungen fest, die erfüllt sein müssen, damit die absolvierte Trainingseinheit deinen Zielen entspricht.",
      },
      5: {
        title: "Benutzer",
        body: "Ordne dir oder anderen Gruppenmitgliedern Ziele zu.",
      },
      6: {
        title: "Bestätigen",
        body: "Klicke hier, um das gesetzte Ziel zu bestätigen.",
      },
      7: {
        title: "Erledigt",
        body:
          "Das soeben erstellte Ziel wird unter „erstellten Zielen“ oder „zugewiesenen Zielen“ aufgelistet.",
      },
    },
    recorder: {
      0: {
        title: "PREZP Rekorder",
        body:
          "In diesem Teil erfährst du, wie du ein Audio oder Video aufnimmst und damit eine Trainingseinheit erstellst. Denk daran, dass du dir gerade ein Ziel gesetzt hast. Behalte die Bedingungen im Hinterkopf, wenn du deine Aufnahme erstellst.",
      },
      1: {
        title: "Liste der Aufnahmen",
        body: "Das ist eine Auflistung all deiner Audio- und Videoaufnahmen.",
      },
      2: {
        title: "Rekorder",
        body:
          "Drücke die rote Taste, um eine Aufnahme zu starten oder zu stoppen",
      },
      3: {
        title: "Präsentation auswählen",
        body: "Wähle eine Präsentation aus.",
      },
      4: {
        title: "Folien",
        body:
          "Wechsel von einer Präsentationsfolie zur nächsten. Jedes Mal, wenn du die Pfeiltaste drückst, wird ein Zeitstempel gesetzt, anhand dessen du später Feedback pro Folie erhalten kannst.",
      },
      5: {
        title: "Sprache auswählen",
        body: "Wähle eine Sprache.",
      },
      6: {
        title: "Geräteauswahl",
        body: "Wähle hier dein Eingabegerät aus.",
      },
      7: {
        title: "Stummschalten/Stummschaltung aufheben",
        body:
          "Hier kannst du das Mikrofon stummschalten und die Stummschaltung aufheben.",
      },
      8: {
        title: "Eingabelautstärke",
        body:
          "Wenn sich die Anzeige nicht bewegt, überprüfe, ob dein Mikrofon funktioniert, eingeschaltet und bei der Geräteauswahl ausgewählt ist.",
      },
      9: {
        title: "Audio aufnehmen",
        body: "Drücke den Knopf für die Aufnahme.",
      },
      10: {
        title: "Deine Aufnahme",
        body: "Klicke auf die erstellte Aufnahme.",
      },
      11: {
        title: "Aufzeichnungsdetails",
        body:
          "Hier gibst du die verwendete Sprache der Aufzeichnung ein, derzeit Englisch und Deutsch. Du kannst deine Präsentation auch umbenennen und dann speichern",
      },
      12: {
        title: "Video wechseln",
        body: "Hiermit wechselst du zur Videoaufnahme.",
      },
      13: {
        title: "Videovorschau",
        body: "Bewerte deine Aufnahme in Echtzeit.",
      },
      14: {
        title: "Aktionen",
        body: "Ein Video starten, anhalten, stoppen oder spiegeln.",
      },
      15: {
        title: "Video aufnehmen",
        body: "Hier kannst du deine Videos aufnehmen.",
      },
      16: {
        title: "Wähle eine Aufnahme",
        body:
          "Klicke auf eine deiner Aufnahmen und erstelle daraus eine Trainings-Session.",
      },
      17: {
        title: "Erstelle eine Trainingseinheit",
        body:
          'Klicke auf die Schaltfläche "Aufzeichnung speichern unter" und wähle die Option "Neue Session" aus.',
      },
      18: {
        title: "Fantastisch",
        body: "Kommen wir zu den Sessions",
      },
    },
    sessions: {
      0: {
        title: "Sessions",
        body:
          'Willkommen auf der Seite "Sessions". Hier werden deine Präsentationen analysiert.',
      },
      1: {
        title: "Liste der Sessions",
        body: "Das ist eine Auflistung deiner analysierten Sessions.",
      },
      2: {
        title: "Session filtern",
        body:
          "Filter deine Session einfach nach Dauer, Datum, Name oder Präsentationen.",
      },
      3: {
        title: "Sessions teilen",
        body:
          "Hier kannst du deine Präsentationen mit anderen Teilnehmern teilen und wichtiges Feedback erhalten!",
      },
      4: {
        title: "Wähle deine Session",
        body: "Um fortzufahren, wähle eine vollständig analysierte Session.",
      },
      5: {
        title: "Sessiondetails",
        body: "Hier bekommst du noch mal einen Session-Überblick.",
      },
      6: {
        title: "Notensystem",
        body:
          "Hier zeigen wir dir die erreichten Noten! Somit erhältst du einen wichtigen Überblick, über deine persönliche Entwicklung. Details zu den Noten, findest du grafisch dargestellt, neben der Note selbst. ",
      },
      7: {
        title: "Folien",
        body: "Alle verwendeten Präsentationsfolien siehst du hier.",
      },
      8: {
        title: "Sprechgeschwindigkeit",
        body:
          "Diese Karte zeigt dir, wie schnell du im Durchschnitt gesprochen hast. In Wörtern pro Minute.",
      },
      9: {
        title: "Pausen",
        body:
          "Wir analysieren deine Pausen und zeigen dir, wie viele Pausen du während deiner Präsentation gemacht hast. Aktuell werden Pausen, ab einer Länge von über 1 Sekunde, als Pause gemessen.",
      },
      10: {
        title: "Stimmlage",
        body:
          "Bekomme ein Gefühl für deine Stimmlage! Wenn du während einer Präsentation mit einer zu hohen Stimme sprichst, kann dies dazu führen, dass du als nervös und unsicher wahrgenommen wirst.",
      },
      11: {
        title: "Sentimentsätze",
        body:
          "Hierbei handelt es sich um eine reine KI-Analyse. Die KI analysiert, ob ein Wort oder ein ganzer Satz eine positive, negative oder neutrale Stimmung vermittelt.",
      },
      12: {
        title: "Wortwahl",
        body:
          'Verwendest du zu viele Füllwörter? Oder Weichmacher? In diesem Bereich wird deine Wortwahl analysiert. Einige Beispiele hierfür sind: "Ähm, Hmm, Uhh, sehen Sie, ich meine," usw.',
      },
      13: {
        title: "Sentiment ratio",
        body: "Das ist Zusammenfassung der Sentimentsätze",
      },
      14: {
        title: "Schlüsselwörter",
        body:
          'Hier siehst du, welche "Schlüsselwörter" die KI in deiner Präsentation erkannt und rausgefiltert hat.',
      },
      15: {
        title: "Bewegungsdaten",
        body:
          "Die Bewegungsanalysen deines PREZP VR-Trainings findest du hier.",
      },
      16: {
        title: "Sitzungssteuerung",
        body: "Navigiere dich durch deine Aufzeichnung. ",
      },
      17: {
        title: "Auf Folie/Zeit zoomen",
        body:
          "Mit dieser Funktion kannst du die Analysen einer einzelnen Folie anzeigen lassen, wenn deine Präsentation mehrere Seiten umfasst.",
      },
      18: {
        title: "Anzahl der Datenpunkte auswählen",
        body:
          "Mit dieser Schaltfläche kannst du die Anzahl der Datenpunkte einstellen. Die Standardeinstellung sind 50 Datenpunkte.",
      },
      19: {
        title: "Benchmark-Sitzungen",
        body:
          "Stell dir eine Benchmark-Sitzung als ein Ziel vor. Wenn du eine bestimmten Stimmlage bevorzugst, dann lade einfach ein Audiobeispiel ins PREZP-Portal hoch. Deine Training-Sessions, werden mit deiner Benchmark-Session verglichen. Auf diese Weise arbeitest du immer auf dein Sprechziel hin!",
      },
      20: {
        title: "Trainings-Fortschritt",
        body:
          'Wähle den Menüpunkt "Session-Fortschritt" aus, um in die Analyse deiner Gesamt-Trainings zu gelangen.',
      },
      21: {
        title: "Fortschrittsseite",
        body:
          "Auf der Fortschrittsseite findest du alle Fortschritte, die du in den einzelnen Schwerpunktbereichen, z. B. Tonhöhe, Lautstärke, Wörter pro Minute usw., gemacht hast.",
      },
      22: {
        title: "Anzahl der zu vergleichenden Sessions",
        body:
          "Wähle hier die Anzahl der zu vergleichenden Trainings. Du kannst nach Inhalten, Daten, Dauer oder einem bestimmten Zeitraum filtern.",
      },
      23: {
        title: "Erledigt",
        body:
          "Jetzt kennst du unser gesamtes Portal und die Analysemöglichkeiten. Es ist Zeit mit dem Training zu beginnen. Wenn du weitere Fragen hast, dann schreibe  uns einfach eine Nachricht über unseren Support-Chat.",
      },
    },
  },
  feature: {
    locked: "Diese Funktion ist für Dich nicht freigeschaltet",
    toUnlockIt: "Wende Dich an Deinen Ansprechpartner, um sie zu aktivieren.",
    toCloseThisWindow:
      "Um dieses Fenster zu schließen, klicke einfach außerhalb des Dialogs.",
  },
  maintenance: {
    label: "Wartungsarbeiten",
    message: "Wir sind in Kürze wieder verfügbar",
  },
};

export default de;
