



























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import type { LocaleMessages } from "vue-i18n";

@Component
export default class RecordSession extends Vue {
  @Prop({ default: () => false }) mini!: boolean;
  @Prop({ default: "" }) label!: string | LocaleMessages;
  @Prop({ default: "" }) route!: string;
  @Getter("sessions/uploadingSession") loading!: boolean;
}
