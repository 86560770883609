import { MutationTree } from "vuex";
import { CompanyState } from ".";

const mutations: MutationTree<CompanyState> = {
  set(state, company) {
    state.company = company;
    state.getting = false;
  },
};
export default mutations;
