import { GetterTree } from "vuex";

import { SessionsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<SessionsState, RootState> = {
  loading: state => state.loading,
  retrieved: state => state.retrieved,
  getSessions: state =>
    state.sessions.sort(
      (a, b) => +new Date(b.CreatedAt) - +new Date(a.CreatedAt),
    ),
  getSharedSessions: state =>
    state.sharedSessions.sort(
      (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
    ),
  sessionsCount: state => state.sessions.length,

  progress: state => state.progress,
  progressLoading: state => state.progressLoading,
  progressRetrieved: state => state.progressRetrieved,

  removingSession: state => state.removingSession,
  uploadingSession: state => state.uploadingSession,
  gettingSingleSession: state => state.gettingSingleSession,
  retrievedSessionId: state => state.retrievedSessionId,

  aiFeedbackLoading: state => state.aiFeedbackLoading,
  aiFeedbackSessionId: state => state.aiFeedbackSessionId,
  aiFeedbackMessage: state => state.aiFeedbackMessage,

  benchmark: state => state.benchmark,
  benchmarks: state => state.benchmarks,
  benchmarkUser: state => state.benchmarkUser,
  baselineLoading: state => state.baselineLoading,
  benchmarksRetrieved: state => state.benchmarksRetrieved,
  benchmarksLoading: state => state.benchmarksLoading,

  meta: state => state.metaAnalysis,
  metaSettings: state => state.metaSettings,
  metaLoading: state => state.metaLoading,
};

export default getters;
