import { GetterTree } from "vuex";

import { RootState } from "../../root";
import { GroupsState } from "../../groups";

const getters: GetterTree<GroupsState, RootState> = {
  addUsersLoading: state => state.addUsersLoading,
  changeUserRoleLoading: state => state.changeUserRoleLoading,
  editedUserEmail: state => state.editedUserEmail,
  removeUserLoading: state => state.removeUserLoading,
  removedUserEmail: state => state.removedUserEmail,
  usersManaged: (state, _, _1, rootGetters) => {
    const currentUserEmail = rootGetters["profile/getUserEmail"];
    const usersManaged = state.groups
      .filter(g => g.IsManager)
      .flatMap(g => g.Users)
      .map(g => g.Email)
      .filter(u => u !== currentUserEmail);
    return [...new Set(usersManaged)];
  },
};

export default getters;
