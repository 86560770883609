import { ActionTree, Dispatch } from "vuex";

import api from "@/core/utils/api";
import {
  AddElementAction,
  CreateSeminarRequest,
  EditSeminarRequest,
  GetSubsRequest,
  RateVideoAction,
  RemoveElementRequest,
  SemIdUId,
} from "@/core/models/requests";
import {
  Seminar,
  SeminarBlock,
  SeminarElement,
  Submission,
  Rating,
  SeminarOrder,
} from "@/core/models";
import {
  blocksInOrder,
  elementStatus,
  requestFromElement,
  typeToEndpoint,
} from "@/core/utils/seminars";

import { SeminarsState } from ".";
import { RootState } from "../root";

const emptySubmissionElements = new Map([
  ["read_text", "/api/Submissions/ReadText"],
  ["watch_video", "/api/Submissions/WatchVideo"],
]);

const updateArray = (state: SeminarsState) => {
  const idx = state.seminars.findIndex(x => x.id === state.selected?.id) || -1;
  if (idx === -1) return;
  state.seminars[idx] = JSON.parse(JSON.stringify(state.selected));
};

const refetchSeminar = (state: SeminarsState, dispatch: Dispatch) => {
  const seminarId = state.selected?.id;
  const selectedIdx = state.seminars.findIndex(x => x.id === seminarId);
  state.selected = undefined;
  if (selectedIdx !== -1) state.seminars.splice(selectedIdx, 1);
  dispatch("get", { id: seminarId, refresh: true });
};

export const actions: ActionTree<SeminarsState, RootState> = {
  clear({ state }) {
    state.adding = false;
    state.loading = false;
    state.retrieved = false;
    state.updating = false;
    state.selected = undefined;
    state.seminars = [];
  },

  set({ state }, seminars: Seminar[]) {
    state.seminars = seminars;
    if (seminars.length > 0) state.selected = seminars[0];
    state.adding = false;
    state.loading = false;
    state.retrieved = false;
    state.updating = false;
  },

  setindices({ state }, indices: [number, number]) {
    state.blockIndex = indices[0];
    state.elementIndex = indices[1];
  },

  next({ state, getters }) {
    const el = getters["element"];
    if (!state.selected || !el) return;

    const index = state.blockIndex;
    const elIndex = state.elementIndex;

    const blocks = blocksInOrder(state.selected, state.selected.order);
    const prevBlock = index - 1 < 0 ? null : blocks[index - 1];
    const lastElOfPrevBlock =
      prevBlock?.elements[prevBlock.elements.length - 1];
    const prevEl =
      elIndex === 0 ? lastElOfPrevBlock : blocks[index].elements[elIndex - 1];

    if (!prevEl || elementStatus(prevEl, state.selected) !== "active") {
      const lastElIdxOfBlockIdx = blocks[state.blockIndex].elements.length - 1;
      const lastBlockIdx = blocks.length - 1;
      if (state.elementIndex === lastElIdxOfBlockIdx) {
        if (state.blockIndex === lastBlockIdx) return;
        state.blockIndex++;
        state.elementIndex = 0;
      } else state.elementIndex++;
    }
  },

  prev({ state }) {
    if (!state.selected) return;
    if (state.elementIndex === 0) {
      if (state.blockIndex === 0) return;
      const blocks = blocksInOrder(state.selected, state.selected.order);
      state.blockIndex--;
      state.elementIndex = blocks[state.blockIndex].elements.length - 1;
    } else state.elementIndex--;
  },

  // get one/all
  async getAll({ state }) {
    state.loading = true;
    try {
      const seminars = (await api.get("/api/Seminar")) as Seminar[];
      state.seminars = seminars.map(x => {
        x.retrieved = false;
        return x;
      });
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async get(
    { state, getters, dispatch },
    { id, refresh }: { id: number; refresh?: boolean },
  ) {
    const existing = state.seminars.find(x => x.id === id);
    if (!refresh && existing && existing.retrieved) {
      state.selected = existing;
      return;
    }

    state.loading = !refresh;
    try {
      if (!id && !state.seminars.length) await dispatch("getAll");
      const seminarId = id || state.seminars[0].id;
      const seminar = (await api.get(`/api/Seminar/${seminarId}`)) as Seminar;
      seminar.retrieved = true;
      if (seminar.order)
        seminar.initialOrder = JSON.parse(JSON.stringify(seminar.order));
      const newArr = state.seminars.slice(0);

      // check submissions
      // const blocks = seminar.blocks.map(b => {
      //   const elements = b.elements.map(e => {
      //     // subs for this el
      //     const all = seminar.submissions?.filter(x => x.elementId === e?.id);
      //     const subs = all?.filter(x => !x.skipped);
      //     const skippedSubs = all?.filter(x => x.skipped);

      //     // element has been skipped
      //     if (all?.length === 1 && all[0].skipped)
      //       return { ...e, skipped: true };

      //     // check video_presentation differently
      //     let completed = false;
      //     if (e?.type === "video_presentation") {
      //       const numReq = e.requiredSubmissions || 1;
      //       const numReqShared = e.requiredNumShared || 0;
      //       const sharedSubs = subs?.filter(x => x.isShared).length || 0;
      //       completed =
      //         (subs?.length || 0) >= numReq && sharedSubs >= numReqShared;

      //       // if not completed - might be skipped
      //       if (!completed) {
      //         const isSkipped = (skippedSubs?.length || 0) > 0;
      //         if (isSkipped) return { ...e, skipped: true };
      //       }
      //     } else completed = (subs?.length || 0) > 0;

      //     console.debug(
      //       "[get one] Element",
      //       e.title,
      //       "Subs",
      //       all,
      //       "Completed",
      //       completed,
      //     );

      //     return { ...e, completed };
      //   });
      //   return { ...b, elements };
      // });

      const newSeminar = JSON.parse(JSON.stringify(seminar));
      // newSeminar.blocks = blocks;
      let idx = newArr.findIndex(x => x.id === id);
      if (idx === -1) {
        newArr.push(seminar);
        idx = newArr.length;
      } else newArr[idx] = newSeminar;
      state.selected = newSeminar;
      state.seminars = JSON.parse(JSON.stringify(newArr));

      const upTo = getters["selectableUpTo"] as [number, number];
      state.blockIndex = upTo[0];
      state.elementIndex = upTo[1];
    } catch (error) {
      console.log(error);
      state.selected = undefined;
    }
    state.loading = false;
  },

  // CRUD
  // todo seminar not right
  async create({ state }, d: CreateSeminarRequest) {
    state.loading = true;
    try {
      const data = new FormData();
      data.append("name", d.name);
      data.append("description", d.description);
      if (d.logo) data.append("logo", d.logo);
      const seminar = (await api.post("/api/Seminar", data, {
        headers: { ContentType: "multipart/form-data" },
      })) as Seminar;
      state.seminars = [...state.seminars, seminar];
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async edit({ state }, d: EditSeminarRequest) {
    state.loading = true;
    console.debug(d);
    try {
      const data = new FormData();
      data.append("seminarId", JSON.stringify(d.seminarId));
      data.append("name", d.name);
      data.append("description", d.description);
      if (d.logo) data.append("logo", d.logo);
      const image = (await api.patch("/api/Seminar/Edit", data, {
        headers: { ContentType: "multipart/form-data" },
      })) as string;

      if (!state.selected) return;
      state.selected.id = d.seminarId;
      state.selected.name = d.name;
      state.selected.description = d.description;
      state.selected.logo = image;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async removeLogo({ state }, id: number) {
    state.loading = true;
    try {
      await api.delete(`/api/Seminar/RemoveLogo/${id}`);
      if (!state.selected) return;
      state.selected.logo = "";
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async remove({ state }, id: number) {
    state.loading = true;
    try {
      await api.delete(`/api/Seminar/${id}`);
      state.selected = undefined;
      state.seminars = state.seminars.filter(x => x.id !== id);
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  // Add/edit block
  async addBlock({ state }, block: SeminarBlock) {
    state.adding = true;
    try {
      // create block
      const data = {
        title: block.title,
        description: block.description,
        seminarId: block.seminarId,
      };
      const id = (await api.post("/api/Seminar/CreateBlock", data)) as number;
      block.id = id;

      // make new seminar
      if (!state.selected) return;
      const newSeminar = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      if (!newSeminar.blocks) state.selected.blocks = [];
      newSeminar.blocks.push(block);

      // update order
      const newOrder = JSON.parse(
        JSON.stringify(newSeminar.order),
      ) as SeminarOrder;
      newOrder.blocks.push({ blockId: id, blockIndex: newOrder.blocks.length });

      // update seminar
      newSeminar.order = newOrder;
      newSeminar.initialOrder = JSON.parse(JSON.stringify(newOrder));
      const idx = state.seminars.findIndex(x => x.id === block.seminarId);
      if (idx === -1) return window.location.reload(); // todo controversial?
      state.seminars[idx] = newSeminar;
      state.selected = newSeminar;
    } catch (error) {
      console.log(error);
    }
    state.adding = false;
  },

  async editBlock({ state }, block: SeminarBlock) {
    state.updating = true;
    try {
      const data = { ...block, elements: null };
      await api.patch("/api/Seminar/Block", data);
      if (!state.selected) throw new Error("No current seminar");
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      const idx = newSelected.blocks.findIndex(x => x.id === block.id);
      if (idx !== -1) {
        newSelected.blocks[idx].title = block.title;
        newSelected.blocks[idx].description = block.description;
        state.selected = newSelected;
      }
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  async removeBlock({ state, dispatch }, id: number) {
    state.updating = true;
    try {
      await api.delete(`/api/Seminar/DeleteBlock/${id}`);
      // if (!state.selected) throw new Error("Seminar not found");

      // // set stuff
      // const newSelected = { ...state.selected };
      // const newBlocks = state.selected.blocks.slice(0) || [];
      // const elIds = newBlocks.flatMap(x => x.elements.map(x => x.id));
      // const newSubs = state.selected.submissions?.slice(0) || [];
      // newSelected.blocks = newBlocks.filter(x => x.id !== id);
      // newSelected.submissions = newSubs.filter(
      //   x => !elIds.includes(x.elementId),
      // );

      // state.selected = JSON.parse(JSON.stringify(newSelected);
      // updateArray(state);
      refetchSeminar(state, dispatch);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  // add/edit element
  async addElement({ state }, { element, file }: AddElementAction) {
    state.adding = true;
    try {
      // send request
      const request = requestFromElement(element);
      const endpoint = typeToEndpoint(element.type);
      if (!request) return;

      // send request
      let res: any;
      if (request.type === "watch_video") {
        if (file) request.data.append("videoFile", file);
        else request.data.append("videoURI", (element as any).videoURI);
        res = (await api.post(endpoint, request.data, {
          headers: { ContentType: "multipart/form-data" },
        })) as any;
      } else res = (await api.post(endpoint, request.data)) as any;

      // setup new element to add
      element.id = res.id;

      // add video uri if type is watch_video
      if (element.type === "watch_video") element.videoURI = res.uri;
      // add goal id if element is goal
      if (element.type === "goal") element.goalId = res.goalId;

      // find seminar/block
      const newArr = state.seminars.slice(0);
      const sIdx = newArr.findIndex(x => x.id === element.seminarId);
      if (sIdx === -1) return;
      const bIdx = newArr[sIdx].blocks.findIndex(x => x.id === element.blockId);
      if (bIdx === -1) return;

      // Add element
      newArr[sIdx].blocks[bIdx].elements.push(element);
      const els = newArr[sIdx].elements || [];
      newArr[sIdx].elements = [...els, element];

      // update order
      const newOrder = JSON.parse(
        JSON.stringify(newArr[sIdx].order),
      ) as SeminarOrder;
      const newIndex = newOrder.elements.filter(
        x => x.blockId === element.blockId,
      ).length;
      newOrder.elements.push({
        blockId: element.blockId,
        elementId: element.id,
        elementIndex: newIndex,
      });
      newArr[sIdx].order = newOrder;
      newArr[sIdx].initialOrder = JSON.parse(JSON.stringify(newOrder));

      // update seminars
      state.seminars = JSON.parse(JSON.stringify(newArr)) as Seminar[];
      state.selected = JSON.parse(JSON.stringify(newArr[sIdx]));
    } catch (error) {
      console.log(error);
    }
    state.adding = false;
  },

  async editElement({ state }, { element, file }: AddElementAction) {
    state.updating = true;
    try {
      const endpoint = typeToEndpoint(element.type);
      const request = requestFromElement(element);
      if (!request) throw new Error("Element type not found");

      if (request.type === "watch_video") {
        const vidUri = (element as any).videoURI as string | undefined;
        request.data.append("elementId", element.id.toString());
        if (file) request.data.append("videoFile", file);
        else if (vidUri) request.data.append("videoURI", vidUri);
        const { uri } = (await api.post(endpoint, request.data, {
          headers: { ContentType: "multipart/form-data" },
        })) as any;
        if (uri) (element as any).videoURI = uri;
      } else {
        const { uri } = (await api.post(endpoint, {
          ...request.data,
          elementId: element.id,
        })) as any;
        if (uri) (element as any).videoURI = uri;
      }

      if (!state.selected) return;
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;

      // update blocks
      const blockIdx = newSelected.blocks.findIndex(
        x => x.id === element.blockId,
      );
      if (blockIdx !== -1) {
        const idx = newSelected.blocks[blockIdx].elements.findIndex(
          x => x.id === element.id,
        );
        newSelected.blocks[blockIdx].elements[idx] = element;
      }

      // update elements
      if (newSelected.elements) {
        const elIdx =
          newSelected.elements?.findIndex(x => x.id === element.id) || -1;
        if (elIdx !== -1) newSelected.elements[elIdx] = element;
      }

      // update selected
      state.selected = newSelected;

      // update array
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  // blockId
  async removeElement({ state, dispatch }, { id }: RemoveElementRequest) {
    // start
    state.updating = true;
    try {
      // make api call
      await api.delete(`/api/Elements/${id}`);
      // if (!state.selected) throw new Error("Seminar not found");

      // const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;

      // // remove subs
      // const newSubs = JSON.parse(
      //   JSON.stringify(newSelected.submissions || []),
      // ) as Submission[];
      // newSelected.submissions = newSubs.filter(x => x.elementId !== id);

      // // remove element from els array
      // const newElements = newSelected.elements?.slice(0) || [];
      // newSelected.elements = newElements.filter(x => x.id !== id);

      // // remove element from block
      // const idx = newSelected.blocks.findIndex(x => x.id === blockId);
      // if (idx !== -1) {
      //   const els = JSON.parse(
      //     JSON.stringify(newSelected.blocks[idx].elements),
      //   ) as SeminarElement[];
      //   newSelected.blocks[idx].elements = els.filter(x => x.id !== id);
      // }

      // // update order
      // if (newSelected.order?.elements) {
      //   newSelected.order.elements = newSelected.order.elements.filter(
      //     x => x.elementId !== id,
      //   );
      //   newSelected.initialOrder = JSON.parse(
      //     JSON.stringify(newSelected.order),
      //   );
      // }

      // // update seminar
      // state.selected = newSelected;
      // updateArray(state);

      refetchSeminar(state, dispatch);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  async submitEmpty({ state }, el?: SeminarElement) {
    // element already skipped/completed
    if (!el || elementStatus(el) !== "active") return;

    try {
      const sub = (await api.post(
        emptySubmissionElements.get(el.type) as string,
        { elementId: el.id, seminarId: el.seminarId },
      )) as Submission;

      if (!state.selected || !sub) return;
      const newSeminar = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      const oldSubs = newSeminar.submissions || [];
      newSeminar.submissions = [...oldSubs, sub];
      state.selected = newSeminar;
      updateArray(state);
    } catch (error) {
      console.error(error);
    }
  },

  async skip({ state }, el?: SeminarElement) {
    // element already skipped/completed
    if (!el || elementStatus(el) !== "active") return;

    try {
      const sub = (await api.get(`/api/Submissions/SkipElement/${el.id}`)) as
        | Submission
        | undefined;

      if (!state.selected || !sub) return;
      const newSeminar = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      const oldSubs = newSeminar.submissions || [];
      newSeminar.submissions = [...oldSubs, sub];
      state.selected = newSeminar;
      updateArray(state);
    } catch (error) {
      throw new Error("Could not skip");
    }

    // update array
  },

  addSubmission({ state }, submission: Submission) {
    if (!state.selected) return;
    const seminar = JSON.parse(JSON.stringify(state.selected));
    seminar.submissions = [...(seminar.submissions || []), submission];
    state.selected = seminar;
  },

  async getFeedbackVideos({ state, dispatch }, id: number) {
    state.gettingFeedback = true;
    try {
      const end = `/api/Submissions/FeedbackForUser/${id}`;
      const res = (await api.get(end)) as Submission[];
      const map = new Map(state.feedbackVideos.entries());
      map.set(id, res);
      state.feedbackVideos = new Map(map);
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
    state.gettingFeedback = false;
  },

  async toggleShared({ state, dispatch }, id: number) {
    try {
      await api.patch(`/api/Submissions/ToggleShared/${id}`);
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      if (
        !state.seminars.length ||
        !newSelected ||
        !newSelected.submissions?.length ||
        !newSelected.elements?.length ||
        !newSelected.blocks?.length
      )
        return;

      // find current sub
      const sub = newSelected.submissions?.find(x => x.id === id);
      if (!sub) return;

      // update selected
      newSelected.submissions = [
        ...newSelected.submissions.filter(x => x.id !== id),
        { ...sub, isShared: !sub.isShared },
      ];

      // video got shared/unshared - check if el becomes complete/incomplete
      const elIdx = newSelected.elements?.findIndex(
        x => x.id === sub.elementId,
      );
      if (elIdx !== -1) {
        const el = newSelected.elements[elIdx];
        if (el.type === "video_presentation") {
          const numShared = newSelected.submissions.filter(
            x => x.elementId === el.id && !x.skipped && x.isShared,
          ).length;
          const reqShared = el.requiredNumShared || 0;

          // update el on its own
          newSelected.elements[elIdx].completed = numShared >= reqShared;

          // update el in block
          const blockIdx = newSelected.blocks.findIndex(
            x => x.id === el.blockId,
          );
          if (blockIdx !== -1) {
            const elInBlockIdx = newSelected.blocks[
              blockIdx
            ].elements.findIndex(x => x.id === el.id);
            if (elInBlockIdx !== -1)
              newSelected.blocks[blockIdx].elements[elInBlockIdx].completed =
                numShared >= reqShared;
          }
        }
      }

      // update state
      state.selected = JSON.parse(JSON.stringify(newSelected));
      updateArray(state);
    } catch (err) {
      dispatch("displaySnackbar", err, { root: true });
    }
  },

  async rateFeedbackVideo(
    { state, rootGetters },
    { submissionId, type, seminarId }: any,
  ) {
    const rating = (await api.post("/api/Submissions/Rate", {
      submissionId,
      type,
    })) as Rating | null;

    const vids = state.feedbackVideos.get(seminarId);
    if (!vids) return;

    const vidIndex = vids.findIndex(x => x.id === submissionId);
    const video = JSON.parse(JSON.stringify(vids[vidIndex])) as Submission;
    if (!video) return;
    if (video.type !== "video_presentation" && video.type !== "give_feedback")
      return;

    const userId = rootGetters["profile/id"] as string;
    const ratings = video.videoRatings || [];

    if (!rating) {
      const rIdx = ratings.findIndex(
        x => x.type === type && x.rater === userId,
      );
      if (rIdx !== -1) ratings.splice(rIdx, 1);
      video.videoRatings = ratings;
    } else video.videoRatings = [...ratings, rating];

    vids[vidIndex] = video;
    const map = new Map(state.feedbackVideos.entries());
    map.set(seminarId, vids);
    state.feedbackVideos = map;
  },

  async rateVideo(
    { state, rootGetters, dispatch },
    { submissionId, type }: RateVideoAction,
  ) {
    try {
      const rating = (await api.post("/api/Submissions/Rate", {
        submissionId,
        type,
      })) as Rating | null;

      if (!state.selected) return;
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;

      if (!newSelected.submissions?.length || !state.seminars.length) return;

      const subIdx =
        newSelected.submissions?.findIndex(x => x.id === submissionId) || -1;
      if (subIdx === -1) return;

      const ratings = ((newSelected.submissions[subIdx] as any).videoRatings ||
        []) as Rating[];
      const userId = rootGetters["profile/id"] as string;

      if (!rating) {
        const rIdx = ratings.findIndex(
          x => x.type === type && x.rater === userId,
        );
        if (rIdx !== -1) ratings.splice(rIdx, 1);
        (newSelected.submissions[subIdx] as any).videoRatings = ratings;
      } else
        (newSelected.submissions[subIdx] as any).videoRatings = [
          ...ratings,
          rating,
        ];

      // update current
      state.selected = newSelected;

      // update array
      updateArray(state);
    } catch (error) {
      dispatch("displaySnackbar", error, { root: true });
    }
  },

  async removeSubmission({ state }, id: number) {
    try {
      await api.delete(`/api/Submissions/${id}`);
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;

      const newSubs = newSelected.submissions?.filter(x => x.id !== id) || [];
      newSelected.submissions = newSubs;

      // update state
      state.selected = JSON.parse(JSON.stringify(newSelected));
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
  },

  async getSubmissions(
    { state, dispatch },
    { seminarId, elementId }: GetSubsRequest,
  ) {
    try {
      const end = `/api/Seminar/Submissions/${seminarId}/${elementId}`;
      const subs = (await api.get(end)) as Submission[];

      const updated = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      if (!updated) return;
      const oldSubs = updated.submissions || [];
      updated.submissions = [
        ...oldSubs.filter(x => x.elementId !== elementId),
        ...subs,
      ];

      if (updated.elements?.length) {
        const idx = updated.elements.findIndex(x => x.id === elementId);
        if (idx !== undefined && idx !== -1)
          updated.elements[idx].submissions = subs;
      }

      // todo check if element is complete

      // update
      state.selected = JSON.parse(JSON.stringify(updated));
      updateArray(state);
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
  },

  async reorder({ state, dispatch }, order: SeminarOrder) {
    try {
      // get seminar + make copy
      const idx = state.seminars.findIndex(x => x.id === order.seminarId);
      if (idx === -1) return;
      const seminar = JSON.parse(JSON.stringify(state.seminars[idx]));

      // bring blocks in order
      const blocks = blocksInOrder(seminar, order);
      seminar.blocks = blocks;
      seminar.order = JSON.parse(JSON.stringify(order));
      seminar.orderChanged = true;

      // update seminar
      state.seminars[idx] = seminar;
      if (state.selected?.id === order.seminarId) state.selected = seminar;
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
  },

  revertOrder({ state }) {
    const idx = state.seminars.findIndex(x => x.id === state.selected?.id);
    if (idx === -1) return;

    // todo this method for all seminar copies
    const seminar = JSON.parse(JSON.stringify(state.seminars[idx]));
    seminar.order = JSON.parse(JSON.stringify(seminar.initialOrder));
    seminar.orderChanged = false;

    state.seminars[idx] = JSON.parse(JSON.stringify(seminar));
    state.selected = JSON.parse(JSON.stringify(seminar));
  },

  async updateOrder({ state, dispatch }) {
    const order = state.selected?.order;
    if (!order)
      return dispatch(
        "displaySnackbar",
        "Order of currently selected seminar is null",
        { root: true },
      );

    try {
      await api.patch("/api/Seminar/Reorder", { ...order });

      // get seminar + make copy
      const idx = state.seminars.findIndex(x => x.id === order.seminarId);
      if (idx === -1) return;
      const seminar = JSON.parse(JSON.stringify(state.seminars[idx]));

      // bring blocks in order and update order + initial
      const blocks = blocksInOrder(seminar, order);
      seminar.blocks = blocks;
      seminar.order = JSON.parse(JSON.stringify(order));
      seminar.initialOrder = JSON.parse(JSON.stringify(order));
      seminar.orderChanged = false;

      // update seminar
      state.seminars[idx] = seminar;
      if (state.selected?.id === order.seminarId) state.selected = seminar;
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
  },

  async clearAllSubmissions({ state, dispatch }, id: number) {
    try {
      await api.delete(`/api/Seminar/Reset/${id}`);
      const idx = state.seminars.findIndex(x => x.id === id);
      if (idx === -1) return;

      // update array
      const newSeminar = JSON.parse(
        JSON.stringify(state.seminars[idx]),
      ) as Seminar;
      newSeminar.allSubmissions = [];
      newSeminar.submissions = [];
      const newUsers = newSeminar.users?.map(u => ({ ...u, progress: 0 }));
      newSeminar.users = newUsers;
      state.seminars[idx] = newSeminar;

      // update selected if same
      if (state.selected?.id === id) state.selected = newSeminar;

      // message
      const m = "All submissions cleared.";
      dispatch("displaySnackbar", m, { root: true });
    } catch (error) {
      // todo translate
      const m = "Could not clear all submissions.";
      dispatch("displaySnackbar", m, { root: true });
    }
  },

  async clearUserSubmissions(
    { state, rootGetters, dispatch },
    { seminarId, userId }: SemIdUId,
  ) {
    try {
      await api.patch("/api/Seminar/ResetOne", { seminarId, userId });
      const idx = state.seminars.findIndex(x => x.id === seminarId);
      if (idx === -1) return;

      // copy seminar
      const newSeminar = JSON.parse(
        JSON.stringify(state.seminars[idx]),
      ) as Seminar;

      // remove subs
      newSeminar.allSubmissions = newSeminar.allSubmissions?.filter(
        x => x.userId !== userId,
      );
      if (userId === rootGetters["profile/id"]) newSeminar.submissions = [];

      let username = "<<unknown>>";
      const userIdx = newSeminar.users?.findIndex(x => x.id === userId) || -1;
      if (newSeminar.users && userIdx !== -1) {
        username = newSeminar.users[userIdx].username;
        newSeminar.users[userIdx].progress = 0;
      }

      // update
      state.seminars[idx] = newSeminar;
      if (state.selected?.id === seminarId) state.selected = newSeminar;

      // message
      const m = `Cleared all submissions for ${username}.`;
      dispatch("displaySnackbar", m, { root: true });
    } catch (error) {
      // todo translate
      const m = "Could not clear submissions for this user.";
      dispatch("displaySnackbar", m, { root: true });
    }
  },

  // Remove one user
  async removeUser({ state, dispatch }, { seminarId, userId }: SemIdUId) {
    try {
      const end = `/api/Seminar/RemoveUser/${seminarId}/${userId}`;
      await api.delete(end);

      // get seminar
      const idx = state.seminars.findIndex(x => x.id === seminarId);
      if (idx === -1) return;
      const newSeminar = JSON.parse(
        JSON.stringify(state.seminars[idx]),
      ) as Seminar;

      // remove user + subs
      newSeminar.submissions = newSeminar.submissions?.filter(
        x => x.userId !== userId,
      );
      newSeminar.users = newSeminar.users?.filter(x => x.id !== userId);

      // commit changes
      state.seminars[idx] = newSeminar;
      if (state.selected?.id === seminarId) state.selected = newSeminar;
    } catch (error) {
      console.log(error);
      const m = "Could not remove this user";
      dispatch("displaySnackbar", m, { root: true });
    }
  },

  async removeAllUsers({ state, dispatch }, id: number) {
    try {
      const end = `/api/Seminar/RemoveUsers/${id}`;
      await api.delete(end);

      // get seminar
      const idx = state.seminars.findIndex(x => x.id === id);
      if (idx === -1) return;
      const newSeminar = JSON.parse(JSON.stringify(state.seminars[idx]));

      // remove user + subs
      newSeminar.submissions = [];
      newSeminar.users = [];

      // commit changes
      state.seminars[idx] = newSeminar;
      if (state.selected?.id === id) state.selected = newSeminar;
    } catch (error) {
      console.log(error);
      const m = "Could not remove this user";
      dispatch("displaySnackbar", m, { root: true });
    }
  },

  refreshGoalElement(
    { state, rootGetters, dispatch },
    {
      element,
      makeCall = false,
    }: {
      element: SeminarElement;
      makeCall: boolean;
    },
  ) {
    if (element.type !== "goal") return false;

    // local update
    // get goal
    const goal = (rootGetters["goals/getAssignedGoals"] as any[]).find(
      x => x.id === element.goalId,
    );
    // get that goal's progress
    const progress = goal?.Progress || goal?.progress || 0;

    // update if new non-null value
    if (progress !== element.progress && progress > 0) {
      element.progress = progress;

      // update selected element
      const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;
      newSelected.elements = JSON.parse(
        JSON.stringify(newSelected.elements),
      ) as SeminarElement[];
      const idx = newSelected.elements.findIndex(x => x.id === element.id);
      newSelected.elements[idx] = JSON.parse(JSON.stringify(element));

      // update seminar in array
      updateArray(state);

      // return false (no call to api)
      return false;
    }

    if (!makeCall) return false;

    // api update
    dispatch("goals/getGoals", null, { root: true });
    // return true (call to api made)
    return true;
  },

  setAssignedToGoal({ state }, element: SeminarElement) {
    if (element.type !== "goal") return;
    element.isAssignedToUser = true;

    // update element
    const newSelected = JSON.parse(JSON.stringify(state.selected)) as Seminar;
    newSelected.elements = JSON.parse(
      JSON.stringify(newSelected.elements),
    ) as SeminarElement[];
    const idx = newSelected.elements.findIndex(x => x.id === element.id);
    newSelected.elements[idx] = JSON.parse(JSON.stringify(element));
    state.selected = newSelected;

    // update array
    updateArray(state);
  },
};
