import { SelectItem } from "../interfaces";

class MeetingTypes {
  public static Custom = "custom_meeting";
  public static Blizz = "blizz_meeting";

  public static getValues(): SelectItem[] {
    const data = [
      { text: "Custom Meeting", value: this.Custom, disabled: false },
      { text: "Blizz Meeting", value: this.Blizz, disabled: false },
    ];
    return data;
  }
}

export default MeetingTypes;
