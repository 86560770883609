class Product {
  id: string;
  name: string;
  metadata: any;
  active: boolean;
  images: string[];
  monthlyPrice: string;
  yearlyPrice: string;
  unit_amount: number;
  unitAmountMonthly: number;
  unitAmountYearly: number;
  description: string;

  constructor(data: any) {
    const {
      monthlyPrice,
      yearlyPrice,
      unitAmount,
      id,
      name,
      metadata,
      active,
      description,
      images,
      unitAmountMonthly,
      unitAmountYearly,
    } = data;
    this.id = id;
    this.name = name;
    this.images = images;
    this.active = active;
    this.metadata = metadata;
    this.monthlyPrice = monthlyPrice;
    this.yearlyPrice = yearlyPrice;
    this.unit_amount = unitAmount;
    this.description = description;
    this.unitAmountMonthly = unitAmountMonthly;
    this.unitAmountYearly = unitAmountYearly;
  }
}
export default Product;
