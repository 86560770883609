
























import Vue from "vue";
import { Settings } from "luxon";
import { Component } from "vue-property-decorator";
import LocalStorage from "@/core/utils/LocalStorage";

@Component
export default class LanguageSelect extends Vue {
  langs: any = {
    en: { locale: "en", name: "English" },
    de: { locale: "de", name: "Deutsch" },
  };

  locale = "de";
  get allLocales() {
    return this.$i18n.availableLocales;
  }
  get currentLang() {
    return this.langs[this.locale];
  }
  setLocale(value: string) {
    this.$i18n.locale = value;
    LocalStorage.setLocale(value);
    this.locale = value;
    Settings.defaultLocale = value;
  }

  created() {
    const locale = LocalStorage.getLocale();
    this.setLocale(locale);
  }
}
