import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { Notification } from "@/core/models";
import { RootState } from "../root";
import { NotificationsState } from ".";

const actions: ActionTree<NotificationsState, RootState> = {
  async loadNotifications({ state }) {
    state.retrieved = false;
    try {
      const res = (await api.get("/api/Notifications")) as any;
      state.notifications = res.notifications.map(
        (x: any) => new Notification(x),
      );
      state.lastChecked = new Date(res.lastChecked);
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
  },

  async checkNotifications({ state }) {
    console.log("CHECK NOTIFICATIONS");
    try {
      await api.patch("/api/Notifications");
      state.lastChecked = new Date(Date.now());
    } catch (error) {
      console.log(error);
    }
  },
};

export default actions;
