import { MutationTree } from "vuex";

import { RootState } from "../root";

const snackTimeout = 10 * 1000; // 10s
const mutations: MutationTree<RootState> = {
  loading: state => (state.dataLoading = true),
  loaded: state => (state.dataLoading = false),
  appLoading: state => (state.appLoading = true),
  appLoaded: state => (state.appLoading = false),
  displaySnackbar(state, message = "") {
    if (!message) return;
    state.showSnackbar = true;
    state.snackbarText = message;
    setTimeout(() => {
      state.showSnackbar = false;
    }, snackTimeout);
  },
};

export default mutations;
