import Condition from "./Condition";
import ConditionType from "./ConditionType";
import User from "../User";
import AssignedUser from "./AssignedUser";

class Goal {
  public ID: number;
  public Creator: User;
  public CreatedAt: Date;
  public Target: number;
  public Conditions: Condition[];
  public AssingedUsers: AssignedUser[];

  public Title: string;

  constructor(
    id: number,
    creator: User,
    createdAt: Date,
    target: number,
    conditions: any[],
    users: AssignedUser[],
  ) {
    this.ID = id;
    this.Creator = creator;
    this.CreatedAt = createdAt;
    this.Target = target;
    this.Conditions = conditions;
    this.AssingedUsers = users;

    this.Title = `Complete ${this.Target} sessions`;
  }

  getDueDate(): Date | null {
    const dueDate = this.Conditions.find(
      c => c.type.toLowerCase() === ConditionType.DueDate,
    );
    if (dueDate) return new Date(dueDate.value);
    else return null;
  }

  static parse(data: any): Goal {
    const creator = User.parse(data.creator);
    const createdAt = new Date(data.createdAt);
    const users = (data.assignedUsers as any[]).map(u => AssignedUser.parse(u));
    return new Goal(
      data.id,
      creator,
      createdAt,
      data.target,
      data.conditions,
      users,
    );
  }
}

export default Goal;
