import { MutationTree } from "vuex";

import { GroupsState } from ".";

const mutations: MutationTree<GroupsState> = {
  set(state, payload) {
    if (state.retrievedGroups) return;
    state.groups = payload;
    state.retrievedGroups = true;
  },
};

export default mutations;
