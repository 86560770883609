export enum Permission {
  Seminar = "seminar",
  UploadAudio = "upload:audio",
  UploadVideo = "upload:video",
  SessionTraining = "session_training",
  CanShareWithAllUsers = "can_share_with_all",
  RelaxedLiveTranscription = "live_transcription:relaxed",
  FastLiveTranscription = "live_transcription:fast",
  RelaxedAnalysis = "analysis:relaxed",
  FastAnalysis = "analysis:fast",
  RelaxedAiFeedback = "ai_feedback:relaxed",
  FastAiFeedback = "ai_feedback:fast",
  LimitedPublicGroup = "public_group:limited",
  FullPublicGroup = "public_group:full",
  B2BGroup = "b2b_group",
  BasicTutorial = "tutorial:basic",
  AdvancedTutorial = "tutorial:advanced",
  ExpertTutorial = "tutorial:expert",
  CoachReviewBooking = "coach:review_booking",
  Coach1On1Booking = "coach:1on1_booking",
  Challenge = "challenge",
  Course = "course",
  VrMode = "vr_mode",
  Sso = "sso",
  CustomIdp = "custom_idp",
  ExternalApi = "external_api",
  Onboarding = "onboarding",
  TechSupport = "tech_support",
  DevSupport = "dev_support",
  Sla = "sla",
  RedundantService = "redundant_service",
}

export default Permission;
