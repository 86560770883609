import { GetterTree } from "vuex";

import { GoalsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<GoalsState, RootState> = {
  loading: state => state.loading,
  retrieved: state => state.retrieved,
  getAssignedGoals: state =>
    state.assignedGoals.sort((a, b) => +b.CreatedAt - +a.CreatedAt),
  getCreatedGoals: state =>
    state.createdGoals.sort((a, b) => +b.CreatedAt - +a.CreatedAt),

  addGoalLoading: state => state.addGoalLoading,
  editedGoalID: state => state.editedGoalId,
  editGoalLoading: state => state.editGoalLoading,
  removedGoalID: state => state.removedGoalId,
  removeGoalLoading: state => state.removeGoalLoading,
};

export default getters;
