import FileObject from "./FileObject";

class Article {
  public ID: number;
  public Title: string;
  public Body: string;
  public PostedAt: Date;
  public BannerImage: FileObject | null;
  public Files: FileObject[];

  constructor(
    id: number,
    title: string,
    body: string,
    postedAt: Date,
    bannerImage: FileObject | null,
    files: FileObject[],
  ) {
    this.ID = id;
    this.Title = title;
    this.Body = body;
    this.PostedAt = postedAt;
    this.BannerImage = bannerImage;
    this.Files = files;
  }
  static parseArticle(data: any): Article {
    const postedAt = new Date(Date.parse(data.createdAt));
    const bannerImage = data.bannerImage
      ? FileObject.parseFile(data.bannerImage)
      : null;
    const files = data.files
      ? (data.files as any[]).map(f => FileObject.parseFile(f)!)
      : [];
    return new Article(
      data.id,
      data.title,
      data.body,
      postedAt,
      bannerImage,
      files,
    );
  }
}

export default Article;
