class MetaSettings {
  Attributes: string[];
  NumOfSessions: number;

  constructor(data: any) {
    const { attributes, value } = data;
    this.Attributes = attributes;
    this.NumOfSessions = value;
  }
}

export default MetaSettings;
