import { Module } from "vuex";

import { actions } from "./actions";
import { getters } from "./getters";
import { RootState } from "../root";
import { Invitation } from "@/core/models";

export interface InvitationsState {
  retrieved: boolean;
  loading: boolean;
  sending: boolean;
  invitations: Invitation[];
}

export const state: InvitationsState = {
  retrieved: false,
  loading: false,
  sending: false,
  invitations: [],
};

export const invitations: Module<InvitationsState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
};
