import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import { RootState } from "../root";
import mutations from "./mutations";
import { Notification } from "@/core/models";

export interface NotificationsState {
  notifications: Notification[];
  retrieved: boolean;
  lastChecked: Date;
}

export const state: NotificationsState = {
  notifications: [],
  retrieved: false,
  lastChecked: new Date("2021-05-01T10:00:00"),
};

const namespaced: boolean = true;

export const notifications: Module<NotificationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
