























































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { convertDuration } from "@/views/recorder/components/utils";

@Component({
  methods: { convertDuration },
})
export default class AppBar extends Vue {
  @Prop({ default: () => 0 }) analysisFast!: string | boolean;
  @Prop({ default: () => 0 }) analysisRelaxed!: string | boolean;
  @Prop({ default: () => 0 }) aiFeedbackFast!: string | boolean;
  @Prop({ default: () => 0 }) aiFeedbackRelaxed!: string | boolean;
  @Prop({ default: () => 0 }) liveTranscriptionFast!: string | boolean;
  @Prop({ default: () => 0 }) liveTranscriptionRelaxed!: string | boolean;

  get hasAnalysisQuota() {
    return (
      parseInt(this.analysisFast.toString()) === 0 &&
      parseInt(this.analysisRelaxed.toString()) === 0
    );
  }
  get hasAiFeedbackQuota() {
    return (
      parseInt(this.aiFeedbackFast.toString()) === 0 &&
      parseInt(this.aiFeedbackRelaxed.toString()) === 0
    );
  }
  get hasLiveTranscriptionQuota() {
    return (
      parseInt(this.liveTranscriptionFast.toString()) === 0 &&
      parseInt(this.liveTranscriptionRelaxed.toString()) === 0
    );
  }
}
