import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
// import mutations from "./mutations";
import { RootState } from "../root";
import { SupportInfo } from "@/core/models/support-info";

export interface SupportState {
  freshchatActive: boolean;
  supportInfo: SupportInfo;
}

export const state: SupportState = {
  freshchatActive: false,
  supportInfo: { name: "Support", email: "", phone: "", times: "" },
};

const namespaced = true;
export const tenants: Module<SupportState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  // mutations,
};
