import {
  User,
  Article,
  Goal,
  Company,
  Group,
  Presentation,
  Meeting,
  AssignedGoal,
  //Subscription,
} from "./";
import { Seminar } from "./interfaces";
import { Session, MetaAnalysis, MetaSettings } from "./sessions";
import BaselineAnalysis from "./sessions/BaselineAnalysis";

class UserData {
  user: User;
  company?: Company;
  goals?: { assignedGoals: AssignedGoal[]; createdGoals: Goal[] } | null;
  presentations?: Presentation[];
  meetings?: Meeting[];
  articles?: Article[];
  groups?: Group[];
  sessions?: Session[];
  metaAnalysis?: MetaAnalysis[];
  metaSettings?: MetaSettings;
  seminars?: Seminar[];
  baseline?: BaselineAnalysis;
  active: boolean;
  lastCheckedNotifications?: Date;
  //subscription?: Subscription | null; // Add subscription field

  constructor(data: any) {
    const {
      user,
      company,
      goals,
      presentations,
      meetings,
      articles,
      groups,
      sessions,
      metaAnalysis,
      initialSession,
      active,
      seminars,
      lastCheckedNotifications,
      //subscription, // Add subscription to the constructor
    } = data;
    this.user = User.parse(user);
    if (company) this.company = new Company(company);
    this.active = active;
    this.seminars = seminars;
    //this.subscription = subscription || null; // Initialize subscription

    if (!active) return;

    if (goals) {
      const { assignedGoals, createdGoals } = goals;
      this.goals = {
        assignedGoals: (assignedGoals as any[]).map(ag =>
          AssignedGoal.parse(ag),
        ),
        createdGoals: (createdGoals as any[]).map(cg => Goal.parse(cg)),
      };
    } else this.goals = { assignedGoals: [], createdGoals: [] };

    if (presentations)
      this.presentations = (presentations as any[]).map(p =>
        Presentation.parse(p),
      );
    else this.presentations = [];

    if (meetings)
      this.meetings = (meetings as any[]).map(m => Meeting.parse(m));
    else this.meetings = [];

    if (articles)
      this.articles = (articles as any[]).map(a => Article.parseArticle(a));
    else this.articles = [];

    if (groups) this.groups = (groups as any[]).map(g => Group.parseGroup(g));
    else this.groups = [];

    if (sessions)
      this.sessions = (sessions as any[]).map(s =>
        Session.parseSingleSession(s),
      );
    else this.sessions = [];

    if (metaAnalysis && metaAnalysis.data)
      this.metaAnalysis = metaAnalysis.data.map(
        (d: any) => new MetaAnalysis(d),
      );
    if (metaAnalysis && metaAnalysis.settings)
      this.metaSettings = new MetaSettings(metaAnalysis.settings);

    this.baseline = new BaselineAnalysis(initialSession);

    this.lastCheckedNotifications = lastCheckedNotifications
      ? lastCheckedNotifications
      : new Date("2021-05-01T10:00:00");
  }
}
export default UserData;
