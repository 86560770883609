import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "../root";
import { ManagedSessionsState, SharingUser } from ".";
import { Session, SharedSessions } from "@/core/models";

const actions: ActionTree<ManagedSessionsState, RootState> = {
  clear({ state }) {
    state.retrieved = false;
    state.sharingUsers = [];
    state.active = false;
    state.selected = null;
    state.sharedSessions = [];
  },

  async setUser({ state }, e: string) {
    const user = state.sharedSessions.map(x => x.user).find(x => x.email == e);
    if (!user) {
      state.sessionsLoading = true;
      try {
        const end = `/api/Sessions/GetSharedSessions/${e}`;
        const res = (await api.get(end)) as SharedSessions;
        res.sessions = res.sessions.map(x => Session.parseSingleSession(x));
        state.sharedSessions.push(res);
        state.active = true;
        state.selected = res.user.email;
      } catch (error) {
        console.log(error);
      }
      state.sessionsLoading = false;
    } else state.selected = user.email;
  },

  async getUsers({ state }) {
    state.usersLoading = true;
    try {
      const end = "/api/Sessions/GetUsersSharingSessions";
      const arr = (await api.get(end)) as SharingUser[];
      state.sharingUsers = [...new Set(arr)];
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.usersLoading = false;
  },

  async getShared({ state }, emails: string[]) {
    state.sessionsLoading = true;
    try {
      const end = "/api/Sessions/GetSharedSessions";
      const data = { emails };
      const res = (await api.post(end, data)) as SharedSessions[];
      state.sharedSessions = res.map(x => {
        x.sessions = Session.parseSessions(x.sessions);
        return x;
      });
      state.active = true;
      state.selected = state.sharedSessions[0].user.email;
    } catch (error) {
      console.log(error);
    }
    state.sessionsLoading = false;
  },

  async getUserShared({ state }, email: string) {
    if (state.sharedSessions.some(x => x.user.email === email)) {
      console.log("User sessions already retrieved");
      return;
    }

    state.sessionsLoading = true;
    try {
      const end = `/api/Sessions/GetSharedSessions/${email}`;
      const res = (await api.get(end)) as SharedSessions;
      res.sessions = Session.parseSessions(res.sessions);
      state.sharedSessions.push(res);
      state.active = true;
    } catch (error) {
      console.log(error);
    }
    state.sessionsLoading = false;
  },

  async getSingleShared(
    { state, dispatch },
    { email, id }: { email: string; id: number },
  ) {
    const user = state.sharedSessions.find(x => x.user.email === email);
    if (!user) {
      dispatch("getUserShared", email);
      return;
    }

    if (user.sessions.some(x => x.ID === id)) {
      console.log("Session already retrieved");
      return;
    }

    state.sessionsLoading = true;
    try {
      const end = `/api/Sessions/GetSharedSessions/${email}/${id}`;
      const res = (await api.get(end)) as any;
      const session = Session.parseSingleSession(res);
      user.sessions.push(session);
      state.active = true;
    } catch (error) {
      console.log(error);
    }
    state.sessionsLoading = false;
  },
};

export default actions;
