import { User } from "@/core/models";

class Meeting {
  public ID: string;
  public Type: string;
  public Subject: string;
  public Creator: User;
  public CreatedAt: Date;
  public StartDate: Date;
  public EndDate: Date;
  public MeetingLink: string;
  public InvitedUsers: string[];

  constructor(
    id: string,
    type: string,
    subject: string,
    creator: User,
    crAt: Date,
    start: Date,
    end: Date,
    link: string,
    users: string[],
  ) {
    this.ID = id;
    this.Type = type;
    this.Subject = subject;
    this.Creator = creator;
    this.CreatedAt = crAt;
    this.StartDate = start;
    this.EndDate = end;
    this.MeetingLink = link;
    this.InvitedUsers = users;
  }

  public static parse(data: any): Meeting {
    const crAt = new Date(data.createdAt);
    const creator = User.parse(data.creator);
    const start = new Date(data.startDate);
    const end = new Date(data.startDate);
    return new Meeting(
      data.id,
      data.type,
      data.subject,
      creator,
      crAt,
      start,
      end,
      data.meetingLink,
      data.invitedUsers,
    );
  }
}
export default Meeting;
