import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import { RootState } from "../root";
import { Meeting } from "@/core/models";

export interface MeetingsState {
  loading: boolean;
  retrieved: boolean;
  meetings: Meeting[];
  addMeetingLoading: boolean;
  editingMeeting: boolean;
  editedMeetingId: number;
  removeMeetingLoading: boolean;
  removedMeetingId: number;
}

export const state: MeetingsState = {
  loading: false,
  retrieved: false,
  meetings: [],
  addMeetingLoading: false,
  editingMeeting: false,
  editedMeetingId: 0,
  removeMeetingLoading: false,
  removedMeetingId: 0,
};

const namespaced: boolean = true;
export const meetings: Module<MeetingsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
};
