import store from "@/core/store";
import { RoleClaims, UserClaims } from "@/core/models";
import Storage from "@/core/utils/LocalStorage";

export const hasPermissions = (permittedClaims: string[]) => {
  const permissions = store.getters["profile/permissions"] as
    | RoleClaims[]
    | undefined;
  const userClaims = store.getters["profile/userClaims"] as
    | UserClaims[]
    | undefined;
  return (
    permissions?.some(x => permittedClaims.includes(x.type)) ||
    userClaims?.some(x => permittedClaims.includes(x.type))
  );
};

export const hasRole = (permittedRoles: string[]) => {
  const roles = Storage.getUserRole();
  return roles?.some(x => permittedRoles.includes(x.name));
};
