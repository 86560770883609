import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import { Goal, AssignedGoal } from "@/core/models";

export interface GoalsState {
  loading: boolean;
  retrieved: boolean;
  assignedGoals: AssignedGoal[];
  createdGoals: Goal[];
  addGoalLoading: boolean;
  editGoalLoading: boolean;
  editedGoalId: number;
  removeGoalLoading: boolean;
  removedGoalId: number;
}

export const state: GoalsState = {
  loading: false,
  retrieved: false,
  assignedGoals: [],
  createdGoals: [],
  addGoalLoading: false,
  editGoalLoading: false,
  editedGoalId: 0,
  removeGoalLoading: false,
  removedGoalId: 0,
};

const namespaced: boolean = true;

export const goals: Module<GoalsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
