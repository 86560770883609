import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "../root";
import { Product } from "@/core/models";
import { ProductsState } from "./index";

const actions: ActionTree<ProductsState, RootState> = {
  async get({ state, commit }) {
    state.getting = true;
    state.retrieved = false;
    try {
      const end = "/api/Products/GetProducts";
      const products = (await api.get(end)) as any[];
      state.products = products.map(p => new Product(p));
      state.retrieved = true;
    } catch (error) {
      //todo: translate
      const fallback = "Could not get products";
      const msg = error.message || fallback;
      commit("displaySnackbar", msg, { root: true });
      console.log(error);
    }
    state.getting = false;
  },

  async create({ state, commit }, payload) {
    state.loading = true;
    try {
      const end = "/api/Products/CreateProduct";
      const created = await api.post(end, payload);
      state.products.push(new Product(created));
      //todo: translate
      const msg = "Successfully created product";
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
      //todo: translate
      const fallback = "Could not update product";
      const msg = error.message || fallback;
      commit("displaySnackbar", msg, { root: true });
    }
    state.loading = false;
  },

  async update({ state, commit }, payload) {
    state.loading = true;
    try {
      const end = "/api/Products/UpdateProduct";
      await api.patch(end, payload);
      const idx = state.products.findIndex(x => x.id === payload.id);
      state.products[idx].metadata = payload.metadata;
      //todo: translate
      const msg = "Successfully updated price";
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
      //todo: translate
      const fallback = "Could not update product";
      const msg = error.message || fallback;
      commit("displaySnackbar", msg, { root: true });
    }
    state.loading = false;
  },
};

export default actions;
