import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","auto":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"px-0",attrs:{"text":"","large":"","width":"50px","min-width":"50px"}},on),[_c(VAvatar,{attrs:{"tile":"","size":"24"}},[_c('span',[_vm._v(_vm._s(_vm.locale === "en" ? "EN" : "DE"))])])],1)]}}])},[_c(VList,[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}},_vm._l((_vm.langs),function(lang){return _c(VListItem,{key:lang.locale,attrs:{"value":lang.locale},on:{"click":function($event){return _vm.setLocale(lang.locale)}}},[_c(VListItemContent,[_vm._v(_vm._s(lang.name))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }