
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class CurrentUser extends Vue {
  @Prop({ default: () => false }) isMobile!: boolean;
  @Getter("profile/getUserEmail") email!: string;
  @Getter("profile/getUserName") name!: string;

  get firstLetter(): string {
    return this.name.charAt(0);
  }
}
