import ChartEntry from "./ChartEntry";

class MovementAnalysis {
  public HandMovement: ChartEntry[];
  public HeadMovement: ChartEntry[];
  public AverageHandMovement: number;
  public AverageHeadMovement: number;
  public TotalHandMovement: number;
  public TotalHeadMovement: number;

  constructor(
    handMovement: ChartEntry[],
    headMovement: ChartEntry[],
    avgHand: number,
    avgHead: number,
    totalHand: number,
    totalHead: number,
  ) {
    this.HandMovement = handMovement;
    this.HeadMovement = headMovement;
    this.AverageHandMovement = avgHand;
    this.AverageHeadMovement = avgHead;
    this.TotalHandMovement = totalHand;
    this.TotalHeadMovement = totalHead;
  }

  public static parseMovementAnalysis(analysis: any) {
    if (!analysis) return this.blank();

    const hand = this.parseEntries(analysis.handMovement);
    const head = this.parseEntries(analysis.headMovement);
    return new MovementAnalysis(
      hand,
      head,
      analysis.averageHandMovement,
      analysis.averageHeadMovement,
      analysis.totalHandMovement,
      analysis.totalHeadMovement,
    );
  }

  public static blank() {
    return new MovementAnalysis([], [], 0, 0, 0, 0);
  }

  private static parseEntries(entries: any[]) {
    return entries.map(entry => {
      const time = entry.timeStamp;
      const value = Math.round(entry.value * 100) / 100;
      return new ChartEntry(time, value);
    });
  }
}

export default MovementAnalysis;
