
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { SupportInfo } from "@/core/models/support-info";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({})
export default class SupportFAB extends Vue {
  @Getter("tenants/supportInfo") supportInfo!: SupportInfo;
  @Action("tenants/getSupportInfo") getSupportInfo!: (
    locale: string,
  ) => Promise<void>;

  supportInfoVisible = false;

  async showSupportInfo() {
    const locale = LocalStorage.getLocale() ?? "de";
    await this.getSupportInfo(locale);
    this.supportInfoVisible = true;
  }

  hidingSupportInfo() {
    this.supportInfoVisible = false;
  }

  get emailLink() {
    return "mailto:" + this.supportInfo.email;
  }

  get phoneLink() {
    return "tel:" + this.supportInfo.phone;
  }
}
