import { GetterTree } from "vuex";

import { ProductsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<ProductsState, RootState> = {
  products: state => state.products,
  getting: state => state.getting,
  retrieved: state => state.retrieved,
  loading: state => state.loading,
};

export default getters;
