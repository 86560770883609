












































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

import BrowserWarning from "@/components/layout/components/BrowserWarning.vue";
import CurrentUser from "@/components/layout/components/CurrentUser.vue";
import LanguageSelect from "@/components/layout/components/LanguageSelect.vue";
import Notifications from "@/components/layout/components/Notifications.vue";
import ProfileDropdown from "@/components/layout/components/ProfileDropdown.vue";
import QuotaBar from "@/components/layout/components/QuotaBar.vue";
import SeminarSelect from "@/components/layout/components/SeminarSelect.vue";
import ToolbarMenu from "@/components/layout/components/ToolbarMenu.vue";
import UpgradeButton from "@/components/layout/components/UpgradeButton.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { isDev, isLocal } from "@/settings";
import { UserClaims } from "@/core/models";

@Component({
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
    isDev() {
      return isDev;
    },
    isLocal() {
      return isLocal;
    },
  },
  components: {
    BrowserWarning,
    CurrentUser,
    LanguageSelect,
    Notifications,
    ProfileDropdown,
    QuotaBar,
    SeminarSelect,
    ToolbarMenu,
    UpgradeButton,
  },
})
export default class AppBar extends Vue {
  @Getter("theme") theme!: any;
  @Getter("appLoading") appLoading!: boolean;
  @Getter("profile/loading") profileLoading!: boolean;
  @Getter("profile/forgotEmailSent") forgotEmailSent!: boolean;
  @Getter("profile/confirmEmailSent") confirmEmailSent!: boolean;
  @Getter("profile/userEmailVerified") userEmailVerified!: boolean;
  @Action("profile/sendConfirmEmail") sendConfirmEmail!: Function;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;

  @Prop({ default: () => false }) isMobile!: boolean;

  analysisFast = this.analysisFastQuota || 0;
  analysisRelaxed = this.analysisRelaxedQuota || 0;
  aiFeedbackFast = this.aiFeedbackFastQuota || 0;
  aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
  liveTranscriptionFast = this.liveTranscriptionFastQuota || 0;
  liveTranscriptionRelaxed = this.liveTranscriptionRelaxedQuota || 0;

  showBanner: boolean = false;

  VRMode = LocalStorage.getVRMode();

  switchVRMode() {
    LocalStorage.setVRMode(!LocalStorage.getVRMode());
    this.$router.go(0);
  }

  get isChrome() {
    return navigator.userAgent.indexOf("Chrome") !== -1;
  }

  sending: boolean = false;
  sendEmail() {
    if (this.sending) return;
    this.sending = true;
    this.sendConfirmEmail();
  }

  get analysisFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get analysisRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1) return "∞";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  @Watch("analysisFastQuota")
  updateAnalysisFast() {
    this.analysisFast = this.analysisFastQuota || 0;
  }
  @Watch("analysisRelaxedQuota")
  updateAnalysisRelaxed() {
    this.analysisRelaxed = this.analysisRelaxedQuota || 0;
  }
  @Watch("aiFeedbackFastQuota")
  updateAiFeedbackFast() {
    this.aiFeedbackFast = this.aiFeedbackFastQuota || 0;
  }
  @Watch("aiFeedbackRelaxedQuota")
  updateAiFeedbackRelaxed() {
    this.aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
  }
  @Watch("liveTranscriptionFastQuota")
  updateLiveTranscriptionFast() {
    this.liveTranscriptionFast = this.liveTranscriptionFastQuota || 0;
  }
  @Watch("liveTranscriptionRelaxedQuota")
  updateLiveTranscriptionRelaxed() {
    this.liveTranscriptionRelaxed = this.liveTranscriptionRelaxedQuota || 0;
  }

  @Watch("$route", { immediate: true })
  urlChanged() {
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;
  }

  @Watch("profileLoading", { immediate: true })
  userLoaded() {
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;
  }

  mounted() {
    if (this.userEmailVerified) this.fetchPermissions(); // Do not try to fetch permissions, if a user is not logged in.

    this.analysisFast = this.analysisFastQuota || 0;
    this.analysisRelaxed = this.analysisRelaxedQuota || 0;
    this.aiFeedbackFast = this.aiFeedbackFastQuota || 0;
    this.aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
    this.liveTranscriptionFast = this.liveTranscriptionFastQuota || 0;
    this.liveTranscriptionRelaxed = this.liveTranscriptionRelaxedQuota || 0;
  }
}
