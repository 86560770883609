import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import { Company } from "@/core/models";

export interface CompanyState {
  company: Company | null;
  error: object | null;
  getting: boolean;
  creating: boolean;
  editing: boolean;
  upgrading: boolean;
  adding: boolean;
  retrieved: boolean;
}

export const state: CompanyState = {
  company: null,
  error: null,
  adding: false,
  editing: false,
  creating: false,
  upgrading: false,
  getting: false,
  retrieved: false,
};

export const company: Module<CompanyState, RootState> = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
