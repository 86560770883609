export enum Role {
  Admin = "admin",
  Owner = "owner",
  User = "user",
  SubscriberFree = "subscriber-free",
  SubscriberBasic = "subscriber-basic",
  SubscriberStandard = "subscriber-standard",
  SubscriberPremium = "subscriber-premium",
  SubscriberEnterprise = "subscriber-enterprise",
}

export default Role;

/*class Roles {
  public static Owner: string = "owner";
  public static Admin: string = "admin";
  public static User: string = "user";
  public static Moderator: string = "moderator";
  public static Coach: string = "coach";
  public static Student: string = "student";
  public static Teacher: string = "teacher";
  public static SubscriberFree: string = "subscriber-free";
  public static SubscriberBasic: string = "subscriber-basic";
  public static SubscriberStandard: string = "subscriber-standard";
  public static SubscriberPremium: string = "subscriber-premium";
  public static SubscriberEnterprise: string = "subscriber-enterprise";
}

export default Roles;
*/
