import { GetterTree } from "vuex";

import { RootState } from "../root";
import { ManagedSessionsState, SharingUser } from ".";

const getters: GetterTree<ManagedSessionsState, RootState> = {
  retrieved: state => state.retrieved,
  usersLoading: state => state.usersLoading,
  sessionsLoading: state => state.sessionsLoading,

  active: state => state.active,
  selected: state => state.selected,
  shared: state => state.sharedSessions,
  sharingUsers: state => state.sharingUsers,

  sessions: state =>
    state.sharedSessions.find(x => x.user.email === state.selected)?.sessions ||
    [],
  users: state => state.sharedSessions.map(x => x.user) as SharingUser[],
  selectedUser: state =>
    state.sharedSessions.map(x => x.user).find(x => x.email == state.selected),
};

export default getters;
