class Slide {
  Uri: string;
  Timestamp: number;

  constructor(uri: string, time: number) {
    this.Uri = uri;
    this.Timestamp = time;
  }

  static parse(data: any): Slide {
    const { uri, timestamp } = data;
    return new Slide(uri, timestamp);
  }
}

export default Slide;
