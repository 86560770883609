import store from "@/core/store";
import Storage from "@/core/utils/LocalStorage";
import { UserClaims } from "@/core/models";
import { RoleClaims } from "@/core/models/interfaces";
import { NavigationGuardNext, Route } from "vue-router";

type Next = NavigationGuardNext<Vue>;

// helpers
const isAuth = () => {
  const token = Storage.getAccessToken();
  return !!token;
};

const hasPermissions = (permittedClaims: string[]) => {
  const permissions = store.getters["profile/permissions"] as
    | RoleClaims[]
    | undefined;
  const userClaims = store.getters["profile/userClaims"] as
    | UserClaims[]
    | undefined;
  return (
    permissions?.some(x => permittedClaims.includes(x.type)) ||
    userClaims?.some(x => permittedClaims.includes(x.type))
  );
};

const hasRole = (permittedRoles: string[]) => {
  const roles = Storage.getUserRole();
  return roles?.some(x => permittedRoles.includes(x.name));
};

// Guards
const authGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else next();
};

const adminGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else if (!hasRole(["admin"])) next("/dashboard");
  else next();
};

const subscriberGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else if (
    !hasRole([
      "subscriber-free",
      "subscriber-basic",
      "subscriber-standard",
      "subscriber-premium",
      "subscriber-enterprise",
    ])
  )
    next("/dashboard");
  else next();
};

const liveTranscriptionGuard = (_to: Route, _from: Route, next: Next) => {
  if (
    !isAuth() ||
    !hasPermissions(["live_transcription:fast", "live_transcription:relaxed"])
  )
    next("/");
  else next();
};

const analysisGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasPermissions(["analysis:fast", "analysis:relaxed"]))
    next("/");
  else next();
};

const aiFeedbackGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasPermissions(["ai_feedback:fast", "ai_feedback:relaxed"]))
    next("/");
  else next();
};

const seminarGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasPermissions(["seminar:default"])) next("/");
  else next();
};

const challengeGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasPermissions(["challenge:default"])) next("/");
  else next();
};

const publicGroupGuard = (_to: Route, _from: Route, next: Next) => {
  if (
    !isAuth() ||
    !hasPermissions(["public_group:limited", "public_group:full"])
  )
    next("/");
  else next();
};

const b2bGroupGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasPermissions(["b2b_group:default"])) next("/");
  else next();
};

export {
  authGuard,
  adminGuard,
  subscriberGuard,
  liveTranscriptionGuard,
  analysisGuard,
  aiFeedbackGuard,
  seminarGuard,
  challengeGuard,
  publicGroupGuard,
  b2bGroupGuard,
};
