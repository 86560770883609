import { SentimentSentence } from "@/core/models/sessions";
import { LookupWord, WordSegment } from "./interfaces";

class TextAnalysis {
  public PositiveScore: number;
  public NegativeScore: number;
  public NeutralScore: number;
  public Sentences: any[];
  public Keyphrases: string[];
  public Keywords: string[];
  public WordsPerMinute: number;
  public Text: string;
  public LookupWords: LookupWord[];
  public Words: WordSegment[];

  constructor(
    positive: number,
    negative: number,
    neutral: number,
    sentences: any[],
    keyphrases: string[],
    keywords: string[],
    wpm: number,
    text: string,
    lWords: LookupWord[],
    words: WordSegment[],
  ) {
    this.PositiveScore = Math.round(positive * 100);
    this.NegativeScore = Math.round(negative * 100);
    this.NeutralScore = Math.round(neutral * 100);
    this.Sentences = sentences;
    this.Keyphrases = keyphrases;
    this.Keywords = keywords;
    this.WordsPerMinute = Math.round(wpm * 10) / 10;
    this.Text = text;
    this.LookupWords = lWords;
    this.Words = words;
  }

  public static parseTextAnalysis(analysis: any): TextAnalysis {
    if (!analysis) return this.blank();

    const sentences = (analysis.sentences as any[]).map(s =>
      SentimentSentence.parseSentence(s),
    );
    return new TextAnalysis(
      analysis.positiveScore,
      analysis.negativeScore,
      analysis.neutralScore,
      sentences,
      analysis.keyPhrases,
      analysis.keyWords,
      analysis.wordsPerMinute,
      analysis.text,
      analysis.lookupWords as LookupWord[],
      analysis.words as WordSegment[],
    );
  }

  public static blank(): TextAnalysis {
    return new TextAnalysis(0, 0, 0, [], [], [], 0, "", [], []);
  }
}

export default TextAnalysis;
