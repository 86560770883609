import { Module } from "vuex";

import actions from "./actions";
import getters from "./getters";
import { RootState } from "../root";

export interface AudioState {
  currentTime: number;
  progress: number;
  totalTime: number;
}

export const state: AudioState = {
  currentTime: 0,
  progress: 0,
  totalTime: 1,
};

export const audio: Module<AudioState, RootState> = {
  state,
  actions,
  getters,
  namespaced: true,
};
