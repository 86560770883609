import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "@/core/store/root";
import { HowTosState } from "@/core/store/howtos";
import { HowToVideo } from "@/core/models";

const actions: ActionTree<HowTosState, RootState> = {
  clearHowTos({ state }) {
    state.videos = [];
    state.retrieved = false;
  },

  async getHowTos({ state }) {
    state.loading = true;
    state.retrieved = false;
    try {
      const res = (await api.get("/api/Showcase/")) as HowToVideo[];
      console.log("API response:", res);
      state.videos = res.map(h => HowToVideo.parseHowToVideo(h));
      console.log("Videos state:", state.videos);
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async addVideoFromLink({ state }, data: any) {
    state.addVideoLoading = true;
    try {
      const res = await api.post("/api/Showcase/AddVideoFromURI", data);
      const video = HowToVideo.parseHowToVideo(res);
      state.videos.push(video);
    } catch (error) {
      console.log(error);
    }
    state.addVideoLoading = false;
  },

  async addVideoFromFile({ state }, data: FormData) {
    state.addVideoLoading = true;
    try {
      const res = await api.post("/api/Showcase/AddVideoFromFile", data, {
        headers: { ContentType: "multipart/form-data" },
      });
      const video = HowToVideo.parseHowToVideo(res);
      state.videos.push(video);
    } catch (error) {
      console.log(error);
    }
    state.addVideoLoading = false;
  },

  async editVideo({ state }, data: any) {
    state.editVideoLoading = true;
    state.editedVideoID = data.videoId;
    try {
      await api.put("/api/Showcase", data);

      const idx = state.videos.findIndex(h => h.ID == data.videoId);
      if (idx === -1) return;

      state.videos[idx].Title = data.newTitle;
      state.videos[idx].Description = data.newDescription;
    } catch (error) {
      console.log(error);
    }
    state.editVideoLoading = false;
  },

  async deleteVideo({ state }, data: any) {
    state.deleteVideoLoading = true;
    state.deletedVideoID = data.videoId;
    try {
      const endpoint = `/api/Showcase/${data.videoId}`;
      await api.delete(endpoint);
      const idx = state.videos.findIndex(h => h.ID == data.videoId);
      if (idx === -1) return;
      state.videos.splice(idx, 1);
    } catch (error) {
      console.log(error);
    }
    state.deleteVideoLoading = false;
  },
};

export default actions;
