// Compare as string with variations ("true", "t", "yes", "y", "1") and allow to return a default
const parseBoolean = (
  value: string | number | boolean | undefined,
  defaultValue?: boolean,
) => {
  if (value !== undefined)
    return ["true", "t", "yes", "y", "1"].some(
      acceptedValue => acceptedValue === value.toString().toLowerCase(),
    );

  return defaultValue !== undefined && defaultValue;
};

export { parseBoolean };
