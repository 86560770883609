import { ActionTree } from "vuex";
import { RootState } from "../root";
import { AiFeedback, Interval } from "@/core/models";
import { CurrentSessionState } from "./index";
import { Session } from "@/core/models/sessions";

const actions: ActionTree<CurrentSessionState, RootState> = {
  setSession({ state }, session: Session | null) {
    if (session === null) state.session = null;
    else state.session = JSON.parse(JSON.stringify(session));
  },
  setLimit({ state }, limit: number) {
    state.limit = limit;
  },
  setZoom({ state }, val: boolean) {
    state.zoom = val;
  },
  setZoomInterval({ state }, interval: Interval) {
    state.interval = interval;
  },
  updateFeedback({ state }, data: any) {
    console.log("updateFeedback", data);
    if (data.id && state.session && state.session.ID == data.id) {
      console.log("updating feedback");
      state.session.VideoFeedback = data.videoFeedback;
    }
  },
  updateAiFeedback({ state }, data: AiFeedback) {
    console.log("updateAiFeedback", data);
    if (state.session) {
      console.log("updating AI feedback");
      state.session.AiFeedback = data;
    }
  },
};

export default actions;
