import { GetterTree } from "vuex";

import { ContactState } from "@/core/store/contact/index";
import { RootState } from "../root";

const getters: GetterTree<ContactState, RootState> = {
  messageLoading: state => state.messageLoading,
  messageSent: state => state.messageSent,
};

export default getters;
