

































import Vue from "vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { Component } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  VRMode = LocalStorage.getVRMode();
}
