// Scaffold app
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";

// Custom styles
import "./styles.scss";
import "./variables.scss";

// Middleware
import router from "@/core/router";
import store from "@/core/store";
import i18n from "@/core/plugins/i18n";
import vuetify from "@/core/plugins/vuetify";
import { SessionHub, NotificationHub } from "@/core/plugins/signalrconnection";

// USE LOCAL COPY OF materialdesignicons TO AVOID VIOLATING DSGVO
import "@mdi/font/css/materialdesignicons.min.css";

// Quill
// @ts-ignore
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
Vue.use(VueQuillEditor);

// videojs
import "video.js/dist/video-js.min.css";
import "videojs-record/dist/css/videojs.record.css";

// App settings
import LocalStorage from "@/core/utils/LocalStorage";
import * as Settings from "@/settings";

// QuestionHub
Vue.use(SessionHub);
Vue.use(NotificationHub);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import VueSimpleMarkdown from "vue-simple-markdown";
import "vue-simple-markdown/dist/vue-simple-markdown.css";
Vue.use(VueSimpleMarkdown);

Vue.directive("hasPermission", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    const userHasPermission = store.getters["profile/hasPermission"];

    if (!userHasPermission(value)) el.style.display = "none";
  },
});

Vue.directive("hasRole", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    const userHasRole = store.getters["profile/hasRole"];

    if (!userHasRole(value)) el.style.display = "none";
  },
});

import VueFreshchat from "vue-freshchat";
Vue.use(VueFreshchat as any, {
  host: "https://wchat.eu.freshchat.com",
  appToken: "42065161-57a6-412a-8551-0327fb052824",
  config: {
    headerProperty: {
      hideChatButton: true,
    },
  },
});

import { DateTime } from "luxon";
Vue.filter("format", (v: any) => {
  let date;
  date = DateTime.fromJSDate(v);
  if (!date.isValid) date = DateTime.fromISO(v);
  return date.toLocaleString((DateTime.DATE_MED as any) as any);
});

Vue.filter("formatwithtime", (v: any) => {
  let date: DateTime | null = null;
  if (typeof v === "string") date = DateTime.fromISO(v);
  else date = DateTime.fromJSDate(v);

  // const addedHours = Number(date.toString().split("+")[1].split(":")[0]);
  // date = date.plus(Duration.fromMillis(addedHours * 60 * 60 * 1000));

  return date.toFormat("dd MMM yyyy, HH:mm");
});

Vue.filter("truncate", (v: string | undefined, maxChar: number = 300) => {
  if (!v) return "";
  if (v.length > maxChar) return v.substr(0, maxChar) + "...";
  return v;
});

const redactedSettings = JSON.parse(JSON.stringify(Settings));

console.debug("%cPREZP DEV CONSOLE", "font-size: 20px; color: #23b0e6");
Settings.isLocal && console.debug(LocalStorage.getAccessToken());
console.debug("%cPARSED SETTINGS:", "color: #23b0e6", redactedSettings);
console.debug("%cENV VARS:", "color: #23b0e6", process.env);
console.log("%cv1.2.5", "font-size: 40px");

import { Component } from "vue-property-decorator";
import { DirectiveBinding } from "vue/types/options";
Component.registerHooks(["beforeRouteLeave"]);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount("#app");
