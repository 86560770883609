import { TimedAverage, ChartEntry } from ".";

/**
 *
 */
class AudioAnalysis {
  /**
   * Represents a collection of chart entries.
   *
   * @typedef {Array<ChartEntry>} Pitch
   */
  public Pitch: ChartEntry[];
  /**
   * Represents the volume of a chart entry.
   *
   * @typedef {Object} Volume
   * @property {ChartEntry[]} data - An array of chart entries representing the volume.
   */
  public Volume: ChartEntry[];
  /**
   * Represents an array of ChartEntry objects that contain information about pauses in a chart.
   *
   * @typedef {Array<ChartEntry>} Pauses
   *
   * @typedef {Object} ChartEntry
   * @property {number} startTime - The start time of the pause in milliseconds.
   * @property {number} endTime - The end time of the pause in milliseconds.
   * @property {string} reason - The reason for the pause.
   */
  public Pauses: ChartEntry[];

  /**
   * Represents the average pitch value.
   *
   * @type {number}
   */
  public AveragePitch: number;
  /**
   * Represents the average volume.
   *
   * @type {number}
   */
  public AverageVolume: number;
  /**
   * Represents the average number of pauses.
   *
   * @typedef {number} AveragePauses
   */
  public AveragePauses: number;
  /**
   * The number of pauses per minute.
   *
   * @type {number}
   */
  public PausesPerMinute: number;
  /**
   * Represents the total pause time in milliseconds.
   *
   * @type {number}
   */
  public TotalPauseTime: number;
  /**
   * Represents the length of a calculation.
   *
   * @typedef {number} CalculationLength
   */
  public CalculationLength: number;

  /**
   * Represents an array of TimedAverage objects.
   *
   * @typedef {TimedAverage[]} TimedAverages
   */
  public TimedAverages: TimedAverage[];
  /**
   * Indicates whether a task or process has been completed.
   *
   * @type {boolean}
   */
  public IsCompleted: boolean;

  /**
   * Creates a new instance of the Constructor class.
   *
   * @param {ChartEntry[]} pitch - The pitch chart entries.
   * @param {ChartEntry[]} vol - The volume chart entries.
   * @param {ChartEntry[]} pauses - The pauses chart entries.
   * @param {number} avgPitch - The average pitch.
   * @param {number} avgVolume - The average volume.
   * @param {number} avgPause - The average pause.
   * @param {number} totalPause - The total pause time.
   * @param {boolean} isCompleted - The completion status.
   * @param {TimedAverage[]} times - The timed averages.
   * @param {number} calcLen - The calculation length.
   * @param {number} ppm - The pauses per minute.
   */
  constructor(
    pitch: ChartEntry[],
    vol: ChartEntry[],
    pauses: ChartEntry[],
    avgPitch: number,
    avgVolume: number,
    avgPause: number,
    totalPause: number,
    isCompleted: boolean,
    times: TimedAverage[],
    calcLen: number,
    ppm: number,
  ) {
    this.Pitch = pitch;
    this.Volume = vol;
    this.Pauses = pauses;
    this.AveragePitch = avgPitch;
    this.AverageVolume = avgVolume;
    this.AveragePauses = avgPause;
    this.TotalPauseTime = totalPause;
    this.IsCompleted = isCompleted;
    this.TimedAverages = times;
    this.CalculationLength = calcLen;
    this.PausesPerMinute = ppm;
  }

  /**
   * Parses the audio analysis object and returns an instance of AudioAnalysis.
   *
   * @param {any} analysis - The audio analysis object to parse.
   * @returns {AudioAnalysis} - The parsed AudioAnalysis instance.
   */
  public static parseAudioAnalysis(analysis: any): AudioAnalysis {
    if (!analysis) return this.blank();

    const pitch = this.parseEntries(analysis.pitch);
    const volume = this.parseEntries(analysis.volume).map(x => {
      x.Value = x.Value * 100;
      return x;
    });
    const pauses = this.parseEntries(analysis.pauses);
    return new AudioAnalysis(
      pitch,
      volume,
      pauses,
      analysis.averagePitch,
      analysis.averageVolume,
      analysis.averagePauseTime,
      analysis.totalPauseTime,
      analysis.isCompleted,
      analysis.timedAverages,
      analysis.calculationLength,
      analysis.pausesPerMinute,
    );
  }

  /**
   * Create a blank AudioAnalysis object.
   *
   * @returns {AudioAnalysis} A new AudioAnalysis object with empty arrays and default values for all properties.
   */
  public static blank(): AudioAnalysis {
    return new AudioAnalysis([], [], [], 0, 0, 0, 0, false, [], 0, 0);
  }

  /**
   * Parses an array of entries and returns an array of ChartEntry objects.
   *
   * @param {any[]} entries - The array of entries to be parsed.
   * @return {ChartEntry[]} - The parsed array of ChartEntry objects.
   */
  private static parseEntries(entries: any[]): ChartEntry[] {
    return entries.map(entry => {
      const time = entry.timeStamp;
      const value = Math.round(entry.value * 100) / 100;
      return new ChartEntry(time, value);
    });
  }
}

export default AudioAnalysis;
