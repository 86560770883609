import { MutationTree } from "vuex";
import { ProductsState } from "./index";
import { Product } from "@/core/models";

const mutations: MutationTree<ProductsState> = {
  setProducts(state, products: Product[]) {
    state.products = products;
    state.retrieved = true;
  },
};

export default mutations;
