import { MutationTree } from "vuex";
import { NotificationsState } from ".";
import { Notification } from "@/core/models";

const mutations: MutationTree<NotificationsState> = {
  setLastChecked(state, lastCheckedNotifications: Date) {
    state.lastChecked = lastCheckedNotifications;
  },

  addNotification(state, notification: Notification) {
    console.log("addNotification");
    console.log(notification);
    state.notifications.push(notification);
  },
};

export default mutations;
