





































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import api from "../../../../core/utils/api";
import { Timestamp } from "../../../../core/models";

@Component
export default class UploadSession extends Vue {
  @PropSync("dialogProp", { default: () => false }) dialog!: boolean;
  @PropSync("isUploadingProp") isUploading!: boolean;
  @Prop({ default: () => false }) locked!: boolean;
  @Prop({ default: () => true }) isAudio!: boolean;

  @Getter("sessions/uploadingSession") loading!: boolean;
  @Action("sessions/uploadSession")
  uploadSessionAction!: (d: FormData) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;

  isTooBig = false;

  get canSubmit() {
    return this.name && this.uploadedFile;
  }

  handleFileChange(file: File | null) {
    if (
      !file ||
      (this.isAudio
        ? !file.type.startsWith("audio/")
        : file.type !== "video/mp4")
    ) {
      this.uploadedFile = null;
      this.displaySnackbar(
        this.isAudio
          ? this.$t("upsess.rejected_file").toString()
          : this.$t("upsessv.rejected_file").toString(),
      );
      // @ts-ignore
      this.$refs.form.reset();
      this.locale = this.$i18n.locale;
      return;
    }
    this.uploadedFile = file;
    if (!this.name)
      this.name = file.name.substring(0, file.name.lastIndexOf("."));
  }

  name = "";
  locale = this.$i18n.locale;
  formValid = false;
  uploadedFile: File | null = null;
  localeItems = [
    { text: "English", value: "en" },
    { text: "Deutsch", value: "de" },
  ];
  fileFormat: string = this.isAudio ? "audio/*, *.m4a" : "video/mp4";
  fileLabel: string = this.isAudio
    ? this.$t("sessionsPage.uploadSessionSection.audioFile").toString()
    : this.$t("sessionsPage.uploadSessionSection.videoFile").toString();

  async uploadSession() {
    if (!this.formValid) {
      const message = this.$t("forms.invalidform").toString();
      this.displaySnackbar(message);
      return;
    }

    if (!this.uploadedFile) {
      const message = this.isAudio
        ? this.$t("forms.audiofile").toString()
        : this.$t("forms.videofile").toString();
      this.displaySnackbar(message);
      return;
    }

    const maxSize = 400000000; // TODO: Move to settings.ts

    if (this.uploadedFile.size > maxSize) {
      this.isTooBig = true;
      return;
    }

    this.dialog = false;

    const data = new FormData();
    if (this.isAudio) {
      this.displaySnackbar(this.$t("upsess.uploading").toString());
      this.isUploading = true;
      data.append("sessionName", this.name);
      data.append("file", this.uploadedFile);
      data.append("locale", this.locale);
      await this.uploadSessionAction(data);
      this.isUploading = false;
      this.displaySnackbar(this.$t("upsess.uploadComplete").toString());
    } else {
      this.displaySnackbar(this.$t("upsessv.uploading").toString());
      this.isUploading = true;
      const now = new Date();
      const timestamps: Timestamp[] = [];
      data.append("file", this.uploadedFile);
      data.append("title", this.name);
      data.append("locale", this.locale);
      data.append("recordedAt", now.toISOString());
      data.append("timestamps", JSON.stringify(timestamps));
      data.append("presentationId", JSON.stringify(0));
      data.append("type", "video");

      // send request
      const res = (await api.post("/api/Recordings", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as any;
      this.isUploading = false;
      this.displaySnackbar(this.$t("upsessv.uploadComplete").toString());

      console.log("video recording created", res);
    }

    // reset form
    this.name = "";
    this.uploadedFile = null;
    (this.$refs.form as HTMLFormElement).resetValidation();
  }

  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.locale = this.$i18n.locale;
  }
}
