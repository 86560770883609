import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "../root";
import i18n from "@/core/plugins/i18n";
import { PresentationsState } from ".";
import { Presentation } from "@/core/models";

const actions: ActionTree<PresentationsState, RootState> = {
  clearPresentations({ state }) {
    state.presentations = [];
    state.retrieved = false;
  },

  async getOne({ state }, id: number) {
    let pres = state.presentations.find(x => x.ID === id);
    if (!pres)
      try {
        const res = (await api.get(`/api/Presentations/${id}`)) as any;
        if (!res) return null;
        pres = Presentation.parse(res);
        state.presentations = [...state.presentations, pres];
      } catch (err) {
        console.log(err);
      }

    return pres;
  },

  async getPresentations({ state }) {
    state.loading = true;
    state.retrieved = false;
    try {
      const res = (await api.get(
        "/api/Presentations/GetPresentations",
      )) as any[];
      const presentations = res.map(x => Presentation.parse(x));
      state.presentations = presentations;
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },
  async uploadPresentation({ state }, file: any) {
    state.uploadLoading = true;
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await api.post(
        "/api/Presentations/AddPresentation",
        formData,
        { headers: { ContentType: "multipart/form-data" } },
      );
      state.presentations.push(Presentation.parse(res));
    } catch (error) {
      console.log(error);
    }
    state.uploadLoading = false;
  },

  async deletePresentation({ dispatch, state }, id: number) {
    state.deleteLoading = true;
    state.deletedPresentationID = id;
    try {
      const endpoint = `/api/Presentations/DeletePresentation?id=${id}`;
      await api.delete(endpoint);

      const idx = state.presentations.findIndex(x => x.ID === id);
      if (idx !== -1) state.presentations.splice(idx, 1);

      const msg = i18n.t("snack.pres.del").toString();
      dispatch("displaySnackbar", msg, { root: true });
    } catch (error: any) {
      if (error.status === 409) {
        const errorMsg = i18n.t("snack.pres.cannotDelete");
        dispatch("displaySnackbar", errorMsg, { root: true });
      }
    }
    state.deleteLoading = false;
    state.deletedPresentationID = 0;
  },

  async edit({ state }, data: any) {
    try {
      const end = "/api/Presentations";
      await api.patch(end, data);
      const { id, name } = data;
      const pres = state.presentations.find(p => p.ID === id);
      const presIdx = state.presentations.findIndex(p => p.ID === id);
      if (!pres) return;
      pres.Name = name;
      state.presentations[presIdx] = pres;
    } catch (error) {
      console.log(error);
    }
  },
};

export default actions;
