import { ActionTree } from "vuex";

import api from "@/core/utils/api";

import { RootState } from "../root";
import { ContactState } from "@/core/store/contact/index";
import i18n from "@/core/plugins/i18n";

const actions: ActionTree<ContactState, RootState> = {
  // contact message post
  async sendMessage({ state, dispatch }, payload) {
    const messageTimeout = 10 * 1000;
    state.messageLoading = true;
    try {
      await api.post("/api/Contact", payload);
      state.messageSent = true;
      setTimeout(() => (state.messageSent = false), messageTimeout);
      dispatch("displaySnackbar", i18n.t("forms.contact.success").toString(), {
        root: true,
      });
    } catch (error) {
      console.error((error as any).description);
      dispatch("displaySnackbar", i18n.t("forms.contact.fail").toString(), {
        root: true,
      });
      state.messageSent = false;
    }
    state.messageLoading = false;
  },
};

export default actions;
