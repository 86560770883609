import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "@/core/store/root";
import { GroupsState } from "@/core/store/groups";
import { GroupVideo } from "@/core/models";

const actions: ActionTree<GroupsState, RootState> = {
  async addVideoFromLink({ state }, data: any) {
    state.addVideoLoading = true;
    try {
      const res = await api.post("/api/Groups/AddVideoFromURI", data);
      const video = GroupVideo.parseGroupVideo(res);
      const idx = state.groups.findIndex(g => g.ID == data.groupId);
      if (idx === -1) return;
      const copy = state.groups.slice(0);
      if (data.isPinned) copy[idx].PinnedVideoID = video.ID;
      copy[idx].Videos.push(video);
      state.groups = copy;
    } catch (error) {
      console.log(error);
    }
    state.addVideoLoading = false;
  },

  async addVideoFromFile({ state }, data: FormData) {
    state.addVideoLoading = true;
    try {
      const res = await api.post("/api/Groups/AddVideoFromFile", data, {
        headers: { ContentType: "multipart/form-data" },
      });
      const video = GroupVideo.parseGroupVideo(res);
      const gid = Number(data.get("groupId"));
      if (!gid) throw new Error("invalid group id");
      const idx = state.groups.findIndex(g => g.ID == gid);
      if (idx === -1) throw new Error("invalid video id");
      const copy = state.groups.slice(0);
      if (data.get("isPinned") === "true") copy[idx].PinnedVideoID = video.ID;
      copy[idx].Videos.push(video);
      state.groups = copy;
    } catch (error) {
      console.log(error);
    }
    state.addVideoLoading = false;
  },

  async editVideo({ state }, data: any) {
    state.editVideoLoading = true;
    state.editedVideoID = data.videoId;
    try {
      await api.put("/api/Groups/EditVideo", data);
      const gidx = state.groups.findIndex(g => g.ID == data.groupId);
      if (gidx === -1) return;
      const copy = state.groups.slice(0);
      const vidx = copy[gidx].Videos.findIndex(g => g.ID == data.videoId);
      if (vidx === -1) return;

      copy[gidx].Videos[vidx].Title = data.newTitle;
      copy[gidx].Videos[vidx].Description = data.newDescription;
      if (data.isPinned) copy[gidx].PinnedVideoID = copy[gidx].Videos[vidx].ID;
      else if (
        !data.isPinned &&
        copy[gidx].PinnedVideoID == copy[gidx].Videos[vidx].ID
      )
        copy[gidx].PinnedVideoID = 0;
    } catch (error) {
      console.log(error);
    }
    state.editVideoLoading = false;
  },

  async deleteVideo({ state }, data: any) {
    state.deleteVideoLoading = true;
    state.deletedVideoID = data.videoId;
    try {
      const endpoint = `/api/Groups/DeleteVideo?groupId=${data.groupId}&videoId=${data.videoId}`;
      await api.delete(endpoint);
      const gidx = state.groups.findIndex(g => g.ID == data.groupId);
      if (gidx === -1) return;
      const copy = state.groups.slice(0);
      const vidx = copy[gidx].Videos.findIndex(v => v.ID == data.videoId);
      if (vidx === -1) return;
      copy[gidx].Videos.splice(vidx, 1);
    } catch (error) {
      console.log(error);
    }
    state.deleteVideoLoading = false;
  },
};

export default actions;
