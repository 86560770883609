import { GetterTree } from "vuex";
import { GroupsState } from ".";
import { RootState } from "../root";

export interface GroupName {
  id: number;
  name: string;
}
export interface PinnedVideo {
  id: number;
  video: number;
}

const getters: GetterTree<GroupsState, RootState> = {
  loadingGroups: state => state.loadingGroups,
  retrievedGroups: state => state.retrievedGroups,
  getGroups: state => {
    return state.groups.sort((a, b) => +b.CreatedAt - +a.CreatedAt);
  },

  loadingPublicGroups: state => state.loadingPublicGroups,
  retrievedPublicGroups: state => state.retrievedPublicGroups,
  getPublicGroups: state =>
    state.publicGroups.sort((a, b) => +b.CreatedAt - +a.CreatedAt),

  loadingCompanyGroups: state => state.loadingCompanyGroups,
  retrievedCompanyGroups: state => state.retrievedCompanyGroups,
  getCompanyGroups: state =>
    state.companyGroups.sort((a, b) => +b.CreatedAt - +a.CreatedAt),

  getNames: state =>
    [
      { id: 0, name: "" },
      ...state.groups.map(g => ({ id: g.ID, name: g.Name })),
    ] as GroupName[],

  pinnedVideos: state =>
    state.groups.map(g => ({
      id: g.ID,
      video: g.PinnedVideoID,
    })) as PinnedVideo[],

  canManage: state => state.groups.some(g => g.IsManager || g.IsOwner),
  addGroupLoading: state => state.addGroupLoading,
  removeGroupLoading: state => state.removeGroupLoading,
  removedGroupID: state => state.removedGroupID,
  renameGroupLoading: state => state.renameGroupLoading,
};

export default getters;
