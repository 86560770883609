import { GetterTree } from "vuex";

import { RootState } from "../root";
import { PresentationsState } from ".";

const getters: GetterTree<PresentationsState, RootState> = {
  loading: state => state.loading,
  retrieved: state => state.retrieved,
  getPresentations: state => state.presentations,
  getUploadLoading: state => state.uploadLoading,
  getDeleteLoading: state => state.deleteLoading,
  getDeletedPresentationID: state => state.deletedPresentationID,
};

export default getters;
