import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "../root";
import { DashboardData, DashboardState } from ".";
import { Article, AssignedGoal, Goal, Group } from "@/core/models";

export default {
  clear({ state }) {
    state.retrieved = false;
  },
  async getData({ state, commit }) {
    state.loading = true;
    try {
      const end = "/api/Users/Dashboard";
      const data = (await api.get(end)) as DashboardData;
      if (data.goals.assignedGoals)
        data.goals.assignedGoals = data.goals.assignedGoals.map(x =>
          AssignedGoal.parse(x),
        );
      if (data.goals.createdGoals)
        data.goals.createdGoals = data.goals.createdGoals.map(x =>
          Goal.parse(x),
        );
      data.groups = data.groups.map(x => Group.parseGroup(x));
      data.articles = data.articles.map(x => Article.parseArticle(x));
      state.retrieved = true;

      commit("goals/set", data.goals, { root: true });
      commit("news/set", data.articles, { root: true });
      commit("groups/set", data.groups, { root: true });
      commit("presentations/set", data.presentations, { root: true });
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },
} as ActionTree<DashboardState, RootState>;
