import { GetterTree } from "vuex";

import { SeminarsState } from ".";
import { RootState } from "../root";

import { blocksInOrder, elementStatus } from "@/core/utils/seminars";

export const getters: GetterTree<SeminarsState, RootState> = {
  gettingAll: state => state.gettingAll,
  loading: state => state.loading,
  skipping: state => state.skipping,
  adding: state => state.adding,
  updating: state => state.updating,
  retrieved: state => state.retrieved,
  seminars: state =>
    state.seminars.sort(
      (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
    ),
  selected: state => state.selected,
  selectedId: state => state.selected?.id || 0,
  ownsSeminar: state => state.seminars.some(x => x.isOwner),
  isOwner: state => state.selected?.isOwner,
  users: state => state.selected?.users || [],
  submissions: state => state.selected?.submissions || [],

  currentLogo: state => state.selected?.logo || "",
  blockIndex: state => state.blockIndex,
  elementIndex: state => state.elementIndex,
  block: state => state.selected?.blocks[state.blockIndex],
  element: state => {
    const blocks = blocksInOrder(state.selected, state.selected?.order);
    return blocks[state.blockIndex]?.elements[state.elementIndex];
  },
  selectableUpTo: state => {
    const blocks = blocksInOrder(state.selected, state.selected?.order);
    if (!blocks.length) return [0, 0];

    const lastBlockIdx = blocks.length - 1;
    for (let i = 0; i <= lastBlockIdx; i++)
      for (let j = 0; j < blocks[i]?.elements.length || 0; j++) {
        const curr = blocks[i]?.elements[j];
        if (elementStatus(curr, state.selected) === "active") return [i, j];
      }
    return [lastBlockIdx, (blocks[lastBlockIdx]?.elements.length || 1) - 1];
  },

  gettingFeedback: state => state.gettingFeedback,
  feedbackVideos: state => state.feedbackVideos.get(state.selected?.id || 0),

  order: state => state.selected?.order,
  blocks: state => blocksInOrder(state.selected, state.selected?.order),
};
