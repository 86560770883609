class PDFInfo {
  public Name: string;
  public PdfUri: string;

  constructor(title: string, uri: string) {
    this.Name = title;
    this.PdfUri = uri;
  }
}

export default PDFInfo;
