import User from "../User";

class GroupUser extends User {
  public JoinedAt: Date;
  public IsOwner: boolean;
  public IsManager: boolean;
  public IsVisible: boolean;

  constructor(
    email: string,
    name: string,
    joinedAt: Date,
    isOwner: boolean,
    isManager: boolean,
    isVisible: boolean,
  ) {
    super(email, name);
    this.JoinedAt = joinedAt;
    this.IsOwner = isOwner;
    this.IsManager = isManager;
    this.IsVisible = isVisible;
  }

  public static parseGroupUser(groupUser: any): GroupUser {
    const joined = new Date(Date.parse(groupUser.joinedAt));
    return new GroupUser(
      groupUser.email,
      groupUser.username || "Unknown",
      joined,
      groupUser.isOwner,
      groupUser.isManager,
      groupUser.isVisible,
    );
  }
}

export default GroupUser;
