import User from "../User";
import { FileObject } from "@/core/models";

class GroupPost {
  public ID: number;
  public Title: string;
  public Body: string;
  public Poster: User;
  public CreatedAt: Date;
  public BannerImage: FileObject | null;
  public Files: FileObject[];

  constructor(
    id: number,
    title: string,
    body: string,
    poster: User,
    createdAt: Date,
    banner: FileObject | null,
    files: FileObject[],
  ) {
    this.ID = id;
    this.Title = title;
    this.Body = body;
    this.Poster = poster;
    this.CreatedAt = createdAt;
    this.BannerImage = banner;
    this.Files = files;
  }

  static parseGroupPost(data: any): GroupPost {
    const poster = new User(data.poster.email, data.poster.username);
    const createdAt = new Date(Date.parse(data.createdAt));
    const bannerImage = FileObject.parseFile(data.bannerImage);
    let files: FileObject[] = [];
    if (data.files != null)
      files = (data.files as any[]).map(f => FileObject.parseFile(f)!);
    return new GroupPost(
      data.id,
      data.title,
      data.body,
      poster,
      createdAt,
      bannerImage,
      files,
    );
  }
}

export default GroupPost;
