class Employee {
  Email: string;
  Username: string;
  HasAccepted: boolean;
  IsManager: boolean;
  IsOwner: boolean;

  constructor(data: any) {
    this.Email = data.email;
    this.Username = data.username;
    this.HasAccepted = data.hasAccepted || false;
    this.IsManager = data.isManager || false;
    this.IsOwner = data.isOwner || false;
  }
}

export default Employee;
