import { GetterTree } from "vuex";

import { SupportState } from ".";
import { RootState } from "../root";

const getters: GetterTree<SupportState, RootState> = {
  freshchatActive: state => state.freshchatActive,
  supportInfo: state => state.supportInfo,
};

export default getters;
