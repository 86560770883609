class Timestamp {
  index: number;
  time: number;
  slideURI: string;
  slideIndex: number;

  constructor(data: any) {
    const { index, time, slideURI, slideIndex } = data;
    this.index = index;
    this.time = time;
    this.slideURI = slideURI;
    this.slideIndex = slideIndex;
  }
}
export default Timestamp;
