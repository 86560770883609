import { Notification } from "@/core/models";
import { GetterTree } from "vuex";
import { NotificationsState } from ".";
import { RootState } from "../root";

const getters: GetterTree<NotificationsState, RootState> = {
  getNotifications: state =>
    state.notifications.sort(
      (a: Notification, b: Notification) => +b.date - +a.date,
    ),
  retrieved: state => state.retrieved,
  lastChecked: state => state.lastChecked,
  newNotificationsCount: state =>
    state.notifications.filter(x => +x.date - +state.lastChecked > 0).length,
};

export default getters;
