import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import { RootState } from "../root";
import mutations from "./mutations";
import { Article } from "@/core/models";

export interface NewsState {
  loading: boolean;
  retrieved: boolean;
  articles: Article[];

  // Helpers
  addArticleLoading: boolean;
  editArticleLoading: boolean;
  editedArticleID: number;
  deleteArticleLoading: boolean;
  deletedArticleID: number;
}

export const state: NewsState = {
  articles: [],
  loading: false,
  retrieved: false,

  // Helpers
  addArticleLoading: false,
  editArticleLoading: false,
  editedArticleID: 0,
  deleteArticleLoading: false,
  deletedArticleID: 0,
};

const namespaced: boolean = true;

export const news: Module<NewsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
