export const convertDuration = (duration: number) => {
  const seconds = duration % 60;
  const ss = Math.floor(seconds).toString().padStart(2, "0");
  const minutes = (duration / 60) % 60;
  const mm = Math.floor(minutes).toString().padStart(2, "0");
  const hours = (duration / 60 / 60) % 60;
  const hh = Math.floor(hours).toString().padStart(2, "0");

  if (Math.floor(hours) > 0) return `${hh}:${mm}:${ss}`;
  return `${mm}:${ss}`;
};
