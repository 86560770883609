import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { Invitation, InvitationData } from "@/core/models";
import { RootState } from "../root";
import { InvitationsState } from "./index";

export const actions: ActionTree<InvitationsState, RootState> = {
  async get({ state, commit }) {
    state.loading = true;
    try {
      state.invitations = await api.get("/api/Invitations");
      state.retrieved = true;
    } catch (error) {
      const msg = "Could not fetch invitations";
      commit("displaySnackbar", msg, { root: true });
      console.log(error);
    }
    state.loading = false;
  },

  async send({ state, commit }, data: InvitationData) {
    state.sending = true;
    let invs: any[] = [];
    try {
      invs = (await api.post("/api/Invitations", data)) as any[];
      const emails = data.userEmails.join(", ");
      const msg = `Invitation successfully sent to ${emails}.`;
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
      const msg = "Could not send out invitations";
      commit("displaySnackbar", msg, { root: true });
    }
    state.sending = false;
    return invs;
  },

  async accept({ commit }, inv: Invitation) {
    try {
      const end = `/api/Invitations/Accept/${inv.id}`;
      const { seminar } = (await api.patch(end)) as any;
      console.log("[accept invitation] Seminar", seminar);
      location.reload();
    } catch (error) {
      console.error(error);
      const msg = "Could not accept invitation";
      commit("displaySnackbar", msg, { root: true });
    }
  },

  async decline({ state, commit }, inv: Invitation) {
    try {
      const end = `/api/Invitations/ToggleActivation/${inv.id}`;
      await api.patch(end);

      // update active status
      const idx = state.invitations.findIndex(x => x.id === inv.id);
      if (idx !== -1) {
        const arr = JSON.parse(
          JSON.stringify(state.invitations),
        ) as Invitation[];
        arr[idx].active = false;
        state.invitations = arr;
      }
    } catch (error) {
      console.error(error);
      const msg = "Could not accept invitation";
      commit("displaySnackbar", msg, { root: true });
    }
  },
};
