import { Module } from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { RootState } from "../root";
import { Product } from "@/core/models";

export interface ProductsState {
  retrieved: boolean;
  getting: boolean;
  loading: boolean;
  products: Product[];
}

export const state: ProductsState = {
  retrieved: false,
  getting: false,
  loading: false,
  products: [],
};

export const products: Module<ProductsState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
