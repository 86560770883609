class FileInfo {
  public Title: string;
  public URI: string;

  constructor(title: string, uri: string) {
    this.Title = title;
    this.URI = uri;
  }
}

export default FileInfo;
