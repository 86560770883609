import VueI18n from "vue-i18n";
import {
  AddElementRequest,
  ElementType,
  Seminar,
  SeminarBlock,
  SeminarElement,
  SeminarOrder,
} from "../models";

export const elementStatus = (e: SeminarElement, seminar?: Seminar) => {
  // subs for this el
  const all = seminar?.submissions?.filter(x => x.elementId === e.id) || [];

  const subs = all.filter(x => !x.skipped);
  const skippedSubs = all.filter(x => x.skipped);

  // check video_presentation differently
  let completed = false;
  if (e?.type === "video_presentation") {
    const numReq = e.requiredSubmissions || 1;
    const numReqShared = e.rateableSubmissions ? e.requiredNumShared || 0 : 0;
    const sharedSubs = subs?.filter(x => x.isShared).length || 0;
    completed = (subs?.length || 0) >= numReq && sharedSubs >= numReqShared;
  } else if (e.type === "goal") completed = e.progress >= e.target;
  else completed = (subs?.length || 0) > 0;

  return completed
    ? "completed"
    : skippedSubs.length > 0
    ? "skipped"
    : "active";
};

export const blocksInOrder = (
  seminar?: Seminar,
  order?: SeminarOrder,
): SeminarBlock[] => {
  if (!seminar || !order) return [];
  const s = JSON.parse(JSON.stringify(seminar)) as Seminar;
  const o = JSON.parse(JSON.stringify(order)) as SeminarOrder;

  // init
  const arr: SeminarBlock[] = [];

  // sorted blocks
  const blocks = o.blocks.sort((a, b) => a.blockIndex - b.blockIndex);

  // iterate over blocks
  for (const b of blocks) {
    // find actual block in seminar
    const bFind = s.blocks.find(x => x.id === b.blockId);
    if (!bFind) continue;
    if (arr.some(x => x.id === bFind.id)) continue;

    const block = JSON.parse(JSON.stringify(bFind)) as SeminarBlock;
    const elements: SeminarElement[] = [];

    // elements of this block
    const blockElements = o.elements
      .filter(x => x.blockId === b.blockId)
      .sort((a, b) => a.elementIndex - b.elementIndex);

    // iterate over elements
    for (const e of blockElements) {
      // find actual element in seminar
      const el = s.elements?.find(x => x.id === e.elementId);
      if (!el) continue;
      if (elements.some(x => x.id === el.id)) continue;

      // push in order
      elements.push(JSON.parse(JSON.stringify(el)));
    }

    // assign elements
    block.elements = elements;

    // push block in order
    arr.push(block);
  }

  // done
  return arr;
};

export const requestFromElement = (
  el: SeminarElement,
): AddElementRequest | undefined => {
  const data = new FormData();
  switch (el.type) {
    case "read_text":
      return {
        type: "read_text",
        data: {
          title: el.title,
          body: el.body,
          seminarId: el.seminarId,
          blockId: el.blockId,
        },
      };
    case "watch_video":
      data.append("body", el.body);
      data.append("title", el.title);
      data.append("blockId", el.blockId.toString());
      data.append("seminarId", el.seminarId.toString());
      return { type: "watch_video", data };
    case "video_presentation":
      return {
        type: "video_presentation",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          rateableSubmissions: el.rateableSubmissions || false,
          requiredSubmissions: Number(el.requiredSubmissions || 1),
          maxNumShared: Number(el.maxNumShared || 2),
          maxSubmissions: Number(el.maxSubmissions || 10),
          requiredNumShared: Number(el.requiredNumShared || 0),
          isTimed: el.isTimed || false,
          expectedLen: el.isTimed && el.expectedLen ? el.expectedLen : 0,
          maxLen: el.isTimed && el.maxLen ? el.maxLen : 0,
        },
      };
    case "watch_feedback":
      return {
        type: "watch_feedback",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
        },
      };
    case "give_feedback":
      return {
        type: "give_feedback",
        data: {
          body: el.body,
          title: el.title,
          blockId: el.blockId,
          seminarId: el.seminarId,
          forElement: el.forElement,
          rateableSubmissions: el.rateableSubmissions || false,
        },
      };
    case "rephrase":
      return {
        type: "rephrase",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          sentences: el.sentences,
        },
      };
    case "categorize":
      return {
        type: "categorize",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          words: el.words,
          categories: el.categories,
        },
      };
    case "gap_text":
      return {
        type: "gap_text",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          text: el.text,
        },
      };
    case "vr_training":
      return {
        type: "vr_training",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          requiredScore: el.requiredScore,
        },
      };
    case "goal":
      return {
        type: "goal",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          target: el.target,
          conditions: el.conditions.map(x => ({
            ...x,
            value: Array.isArray(x.value) ? JSON.stringify(x.value) : x.value,
          })),
        },
      };
    default:
      return undefined;
  }
};

export const elementTypes = [
  "read_text",
  "watch_video",
  "video_presentation",
  "give_feedback",
  "watch_feedback",
  "gap_text",
  "rephrase",
  "categorize",
  "vr_training",
  "goal",
];

export const videoTypes = ["video_presentation", "give_feedback"];

export const allTypes = (
  t?: (
    key: string,
    values?: VueI18n.Values | undefined,
  ) => VueI18n.TranslateResult,
) => {
  return elementTypes.map(x => (t && t(`seminars.${x}.title`).toString()) || x);
};

export const typeName = (
  type: ElementType,
  t?: (
    key: string,
    values?: VueI18n.Values | undefined,
  ) => VueI18n.TranslateResult,
) => {
  switch (type) {
    case "read_text":
      return (t && t(`seminars.${type}.title`)) || "Read text";
    case "categorize":
      return (t && t(`seminars.${type}.title`)) || "Put words into categories";
    case "gap_text":
      return (t && t(`seminars.${type}.title`)) || "Gap text";
    case "give_feedback":
      return (t && t(`seminars.${type}.title`)) || "Record feedback";
    case "rephrase":
      return (t && t(`seminars.${type}.title`)) || "Rephrase sentences";
    case "video_presentation":
      return (t && t(`seminars.${type}.title`)) || "Record presentation";
    case "vr_training":
      return (t && t(`seminars.${type}.title`)) || "VR Training";
    case "watch_feedback":
      return (t && t(`seminars.${type}.title`)) || "Watch feedback";
    case "watch_video":
      return (t && t(`seminars.${type}.title`)) || "Watch video";
    case "goal":
      return (t && t(`seminars.${type}.title`)) || "Goal";
    default:
      return "";
  }
};

export const typeToEndpoint = (t: ElementType) => {
  switch (t) {
    case "read_text":
      return "/api/Elements/ReadText";
    case "categorize":
      return "/api/Elements/Categorize";
    case "gap_text":
      return "/api/Elements/GapText";
    case "give_feedback":
      return "/api/Elements/GiveFeedback";
    case "rephrase":
      return "/api/Elements/Rephrase";
    case "video_presentation":
      return "/api/Elements/VideoPresentation";
    case "vr_training":
      return "/api/Elements/VRTraining";
    case "watch_feedback":
      return "/api/Elements/WatchFeedback";
    case "watch_video":
      return "/api/Elements/WatchVideo";
    case "goal":
      return "/api/Elements/Goal";
    default:
      return "";
  }
};

const base = {
  id: 0,
  blockId: 0,
  seminarId: 0,
  title: "",
  body: "",
  completed: false,
};
export const emptyElement = (t: ElementType): SeminarElement => {
  switch (t) {
    case "read_text":
      return {
        ...base,
        type: "read_text",
      };
    case "categorize":
      return {
        ...base,
        type: "categorize",
        words: [],
        answers: [["Answer 1"]],
        categories: ["Category #1"],
      };
    case "gap_text":
      return {
        ...base,
        type: "gap_text",
        text: "__ text",
        answers: ["Gap"],
      };
    case "give_feedback":
      return {
        ...base,
        type: "give_feedback",
        forElement: 0,
      };
    case "rephrase":
      return {
        ...base,
        type: "rephrase",
        sentences: ["Hey"],
        answers: ["Hello"],
      };
    case "video_presentation":
      return {
        ...base,
        type: "video_presentation",
        maxNumShared: 2,
        maxSubmissions: 10,
        requiredNumShared: 0,
        requiredSubmissions: 1,
        rateableSubmissions: false,
        isTimed: false,
        expectedLen: 10,
        maxLen: 20,
      };
    case "vr_training":
      return {
        ...base,
        type: "vr_training",
        requiredScore: 7,
      };
    case "watch_feedback":
      return {
        ...base,
        type: "watch_feedback",
      };
    case "watch_video":
      return {
        ...base,
        type: "watch_video",
        videoURI: "",
      };
    case "goal":
      return {
        ...base,
        type: "goal",
        goalId: 0,
        target: 5,
        progress: 0,
        conditions: [],
        isAssignedToUser: false,
      };
    default:
      return {} as SeminarElement;
  }
};
