import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import { RootState } from "../root";
import { SupportState } from ".";
import { SupportInfo } from "@/core/models/support-info";

const actions: ActionTree<SupportState, RootState> = {
  async getFreshchatActive({ state }) {
    try {
      const end = "/api/tenants/freshchat";
      state.freshchatActive = (await api.get(end)) as boolean;
    } catch (error) {
      console.log(error);
    }
  },
  async getSupportInfo({ state }, locale: string) {
    try {
      const end = "/api/tenants/support/" + locale;
      state.supportInfo = (await api.get(end)) as SupportInfo;
    } catch (error) {
      console.log(error);
    }
  },
};

export default actions;
