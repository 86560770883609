import { GetterTree } from "vuex";

import { RootState } from "../../root";
import { GroupsState } from "../../groups";

const getters: GetterTree<GroupsState, RootState> = {
  getPosts: state =>
    state.groups
      .flatMap(g => g.Posts)
      .sort((a, b) => +b.CreatedAt - +a.CreatedAt),

  // Group Posts
  addPostLoading: state => state.addPostLoading,
  editPostLoading: state => state.editPostLoading,
  editedPostID: state => state.editedPostID,
  deletePostLoading: state => state.deletePostLoading,
  deletedPostID: state => state.deletedPostID,
};

export default getters;
