import User from "../User";
import FileObject from "../FileObject";

class GroupVideo {
  public ID: number;
  public GroupID: number;
  public Title: string;
  public Description: string;
  public UploadedAt: Date;
  public Uploader: User;
  public File: FileObject | null;

  constructor(
    id: number,
    groupId: number,
    t: string,
    d: string,
    uploader: User,
    uploadedAt: Date,
    file: FileObject | null,
  ) {
    this.ID = id;
    this.GroupID = groupId;
    this.Title = t;
    this.Description = d;
    this.Uploader = uploader;
    this.UploadedAt = uploadedAt;
    this.File = file;
  }

  static parseGroupVideo(data: any) {
    const uploader = new User(data.uploader.email, data.uploader.username);
    const at = new Date(Date.parse(data.uploadedAt));
    const file = data.file ? FileObject.parseFile(data.file) : null;
    return new GroupVideo(
      data.id,
      data.groupId,
      data.title,
      data.description,
      uploader,
      at,
      file,
    );
  }
}

export default GroupVideo;
