import { ActionTree } from "vuex";

import { GoalsState } from ".";
import api from "@/core/utils/api";
import { RootState } from "../root";
import { AssignedGoal, Goal } from "@/core/models";

const actions: ActionTree<GoalsState, RootState> = {
  clearGoals({ state }) {
    state.assignedGoals = [];
    state.createdGoals = [];
    state.retrieved = false;
  },

  async getGoals({ state }) {
    state.loading = true;
    state.retrieved = false;

    try {
      const { createdGoals, assignedGoals } = (await api.get(
        "/api/Goals/",
      )) as any;
      state.createdGoals = (createdGoals as any[]).map(g => Goal.parse(g));
      state.assignedGoals = (assignedGoals as any[]).map(g =>
        AssignedGoal.parse(g),
      );
      state.retrieved = true;
      window.dispatchEvent(new CustomEvent("got-goals"));
    } catch (err) {
      console.log(err);
    }
    state.loading = false;
  },

  async addGoal({ state, rootGetters }, data) {
    state.addGoalLoading = true;
    try {
      const res = await api.post("/api/Goals/CreateGoal", data);
      const currentEmail = rootGetters["profile/getUserEmail"];
      const goal = Goal.parse(res);
      if (goal.AssingedUsers.some(u => u.Email === currentEmail)) {
        const assignedGoal = new AssignedGoal(
          goal.ID,
          goal.Target,
          goal.Creator,
          goal.CreatedAt,
          goal.Conditions,
        );
        state.assignedGoals.push(assignedGoal);
      }
      state.createdGoals.push(goal);
    } catch (error) {
      console.log(error);
    }
    state.addGoalLoading = false;
  },

  async removeGoal({ commit, state, rootGetters }, goalId) {
    state.removeGoalLoading = true;
    state.removedGoalId = goalId;
    try {
      await api.delete(`/api/Goals/RemoveGoal?goalId=${goalId}`);
      const currentEmail = rootGetters["profile/getUserEmail"];
      const cidx = state.createdGoals.findIndex(x => x.ID === goalId);
      if (cidx === -1) return;
      const currentAssigned = state.createdGoals[cidx].AssingedUsers.some(
        u => u.Email === currentEmail,
      );
      if (currentAssigned) {
        const idx = state.assignedGoals.findIndex(x => x.GoalId === goalId);
        if (idx !== -1) state.assignedGoals.splice(idx, 1);
      }
      state.createdGoals.splice(cidx, 1);
      commit("removeGoal", goalId);
    } catch (error) {
      console.log(error);
    }
    state.removeGoalLoading = false;
  },

  async assignToGoal({ state }, goalId: number) {
    const res = (await api.patch(`/api/Goals/AddUser/${goalId}`)) as any;
    const goal = AssignedGoal.parse(res);
    state.assignedGoals = [...state.assignedGoals, goal];
  },
};

export default actions;
