
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class AppBar extends Vue {
  @Prop({ default: () => 0 }) analysisFast!: string | boolean;
  @Prop({ default: () => 0 }) aiFeedbackFast!: string | boolean;
  @Prop({ default: () => 0 }) liveTranscriptionFast!: string | boolean;
  @Getter("profile/hasSubscription") hasSubscription!: boolean;
}
