import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"d-flex align-center shadow",staticStyle:{"border-color":"var(--v-accent-base)"},attrs:{"large":"","rounded":"","outlined":"","color":"accentText"}},on),[_c('div',{staticClass:"menu-button"},[_c('img',{attrs:{"src":_vm.pfp,"alt":"Profile Picture"}})]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VCard,{attrs:{"elevation":"0","width":"300px"}},[_c(VList,{staticClass:"pa-0"},[_c(VListItem,{staticClass:"pa-0"},[_c('div',{staticClass:"banner other--text text-truncate"},[_c('div',{staticClass:"text-truncate font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name || "Username")+" ")]),_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.email || "example@email.com")+" ")])])]),_c(VDivider),_c(VListItem,{staticClass:"py-2 align-center",attrs:{"link":"","to":"/settings"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("profileDropdown.profile")))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t("profileDropdown.checkoutSettings")))])],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider),_c(VListItem,[_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"text":""},on:{"click":_vm.refresh}},[_vm._v(" "+_vm._s(_vm.$t("toolbarMenu.refresh"))+" ")]),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logoutUser}},[_vm._v(_vm._s(_vm.$t("profileDropdown.logout")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }