class MetaAnalysis {
  attributeType: string;
  avgFormerSessions: number;
  avgLatterSessions: number;
  deviation: number;
  metaAnalysisFormerSessions: {
    sessionId: number;
    average: number;
    date: Date;
  }[];
  metaAnalysisLatterSessions: {
    sessionId: number;
    average: number;
    date: Date;
  }[];
  numAvailableSessions: number;
  numSessionsPerSet: number;

  constructor(data: any) {
    this.attributeType = data.attributeType;
    this.avgFormerSessions = data.avgFormerSessions;
    this.avgLatterSessions = data.avgLatterSessions;
    this.deviation = data.deviation;
    const mapper = (s: any) => {
      s.date = new Date(s.date);
      return s;
    };
    this.metaAnalysisFormerSessions = data.metaAnalysisFormerSessions.map(
      mapper,
    );
    this.metaAnalysisLatterSessions = data.metaAnalysisLatterSessions.map(
      mapper,
    );
    this.numAvailableSessions = data.numAvailableSessions;
    this.numSessionsPerSet = data.numSessionsPerSet;
  }
}

export default MetaAnalysis;
