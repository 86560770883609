























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class ToolbarMenu extends Vue {
  @Prop({ default: () => false }) isMobile!: boolean;
  @Action("profile/logoutUser") logout!: Function;
  @Action("loadEverything") loadEverything!: Function;
  @Action("clearEverything") clearEverything!: Function;

  logoutUser() {
    this.clearEverything();
    this.logout();
    this.$sessionHub.stop();
    this.$notificationHub.stop();
    this.$router.replace("/auth/login");
  }
  refresh() {
    location.reload();
  }
}
