import { MutationTree } from "vuex";

import { GoalsState } from ".";

const mutations: MutationTree<GoalsState> = {
  set(state, payload) {
    if (!payload) {
      state.createdGoals = [];
      state.assignedGoals = [];
      state.retrieved = false;
      return;
    }
    state.retrieved = true;
    const { assignedGoals, createdGoals } = payload;
    state.assignedGoals = assignedGoals;
    state.createdGoals = createdGoals;
  },

  removeGoal(state, goalId) {
    const cidx = state.createdGoals.findIndex(goal => goal.ID === goalId);
    if (cidx !== -1) state.createdGoals.splice(cidx, 1);

    const aidx = state.assignedGoals.findIndex(goal => goal.GoalId === goalId);
    if (aidx !== -1) state.assignedGoals.splice(aidx, 1);
  },
};

export default mutations;
