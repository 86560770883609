import { MutationTree } from "vuex";

import { PresentationsState } from ".";
import { Presentation } from "@/core/models";

const mutations: MutationTree<PresentationsState> = {
  set(state, payload: Presentation[]) {
    state.presentations = payload.map(x => Presentation.parse(x));
    state.retrieved = true;
  },
};

export default mutations;
