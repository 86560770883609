import { MutationTree } from "vuex";

import { NewsState } from ".";
import { Article } from "@/core/models";

const mutations: MutationTree<NewsState> = {
  set(state, payload: any[]) {
    if (state.retrieved) return;
    state.retrieved = true;
    state.articles = payload;
  },

  // Mutations for getting articles
  getNewsSucess: (state, res: any) =>
    (state.articles = (res as any[]).map(a => Article.parseArticle(a))),
  getNewsError(state, err: any) {
    state.articles = [];
    console.log(err);
  },

  // Clearing articles
  clearArticles: state => (state.articles = []),

  // Mutations for uploading a articles
  addingArticle: (state, value: boolean) => (state.addArticleLoading = value),
  addArticleError: (_, err: any) => console.log(err),
  addArticleSuccess(state, res: any) {
    const article = Article.parseArticle(res);
    state.articles.push(article);
  },

  // Mutations for editing articles
  editingArticle(state, payload: any): void {
    state.editArticleLoading = payload.value;
    state.editedArticleID = payload.articleId;
  },
  editArticleSuccess(state, data: any): void {
    const newTitle = data.newTitle;
    const newBody = data.newBody;
    const article = state.articles.find(a => a.ID == data.articleId)!;
    article.Title = newTitle;
    article.Body = newBody;
  },
  editArticleError: (_, err: any) => console.log(err),

  // Mutations for deleting articles
  deletingArticle(state, payload: any): void {
    state.deleteArticleLoading = payload.value;
    state.deletedArticleID = payload.articleId;
  },
  deleteArticleSuccess(state, id: number): void {
    const articleToDelete = state.articles.find(a => a.ID == id)!;
    state.articles.splice(state.articles.indexOf(articleToDelete), 1);
  },
  deleteArticleError: (_, err: any) => console.log(err),
};

export default mutations;
