






























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

const key = "prezp_hide_browser_warning";

@Component
export default class BrowserWarning extends Vue {
  @Getter("profile/id") userId!: string;

  dialog = false;
  shouldShow = false;
  dontShow() {
    const arr = window.localStorage.getItem(key);
    const newArr = !arr ? [] : [...(JSON.parse(arr) as string[])];
    newArr.push(this.userId);
    window.localStorage.setItem(key, JSON.stringify(newArr));
    this.dialog = false;
  }
  created() {
    const arr = window.localStorage.getItem(key);
    if (!arr) {
      this.shouldShow = true;
      return;
    }

    const uids = JSON.parse(arr) as string[];
    this.shouldShow = !uids.includes(this.userId);
  }
}
