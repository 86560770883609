import { ActionTree } from "vuex";

import { MeetingsState } from ".";
import api from "@/core/utils/api";
import { RootState } from "../root";
import { Meeting } from "@/core/models";

const actions: ActionTree<MeetingsState, RootState> = {
  clearMeetings({ state }) {
    state.meetings = [];
    state.loading = false;
    state.retrieved = false;
  },

  async getMeetings({ state }) {
    state.loading = true;
    try {
      const res = (await api.get("/api/Meetings/")) as any[];
      state.retrieved = true;
      state.meetings = res.map(g => Meeting.parse(g));
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async addMeeting({ state }, data: any) {
    state.addMeetingLoading = true;
    try {
      const res = await api.post("/api/Meetings/CreateMeeting", data);
      state.meetings.push(Meeting.parse(res));
    } catch (error) {
      console.log(error);
    }
    state.addMeetingLoading = false;
  },

  async editMeeting({ state }, data: any) {
    state.editingMeeting = true;
    state.editedMeetingId = data.meetingId;
    try {
      const res = await api.put("/api/Meetings/EditMeeting", data);
      state.meetings.push(Meeting.parse(res));
    } catch (error) {
      console.log(error);
    }
    state.editingMeeting = false;
  },

  async removeMeeting({ state }, meetingId: any) {
    state.removeMeetingLoading = true;
    state.removedMeetingId = meetingId;
    try {
      await api.delete(`/api/Meetings/DeleteMeeting?meetingId=${meetingId}`);
      const idx = state.meetings.findIndex(x => x.ID === meetingId);
      if (idx === -1) return;
      state.meetings.splice(idx, 1);
    } catch (error) {
      console.log(error);
    }
    state.removeMeetingLoading = false;
  },
};

export default actions;
